import React from "react";
import { IconProps } from "react-feather";
import { gold } from "shared/styles/colors";

const RocketIcon = ({ color = gold, size = 16 }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.59 12.526C1.397 13.527 1 16.5 1 16.5s2.973-.397 3.974-1.59c.565-.667.557-1.693-.071-2.313a1.733 1.733 0 0 0-2.313-.071v0zM8.551 11.333 6.166 8.95c.423-1.098.956-2.15 1.59-3.14A10.238 10.238 0 0 1 16.5 1c0 2.162-.62 5.962-4.77 8.744a17.757 17.757 0 0 1-3.179 1.59v0z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.167 8.949H2.192s.438-2.409 1.59-3.18c1.288-.858 3.974 0 3.974 0M8.551 11.333v3.975s2.409-.438 3.18-1.59c.858-1.288 0-3.974 0-3.974"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default RocketIcon;
