import firebase from "firebase";

// Configuration for what Firebase application to connect to
var config = {
  apiKey: "AIzaSyBe0jEmiD7V3fJ-PgRY3SGOcaQDJ3JWtAo",
  authDomain: "the-citizenry.firebaseapp.com",
  databaseURL: "https://the-citizenry.firebaseio.com",
  projectId: "the-citizenry",
  storageBucket: "the-citizenry.appspot.com",
  messagingSenderId: "110771259330"
};

const firebaseInit = () => {
  firebase.initializeApp(config);
};

export default firebaseInit;
