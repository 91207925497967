/* NOT NECESSARY FOR CITIZENRY DASHBOARD */
// import Pages from "layouts/Pages.jsx";
// import RTL from "layouts/RTL.jsx";

import Dashboard from "layouts/Dashboard.jsx";
import ProductInventoryLayout from "layouts/ProductInventory/ProductInventory.layout";
import Incoming from "layouts/Incoming.jsx";
import NewIncoming from "layouts/NewIncoming/NewIncoming.layout";
import Settings from "layouts/Settings.jsx";
import Login from "layouts/Login.jsx";
// import PurchaseOrders from "layouts/PurchaseOrders.jsx"
import OrdersRoute from "./orders";
import KitsLayout from "layouts/Kits/Kits";
import ProductLog from "layouts/ProductLog/ProductLog.layout";
import Triggers from "layouts/Triggers/Triggers.layout";
import AtRiskLayout from "layouts/AtRisk/AtRisk.layout";
import InventoryUpload from "layouts/InventoryUpload/InventoryUpload";

var indexRoutes = [
  // { path: "/rtl", name: "RTL", component: RTL },
  // { path: "/pages", name: "Pages", component: Pages },
  { path: "/", name: "Login", component: Login }, // Uncomment after login page is created
  { path: "/dashboard", name: "Dashboard", component: ProductInventoryLayout },
  { path: "/dashboard-old", name: "Dashboard", component: Dashboard },
  { path: "/orders", name: "Orders", component: OrdersRoute },
  { path: "/incoming", name: "Incoming View", component: NewIncoming },
  { path: "/incoming-old", name: "Incoming View", component: Incoming },
  { path: "/settings", name: "Settings", component: Settings },
  { path: "/kits", name: "Kits", component: KitsLayout },
  { path: "/log", name: "Product Log", component: ProductLog },
  { path: "/triggers", name: "Triggers", component: Triggers },
  { path: "/at-risk", name: "At Risk", component: AtRiskLayout },
  { path: "/inventory-removal", name: "Inventory Removal", component: InventoryUpload },
];

export default indexRoutes;
