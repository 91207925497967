import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import { CSVLink } from 'react-csv';
import CustomReactTable from 'components/ReactTable/CustomReactTable';
import ArchiveIcon from '@material-ui/icons/Archive';
import ViewWeekOutlined from '@material-ui/icons/ViewWeekOutlined';
import actions from '../../actions';
import { calculateWOH, getECSDAndPresell } from '../../helpers/inventory';
import { captureException } from '@sentry/react';
import { toast } from 'react-toastify';
import Spinner from 'react-spinkit';
import { darkBlue } from 'shared/styles/colors';
import { css, StyleSheet } from 'aphrodite';
import { differenceInCalendarDays, format } from 'date-fns';
import { compareCaseInsensitive, isValidDate } from 'shared/utils';


const mapValidHeaders = headers => {
  if (headers && headers.length > 0) {
    return headers.filter(item => item.onTable);
  }
  return headers;
};

class InventoryTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sku: [],
      textFilters: [],
      filters: {
        sku: []
      },
      dataLoadedReady: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.prepareCSV = this.prepareCSV.bind(this);
    this.handleShipmentDate = this.handleShipmentDate.bind(this);
    this.handleWaitlistChange = this.handleWaitlistChange.bind(this);
    this.updateShipHeroData = this.updateShipHeroData.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleFilters = this.handleFilters.bind(this);
    this.handleTextFilters = this.handleTextFilters.bind(this);
    this.saveFilters = this.saveFilters.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.mapShipHeroDataToShopifyData = this.mapShipHeroDataToShopifyData.bind(
      this
    );
  }

  componentDidMount() {
    // this.props.actions
    //   .fetchFiltersData(localStorage.getItem('userId'))
    //   .then(response => {
    //     if (typeof response.data[0] !== 'undefined') {
    //       this.setState({
    //         filters: response.data[0].filterOptions.filters,
    //         textFilters: response.data[0].filterOptions.textFilters
    //       });
    //     }
    //   });
  }

  handleChange() {
    this.props.onColumnView();
  }

  prepareCSV(data) {
    let csvData = [
      [
        'SKU',
        'Shopify Title',
        'Shopify Inventory',
        'Warehouse Inventory',
        'Warehouse Backorder',
        'Current Shipment Left to Pre-sell',
        'Earliest Customer Ship Date',
        'Web Ship Date',
        'Sell Through',
        'Shopify Weeks on Hand',
        'Warehouse Weeks on Hand',
        'Shopify Status',
        'WOH + OOO',
      ]
    ];

    data.map(r => {
      let webShipDate = '';
      if (r.web_ship_date === 'Waitlist' || !!r.waitlist) {
        webShipDate = 'WAITLIST';
      } else if (
        typeof r.web_ship_date !== 'undefined' &&
        r.web_ship_date !== null &&
        r.web_ship_date !== ''
      ) {
        webShipDate = moment(r.web_ship_date).format('MM/DD/YYYY');
      }

      csvData.push([
        r.sku,
        r.title,
        r.inventory_quantity,
        r.available_amount,
        r.backorder,
        r.presell,
        r.earliestShipDate,
        webShipDate,
        r.sell_thru,
        r.shopify_week,
        r.shiphero_week,
        r.vendor,
        r.wohCount,
      ]);

      return true;
    });

    return csvData;
  }

  handleShipmentDate(sku, value) {
    let data = Object.assign({}, { sku: sku, shipDate: value });

    this.props.actions.setWebShipmentDate(data);
  }

  handleWaitlistChange(sku, value) {
    let data = Object.assign({}, { sku: sku, waitlist: value, shipDate: null });

    this.props.actions
      .setWaitlist(data)
      .then(resp => this.props.actions.getWebShipmentDate());
  }

  updateShipHeroData(sku) {
    this.props.actions.updateShipHeroDataAction(sku);
  }

  handleCheckbox(data) {
    let { sku } = this.state;

    if (typeof data === 'object') {
      this.setState({ sku: data });
    } else {
      if (sku.indexOf(data) !== -1) {
        sku.splice(sku.indexOf(data), 1);
      } else {
        sku.push(data);
      }

      this.setState({ sku: sku });
    }
  }

  handleFilters(key, value) {
    this.setState({
      filters: {
        ...this.state.filters,
        [key]: value
      }
    });
  }

  handleTextFilters(data) {
    this.setState({
      textFilters: data
    });
  }

  saveFilters() {
    const { textFilters, filters } = this.state;
    const resultFilters = {
      userFirebaseId: localStorage.getItem('userId'),
      filterOptions: {
        textFilters,
        filters
      }
    };

    this.props.actions.setUserFilterData(resultFilters);
  }

  applyFilters() {
    const { sku } = this.state;

    this.setState({
      filters: {
        ...this.state.filters,
        sku: [...sku]
      }
    });
  }

  clearFilters() {
    this.setState({
      sku: [],
      filters: {
        sku: []
      }
    });
  }

  mapShipHeroDataToShopifyData() {
    const {
      shopifyProductsData,
      shiphero,
      shipment,
      order,
      kits: {
        kitsbySku,
      },
    } = this.props;
    let respond = [];

    for (const single of shopifyProductsData.shopifyProducts) {
    // shopifyProductsData.shopifyProducts.map(single => {
      const singleRecord = {
        sku: single.sku,
        title: single.title,
        inventory_quantity: single.inventory_quantity,
        available: 'N/A',
        backorder: 'N/A',
        presell: '',
        earliestShipDate: '',
        web_ship_date: null,
        waitlist: false,
        sell_thru: 'N/A',
        shopify_week: 'N/A',
        shiphero_week: 'N/A',
        vendor: single.vendor,
        wohCount: null,
        last_update:
          typeof single.last_update !== 'undefined'
            ? moment(single.last_update).format('MM/DD h:mm a')
            : 'N/A',
        last_update_ship: 'N/A'
      };

      let skuCurrent = single.sku;
      let oldSku = '';
      // Use dashboard sku override file to update sku if need be
      // ----------------------------------------------------------------------
      let mergedData = _.find(shopifyProductsData.shopifyMerge, record =>
        compareCaseInsensitive(record.SKU, skuCurrent)
      );
      if (typeof mergedData != 'undefined') {
        oldSku = skuCurrent;
        skuCurrent = mergedData.newSKU;
      }

      const shipHeroProduct = _.find(shiphero.processedData, record =>
        compareCaseInsensitive(record.sku, skuCurrent)
      );

      // number available: background color
      // ----------------------------------------------------------------------
      if (typeof shipHeroProduct !== 'undefined') {
        let backgroundColor = ''; // initial row color
        if (
          typeof shipHeroProduct.available !== 'object' &&
          (singleRecord.vendor === 'Ready to ship' ||
            singleRecord.vendor === 'Waitlist')
        ) {
          if (shipHeroProduct.available < singleRecord.inventory_quantity) {
            backgroundColor = 'rgba(225, 115, 115, 0.2)';
          } else if (
            shipHeroProduct.available > singleRecord.inventory_quantity
          ) {
            backgroundColor = '#FAD956';
          }
        }

      // number available: conditional render logic
      // ----------------------------------------------------------------------
        singleRecord.available_amount = shipHeroProduct.available;
        if (shipHeroProduct.shiphero_legacy_id !== null) {
          singleRecord.available = (
            <div
              data-value={shipHeroProduct.available}
              style={{ backgroundColor }}
              className="cell available"
            >
              <div className="value">
                <a
                  href={`https://app.shiphero.com/dashboard/products/details/${
                    shipHeroProduct.shiphero_legacy_id
                  }`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {shipHeroProduct.available}
                </a>
              </div>
            </div>
          );
        } else {
          singleRecord.available = (
            <div
              data-value={shipHeroProduct.available}
              style={{ backgroundColor }}
              className="cell available"
            >
              <div className="value">{shipHeroProduct.available}</div>
            </div>
          );
        }
        // number backorder
        // ----------------------------------------------------------------------
        singleRecord.backorder = shipHeroProduct.backorder;

        // last update
        // ----------------------------------------------------------------------
        singleRecord.last_update_ship =
          typeof shipHeroProduct.last_update !== 'undefined'
            ? moment(shipHeroProduct.last_update).format('MM/DD h:mm a')
            : 'N/A';

        // kit logic
        // ----------------------------------------------------------------------
        let kitComponents;
        const kit = kitsbySku[skuCurrent];
        if (kit !== undefined) {
          /*
            - Rollup kit information for ECSD and Presell
            - Check if any kit component backorder is greater
              than the kit sku itself
          */
          let hasComponentBackorderGreaterThanZero;
          kitComponents = [];
          for (const { sku: componentSku, quantity, id } of kit.components) {
            let sku = componentSku;
            const mappedProduct = _.find(shopifyProductsData.shopifyMerge, record =>
              compareCaseInsensitive(record.SKU, sku)
            );
            if (mappedProduct !== undefined) {
              sku = mappedProduct.newSKU;
            }

            const shipheroInfo = _.find(shiphero.processedData, record =>
              compareCaseInsensitive(record.sku, sku)
            );
            if (shipheroInfo) {
              kitComponents.push({
                sku,
                componentInfo: {
                  id,
                  quantity,
                },
                shipheroInfo,
              });
              if (shipheroInfo.backorder > 0) {
                hasComponentBackorderGreaterThanZero = true;
              }
            } else {
              const errorMessage = `cannot find component sku: ${sku} in kit: ${kit.name}`;
              toast.error(errorMessage);
              captureException(`cannot find component sku: ${sku} in kit sku: ${skuCurrent}`);
            }
          }

          if (shipHeroProduct.backorder === 0 && hasComponentBackorderGreaterThanZero) {
            singleRecord.backorder = (
              <div
                style={{backgroundColor: '#ef5350' }}
                className="cell available"
              >
                <div className="value">{shipHeroProduct.backorder}</div>
              </div>
            );
          }
        }

        // ECSD / Presell
        // ----------------------------------------------------------------------
        const calculationData = getECSDAndPresell({
          backorder: shipHeroProduct.backorder,
          ordersAll: order.ordersData,
          sku: skuCurrent,
          kitComponents,
          shipment,
        });

        singleRecord.earliestShipDate = calculationData.earliestShipDate;
        singleRecord.presell = calculationData.presell;

        // Web ship date / Waitlist
        // ----------------------------------------------------------------------
        let currentShipDate = _.find(shopifyProductsData.shopifyShipDate, record =>
          compareCaseInsensitive(record.sku, skuCurrent)
        );

        let oldShipDate = undefined;
        if (oldSku !== '') {
          oldShipDate = _.find(shopifyProductsData.shopifyShipDate, record =>
            compareCaseInsensitive(record.sku, oldSku)
          );
        }

        if (typeof oldShipDate !== 'undefined') {
          singleRecord.web_ship_date = oldShipDate.shipDate;

          if (
            typeof oldShipDate.shipDate !== 'undefined' &&
            oldShipDate.shipDate !== null &&
            oldShipDate.shipDate !== ''
          ) {
            let split = oldShipDate.shipDate.split('-');
            singleRecord.web_ship_date = new Date(
              Date.UTC(split[0], split[1] - 1, split[2], 12, 0, 0)
            );
          }

          singleRecord.waitlist = oldShipDate.waitlist;
          if (!!singleRecord.waitlist) {
            singleRecord.web_ship_date = 'Waitlist';
          }
        }

        if (typeof currentShipDate !== 'undefined') {
          if (currentShipDate.sell_thru > 0) {
            singleRecord.sell_thru = currentShipDate.sell_thru.toFixed(2);
            singleRecord.shopify_week = (
              singleRecord.inventory_quantity / currentShipDate.sell_thru
            ).toFixed(2);
            singleRecord.shiphero_week = (
              singleRecord.available_amount / currentShipDate.sell_thru
            ).toFixed(2);
          } else if (currentShipDate.sell_thru === 0) {
            singleRecord.shopify_week = 'N/A';
            singleRecord.shiphero_week = 'N/A';
            singleRecord.sell_thru = 0;
          }

          if (typeof oldShipDate === 'undefined') {
            singleRecord.web_ship_date = currentShipDate.shipDate;

            if (
              typeof currentShipDate.shipDate !== 'undefined' &&
              currentShipDate.shipDate !== null &&
              currentShipDate.shipDate !== ''
            ) {
              let split = currentShipDate.shipDate.split('-');
              singleRecord.web_ship_date = new Date(
                Date.UTC(split[0], split[1] - 1, split[2], 12, 0, 0)
              );
            }

            singleRecord.waitlist = currentShipDate.waitlist;

            if (!!singleRecord.waitlist) {
              singleRecord.web_ship_date = 'Waitlist';
            }
          }
        } else {
          singleRecord.shopify_week = 'N/A';
          singleRecord.shiphero_week = 'N/A';
          singleRecord.sell_thru = 0;
        }
      }
      let currentShopifyDate = _.find(shopifyProductsData.shopifyShipDate, record =>
        compareCaseInsensitive(record.sku, skuCurrent)
      );


      if (typeof currentShopifyDate !== 'undefined') {
        singleRecord.wohCount = calculateWOH(
          skuCurrent,
          currentShopifyDate.sell_thru,
          singleRecord.available_amount,
          singleRecord.backorder,
          order.orderAllDetailsData
        );
      }

      respond.push(singleRecord);

      // return single;
    // });
    }

    return respond;
  }

  render() {
    const { filters, sku, textFilters } = this.state;
    const {
      shopifyProductsData,
      settings,
      shiphero,
      shipment,
      order
    } = this.props;

    let combinedData = [];

    if (
      shiphero.processedData.length > 0 &&
      shopifyProductsData.shopifyShipDate.length > 0 &&
      shopifyProductsData.shopifyMerge.length > 0 &&
      shipment.shipmentBills.length > 0 &&
      order.orderAllDetailsData.length > 0 &&
      shipment.shipmentAll.length > 0
    ) {
      combinedData = this.mapShipHeroDataToShopifyData();
    }

    let headers = mapValidHeaders(shopifyProductsData.tableHeaders);

    let filteredData = combinedData
      .filter(record => {
        if (record.sku === '') {
          return false;
        }
        return true;
      })
      .filter(record => {
        let title = record.title || '';
        if (title.indexOf('Bunkhouse') !== -1) {
          return false;
        }
        return true;
      })
      .filter(record => {
        let exclude = _.find(settings.excludeSKU, settingsRecord =>
          compareCaseInsensitive(settingsRecord.name, record.sku)
        );
        if (typeof exclude !== 'undefined') {
          return false;
        }
        return true;
      })
      .filter(record => {
        if (filters.sku.length === 0) {
          return true;
        } else {
          if (filters.sku.includes(record.sku)) {
            return true;
          } else {
            return false;
          }
        }
      })
      .filter(record => {
        if (
          typeof filters.web_ship_date === 'undefined' ||
          filters.web_ship_date === 'all'
        ) {
          return true;
        } else if (filters.web_ship_date === 'not_null') {
          if (
            typeof record.web_ship_date === 'undefined' ||
            record.web_ship_date === null
          ) {
            return false;
          }
        } else if (filters.web_ship_date === 'null') {
          if (record.web_ship_date) {
            return false;
          }
        } else if (filters.web_ship_date === 'date') {
          const selectedDate = filters.web_ship_date_date;
          if (selectedDate) {
            if (isValidDate(record.web_ship_date)) {
              return differenceInCalendarDays(selectedDate, record.web_ship_date) === 0;
            }
            return false;
          }
          return true;
        }

        return true;
      })
      .filter(record => {
        if (typeof filters.vendor === 'undefined' || filters.vendor === 'all') {
          return true;
        }

        if (record.vendor === filters.vendor) {
          return true;
        } else {
          return false;
        }
      })
      .filter(record => {
        if (
          typeof filters.wohCount === 'undefined' ||
          filters.wohCount === 'all'
        ) {
          return true;
        }

        if (filters.wohCount === 'not_null') {
          if (
            typeof record.wohCount === 'undefined' ||
            record.wohCount === null ||
            record.wohCount === 'N/A'
          ) {
            return false;
          }
        }

        if (filters.wohCount === 'null') {
          if (
            typeof record.wohCount === 'undefined' ||
            record.wohCount === null ||
            record.wohCount === 'N/A'
          ) {
            return true;
          }
        }

        return true;
      });

    let exportCsvButton;
    if (filteredData.length > 0) {
      exportCsvButton = (
        <CSVLink
          data={this.prepareCSV(filteredData)}
          filename={`Dashboard Inventory Export ${format(new Date(), 'MM-dd-y')}.csv`}
          className={'Button export-link'}
          target="_blank"
        >
          <ArchiveIcon className="Icon" />
          <span className="Label">Export CSV</span>
        </CSVLink>
      );
    } else {
      exportCsvButton = (
        <Spinner
          className={css(styles.spinner)}
          name="line-scale-pulse-out"
          color={darkBlue}
          fadeIn="none"
        />
      );
    }

    return (
      <React.Fragment>
        <div className="Info__Content">
          <div className="Description">Inventory Data</div>
          <div className="Buttons_Content">
            {exportCsvButton}
          </div>
        </div>
        {/* <div className="Info__Content">
          {typeof settings.shipheroUpdating !== 'undefined' &&
            settings.shipheroUpdating.updating && (
              <span className="spinning-loader" />
            )}
        </div> */}
        <div className="Table">
          <div className="inventory-table-wrapper">
            <CustomReactTable
              headers={headers}
              data={filteredData}
              selectedSKU={sku}
              filters={filters}
              textFilters={textFilters}
              handleFilters={this.handleFilters}
              handleTextFilters={this.handleTextFilters}
              handleCheckbox={this.handleCheckbox}
              handleShipmentDate={this.handleShipmentDate}
              handleWaitlistClick={this.handleWaitlistChange}
              updateShipHeroData={this.updateShipHeroData}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const styles = StyleSheet.create({
  spinner: {
    transform: 'scale(0.8)',
    marginRight: 32,
    paddingTop: 4,
  },
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

export default connect(
  state => state,
  mapDispatchToProps
)(InventoryTable);
