import React from 'react';
import { DatePicker } from 'carbon-components-react';
import { DatePickerInput } from 'carbon-components-react';
import '../styles/carbon-calendar-styles.scss';
import EyeOffIcon from 'components/icons/EyeOffIcon';
import { css, StyleSheet } from 'aphrodite';
import { taupe } from 'shared/styles/colors';
import { ReactComponent as XCircleIcon } from 'assets/svgs/x-circle.svg';

interface Props {
  column: {
    filterValue?: { date: Date, isNullChecked: boolean };
    setFilter: (value: any) => void;
    preFilteredRows: any[];
    id: number;
  },
  hasNullOption: boolean,
}

const CalendarColumnFilter = ({
  column: {
    filterValue,
    setFilter,
  },
  hasNullOption = false,
}: Props) => {
  return (
    <div className="CalendarColumnFilter">
      <div className={css(styles.filterContainer)}>
        {hasNullOption && (
          <div
            className={css(styles.eyeWrapper)}
            onClick={() => setFilter({ ...filterValue, isNullChecked: !filterValue?.isNullChecked ?? false })}
          >
            <EyeOffIcon color={filterValue?.isNullChecked ? taupe : undefined } />
          </div>
        )}
        {filterValue?.date && (
          <div
            className={css(styles.xIconWrapper)}
            onClick={() => setFilter({ ...filterValue, date: null })}
          >
            <XCircleIcon />
          </div>
        )}
        <DatePicker
          datePickerType="single"
          onChange={datetimeArray => setFilter({ ...filterValue, date: datetimeArray[0], })}
          value={filterValue?.date}
        >
          <DatePickerInput
            placeholder="mm/dd/yyyy"
            labelText=""
            id="date-picker-single"
            autoComplete="off"
            disabled={filterValue?.isNullChecked ?? false}
          />
        </DatePicker>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  filterContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  eyeWrapper: {
    position: 'absolute',
    top: 9,
    left: -22,
    cursor: 'pointer',
    padding: 2,
  },
  xIconWrapper: {
    paddingTop: 8,
    marginRight: 8,
    cursor: 'pointer',
  },
});


export default CalendarColumnFilter;
