import axios from 'axios';

export const setUserFilterData = (data) => {
  return dispatch => {
    return axios.post('/users/', data);
  }
}

export const fetchFiltersData = (id) => {
  return dispatch => {
    return axios.get('/users/' + id)
      .then(response => {
        return response;
      })
      .catch(error => {
        console.log('error from server: ', error);
      });
  };
}
