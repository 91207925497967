import thunk, { ThunkAction } from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { createLogger } from 'redux-logger';
import { createStore, applyMiddleware, compose, Action } from 'redux';
import { StoreState } from 'types/state';
import reducer from 'reducers';

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  StoreState,
  unknown,
  Action<string>
>;

// middleware
// ----------------------------------------------------------------------------
export const history = createBrowserHistory();

const middleware: any = [thunk];

if (process.env.NODE_ENV !== 'production') {
  const logger = createLogger({
    collapsed: () => true,
  });
  middleware.push(logger);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// store
// ----------------------------------------------------------------------------
const configureStore = () => {
  // @ts-ignore
  const store = createStore(
    reducer,
    composeEnhancers(applyMiddleware(...middleware))
  );

  if (process.env.NODE_ENV !== 'production') {
    if ((module as any).hot) {
      (module as any).hot.accept('../reducers', () => {
        // @ts-ignore
        store.replaceReducer(reducer);
      });
    }
  }

  return store;
};

export default configureStore;
