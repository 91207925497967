import { css, StyleSheet } from 'aphrodite';
import React, { useEffect, useRef } from 'react';
import { ColumnInstance } from 'react-table';
import { black, white } from 'shared/styles/colors';
import { mainShadow } from 'shared/styles/shadows';
import Text, { Font } from 'components/Text';
import { ReactComponent as CheckboxActive } from 'assets/svgs/check-active.svg';
import { ReactComponent as CheckboxInactive } from 'assets/svgs/check-inactive.svg';
import useOnclickOutside from "react-cool-onclickoutside";
import { chunk } from 'lodash';

const SettingsRow = ({ column }: { column: ColumnInstance<object> }) => {
  const { checked } = column.getToggleHiddenProps();
  const CheckboxComponent = checked ? CheckboxActive : CheckboxInactive;
  return (
    <div key={column.id}>
      <div
        className={css(styles.row)}
        onClick={() => column.toggleHidden(checked)}
      >
        <div className={css(styles.checkboxWrapper)}>
          <CheckboxComponent />
        </div>
        <Text style={styles.label}>
          {column.Header}
        </Text>
      </div>
    </div>
  )
};


interface Props {
  setIsColumnSettingsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isColumnSettingsModalOpen: boolean;
  allColumns: ColumnInstance<object>[];
}

const ColumnSettings = ({
  setIsColumnSettingsModalOpen,
  isColumnSettingsModalOpen,
  allColumns,
}: Props) => {
  const ref = useOnclickOutside(() => {
    setIsColumnSettingsModalOpen(false);
  });

  if (!isColumnSettingsModalOpen) {
    return null;
  }

  const chunkedColumns = chunk(allColumns, 11);

  return (
    <div ref={ref} className={css(styles.ColumnSettings)}>
      <div className={css(styles.triangle)} />
      <Text style={styles.header} font={Font.SourceSansProBold}>
        Column Visibility
      </Text>
      <div className={css(styles.columnsContainer)}>
        {chunkedColumns.map(columns => (
          <div className={css(styles.columnContainer)}>
            {columns.map(column => (
              <SettingsRow column={column} />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  ColumnSettings: {
    position: 'absolute',
    top: -48,
    left: 44,
    backgroundColor: white,
    // width: 300,
    padding: 24,
    boxShadow: mainShadow,
    borderRadius: 8,
    zIndex: 100,
  },
  //
  columnsContainer: {
    display: 'flex',
  },
  columnContainer: {
    width: 230,
  },
  label: {
    fontSize: 15,
    color: black,
    marginLeft: 6,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    cursor: 'pointer',
  },
  checkbox: {
    marginTop: 4,
  },
  header: {
    fontSize: 16,
    marginBottom: 20,
    textAlign: 'center',
  },
  triangle: {
    position: 'absolute',
    top: 41,
    left: -18,
    width: 0,
    height: 0,
    borderTop: '18px solid transparent',
    borderRight: `22px solid ${white}`,
    borderBottom: '18px solid transparent',
    filter: 'drop-shadow(-4px 0px 2px rgba(0, 0, 0, 0.1))'
  },
  checkboxWrapper: {
    cursor: 'pointer',
    marginBottom: -4,
    marginTop: 2,
  },
});

export default ColumnSettings;
