import { LeftJustifiedCell } from "components/NewReactTable/Cells";
import { CalendarColumnFilter, SelectColumnFilter, MinMaxColumnFilter } from "components/NewReactTable/Table";
import { sortByDate, filterCalendarEqualTo, filterInventoryWebShipDate, filterWOH, locationAgnosticSearch, filterMinMax } from "components/NewReactTable/tableUtils";
import React from "react";

export const columnsData = [
  {
    Header: 'SKU',
    accessor: 'sku',
    width: 150,
  },
  {
    Header: 'Artisan',
    accessor: 'artisan',
    width: 265,
    filter: locationAgnosticSearch,
  },
  {
    Header: 'QB Product Name',
    accessor: 'quickbooksProductName',
    width: 280,
    filter: locationAgnosticSearch,
  },
  {
    Header: 'Expected Qty',
    accessor: 'expectedQuantity',
    width: 100,
    filter: filterMinMax,
    Filter: MinMaxColumnFilter,
  },
  {
    Header: 'Avail for Pre-sell Qty',
    accessor: 'availableForPresellQuantity',
    width: 101,
    filter: filterMinMax,
  },
  {
    Header: 'Qty Presold',
    accessor: 'quantityPresold',
    width: 101,
    filter: filterMinMax,
  },
  {
    Header: 'Shopify Status',
    accessor: 'shopifyStatus',
    width: 119,
  },
  {
    Header: 'Web Ship Date',
    accessor: 'webShipDate',
    Filter: (props) => (
      <SelectColumnFilter
        selectOptions={[
          'Show All',
          'By Date',
          'Not Null',
          'Only Null',
        ]}
        defaultOption="Show All"
        withCalendarForSelectedOption="By Date"
        {...props} />
    ),
    filter: filterInventoryWebShipDate,
    width: 182,
  },
  {
    Header: 'Earliest Customer Ship Date',
    accessor: 'ecsd',
    Filter: (props) => <CalendarColumnFilter {...props} />,
    filter: filterCalendarEqualTo,
    sortType: sortByDate,
    width: 182,
  },
  {
    Header: 'Estimated Landing Date',
    accessor: 'estimatedLandingDate',
    Filter: (props) => <CalendarColumnFilter {...props} />,
    filter: filterCalendarEqualTo,
    sortType: sortByDate,
    width: 182,
  },
  {
    Header: 'Artisan Ship Date',
    accessor: 'artisanShipDate',
    Filter: (props) => <CalendarColumnFilter {...props} />,
    filter: filterCalendarEqualTo,
    sortType: sortByDate,
    width: 182,
  },
  {
    Header: 'Shipment ID',
    accessor: 'shipmentId',
    width: 101,
  },
  {
    Header: 'Inbound ID',
    accessor: 'inboundId',
    width: 101,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Filter: (props) => (
      <SelectColumnFilter
        selectOptions={[
          'Show All',
          'Delayed',
          'In Production',
          'Landed',
          'On-hold',
          'Partial Landed',
          'Planning',
          'Preparing Shipping',
          'Shipping',
        ]}
        defaultOption="Show All"
        {...props} />
    ),
    width: 150,
  },
  {
    Header: 'Tracking #',
    accessor: 'trackingNumber',
    width: 211,
  },
  {
    Header: 'Warehouse Backorder',
    accessor: 'shipheroBackorder',
    width: 160,
    Cell: LeftJustifiedCell,
    filter: filterMinMax,
  },
  {
    Header: 'Sell Through',
    accessor: 'sellThrough',
    width: 160,
    filter: filterMinMax,
  },
  {
    Header: 'WOH + OOO',
    accessor: 'wohOOO',
    width: 150,
    Filter: (props) => (
      <SelectColumnFilter
        selectOptions={[
          'Show All',
          'Not Null',
          'Only Null',
        ]}
        defaultOption="Show All"
        {...props} />
    ),
    filter: filterWOH,
  },
  {
    Header: 'Acq Cost',
    accessor: 'acquisitionCost',
    width: 160,
    filter: filterMinMax,
  },
  {
    Header: 'Total Acq Cost',
    accessor: 'totalAcquisitionCost',
    width: 160,
    filter: filterMinMax,
  },
];
