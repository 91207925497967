import { logAxiosEvent } from './utils';
import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';

export default class Http {
  private axios: AxiosInstance;

  public constructor(baseURL: string | undefined) {
    this.axios = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.axios.interceptors.response.use(
      response => {
        this.logEvent({ response });
        return response.data;
      },
      error => {
        this.logEvent({ error });
        return error;
      }
    );
  }

  protected _get({
    endpoint,
    params,
  }: {
    endpoint: string;
    params?: object | URLSearchParams;
  }): Promise<any> {
    return this.axios({
      method: 'get',
      url: endpoint,
      params,
    });
  }

  protected _delete({
    endpoint,
  }: {
    endpoint: string;
  }): Promise<any> {
    return this.axios({
      method: 'delete',
      url: endpoint,
    });
  }

  protected _post({
    body,
    endpoint,
    formDataBody,
  }: {
    body?: object;
    formDataBody?: FormData;
    endpoint: string;
  }): Promise<any> {
    const headers = new Headers();
    if (formDataBody) {
      headers.append('Content-Type', 'multipart/form-data');
    }

    return this.axios({
      method: 'post',
      data: body ? body : formDataBody,
      url: endpoint,
      headers,
    });
  }

  protected _patch({
    body,
    endpoint,
    formDataBody,
  }: {
    body?: object;
    formDataBody?: FormData;
    endpoint: string;
  }): Promise<any> {
    const headers = new Headers();
    if (formDataBody) {
      headers.append('Content-Type', 'multipart/form-data');
    }

    return this.axios({
      method: 'patch',
      data: body ? body : formDataBody,
      url: endpoint,
      headers,
    });
  }

  private logEvent({
    response,
    error,
  }: {
    response?: AxiosResponse;
    error?: AxiosError;
  }) {
    if (process.env.NODE_ENV !== 'production') {
      logAxiosEvent({ error, response });
    }
  }
}
