import { css, StyleSheet } from 'aphrodite';
import React, { useEffect, useState } from 'react';
import { useTable, useSortBy, usePagination, useFilters, Column, Cell, useRowState, useResizeColumns, useFlexLayout } from 'react-table';
import { useExportData } from "react-table-plugins";
import { ctaBlue, ctaBlueHover, darkGold, gray, lightGold, gold, tableLineGray, white } from 'shared/styles/colors';
import ColumnHeader from 'components/NewReactTable/ColumnHeader';
import SearchColumnFilter from '../../components/NewReactTable/Filters/SearchColumnFilter';
import { defaultGetExportFileBlob } from 'components/NewReactTable/tableUtils';
import TableControls from 'components/NewReactTable/TableControls';
import NoSearchResults from './NoSearchResults';
import Text, { Font } from 'components/Text';
import { format } from 'date-fns';
import { Settings as SettingsIcon } from 'react-feather';
import ColumnSettings from './ColumnSettings';
import './tableStyleOverrides.scss';
import { LeftJustifiedCell } from './Cells';
import { FixedSizeList } from 'react-window';
import { Zap, Info } from 'react-feather';
import { isEven } from 'shared/utils';


export * from './dummyData';
export * from './Filters';

interface Props {
  exportDataRef: any;
  csvExportPrefix: string;
  isFetchingData: boolean;
  tableData: object[];
  columns: Column<object>[];
  noResultsAdditionalText: string;
  showVisibilitySettings?: boolean;
  hiddenColumns?: string[];
  onHiddenColumnsUpdate?: (value: string[]) => void;
  getExportFileBlob?: any;
  isUpcomingWaitlistsFilterActive?: boolean;
  setIsUpcomingWaitlistsFilterActive?: React.Dispatch<React.SetStateAction<boolean>>;
  isInventoryFilterActive?: boolean;
  setIsInventoryFilterActive?: React.Dispatch<React.SetStateAction<boolean>>;
  filterButtons?: JSX.Element[]
  resetCustomFilters?: () => void
}

const Table = ({
  exportDataRef,
  csvExportPrefix,
  isFetchingData,
  tableData,
  columns,
  noResultsAdditionalText,
  hiddenColumns = [],
  showVisibilitySettings = false,
  onHiddenColumnsUpdate,
  getExportFileBlob,
  isUpcomingWaitlistsFilterActive,
  setIsUpcomingWaitlistsFilterActive,
  isInventoryFilterActive,
  setIsInventoryFilterActive,
  filterButtons,
  resetCustomFilters
}: Props) => {
  const [isColumnSettingsModalOpen, setIsColumnSettingsModalOpen] = useState(false);

  /* ----------------------------- Table Setup ----------------------------- */
  const defaultColumn = React.useMemo(
    () => ({
      Filter: SearchColumnFilter,
      Cell: LeftJustifiedCell,
    }),
    []
  );

  const [controlledPageIndex, setControlledPageIndex] = useState<number>(0);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    // @ts-ignore
    page,
    prepareRow,
    // @ts-ignore
    nextPage,
    // @ts-ignore
    canNextPage,
    // @ts-ignore
    previousPage,
    // @ts-ignore
    canPreviousPage,
    // @ts-ignore
    gotoPage,
    // @ts-ignore
    pageCount,
    // @ts-ignore
    setAllFilters,
    // @ts-ignore
    exportData,
    // @ts-ignore
    setSortBy,
    state: {
      // @ts-ignore
      pageIndex,
      // @ts-ignore
      filters,
      // @ts-ignore
      hiddenColumns: hiddenColumnsState,
      // @ts-ignore
      pageSize
    },
    allColumns,
    totalColumnsWidth,
  } = useTable(
    {
      data: tableData,
      // @ts-ignore
      defaultColumn,
      // @ts-ignore
      columns,
      autoResetFilters: false,
      autoResetSortBy: false,
      // @ts-ignore
      initialState: { pageIndex: controlledPageIndex, pageSize: 250, hiddenColumns },
      // @ts-ignore
      getExportFileBlob: getExportFileBlob ? getExportFileBlob : defaultGetExportFileBlob,
      // @ts-ignore
      getExportFileName: () => `${csvExportPrefix} Export ${format(new Date(), 'MM-dd-y')}`,
      manualFilters: isFetchingData,
    },
    useFilters,
    useSortBy,
    usePagination,
    useExportData,
    useRowState,
    useResizeColumns,
    useFlexLayout
  );

  useEffect(() => {
    if (showVisibilitySettings && onHiddenColumnsUpdate) {
      onHiddenColumnsUpdate(hiddenColumnsState as string[]);
    }
  }, [hiddenColumnsState]);

  useEffect(() => {
    setControlledPageIndex(pageIndex);
  }, [pageIndex]);

  useEffect(() => {
    exportDataRef.current = () => exportData('csv', false); // include filters / sort
  }, [exportData]);

  useEffect(() => {
    document.getElementsByClassName('TableVirtualizedRows')[0].scrollTop = 0;
  }, [page]);

  const hasActiveFilterAndNoResults = filters.length > 0 && rows.length === 0;

  let showTableControls = false;
  let showNoSearchResultsContent = false;
  if (!isFetchingData) {
    showTableControls = true;
    if (hasActiveFilterAndNoResults) {
      showTableControls = false;
      showNoSearchResultsContent = true;
    }
  }

  const onResetAllFilters = () => {
    setAllFilters([]);
    setSortBy([]);
    if (setIsUpcomingWaitlistsFilterActive !== undefined) {
      setIsUpcomingWaitlistsFilterActive(false);
    }
    if (setIsInventoryFilterActive !== undefined) {
      setIsInventoryFilterActive(false);
    }
    if (resetCustomFilters) {
      resetCustomFilters();
    }
  }

  const {
    style: tableStyle,
    ...restTableProps
  } = getTableProps();

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = page[index];
      prepareRow(row);
      return (
        <tr
          className={css(styles.tr)}
          {...row.getRowProps({ style })}
        >
          {row.cells.map((cell: Cell) => {
            const { style, ...rest } = cell.getCellProps();
            return (
              <td
                className={css(styles.td)}
                style={{
                  ...style,
                  backgroundColor: isEven(cell.row.index) ? white : '#f8f9fa',
                }}
                {...rest}
              >
                {cell.render('Cell')}
              </td>
            )
          })}
        </tr>
      )
    },
    [prepareRow, page]
  );

  return (
    <div className={css(styles.outerContainer)}>
      <div className={css(styles.topRow)}>
        {showVisibilitySettings && (
          <div className={css(styles.settingsContainer)}>
            <div
              className={css(styles.settingsIconWrapper)}
              onClick={() => setIsColumnSettingsModalOpen(!isColumnSettingsModalOpen)}
            >
              <SettingsIcon size={22} color={gray} />
            </div>
            <ColumnSettings
              allColumns={allColumns}
              isColumnSettingsModalOpen={isColumnSettingsModalOpen}
              setIsColumnSettingsModalOpen={setIsColumnSettingsModalOpen}
            />
          </div>
        )}
        <div className={css(styles.topRowRight)}>
          {setIsUpcomingWaitlistsFilterActive !== undefined && (
            <div
              className={css(
                styles.upcomingWaitlistsContainer,
                isUpcomingWaitlistsFilterActive && styles.upcomingWaitlistsContainerActive,
              )}
              onClick={() => {
                const sortBy = isUpcomingWaitlistsFilterActive ? [] : [{id: 'ecsd', desc: false}];
                setSortBy(sortBy);
                setIsUpcomingWaitlistsFilterActive(!isUpcomingWaitlistsFilterActive)
              }}
            >
              <Zap
                fill={gold}
                color={gold}
                size={14}
                className={css(styles.zapIcon)}
              />
              <Text
                font={Font.SourceSansProSemibold}
                style={styles.upcomingWaitlistsText}
              >
                Upcoming Waitlists
              </Text>
            </div>
          )}
          {setIsInventoryFilterActive !== undefined && (
            <div
              className={css(
                styles.upcomingWaitlistsContainer,
                styles.inventoryFilterContainer,
                isInventoryFilterActive && styles.upcomingWaitlistsContainerActive,
              )}
              onClick={() => {
                setIsInventoryFilterActive(!isInventoryFilterActive)
              }}
            >
              <Info
                stroke={gold}
                size={16}
                strokeWidth={2.5}
                className={css(styles.infoIcon)}
              />
              <Text
                font={Font.SourceSansProSemibold}
                style={styles.upcomingWaitlistsText}
              >
                Inventory Discrepancies
              </Text>
            </div>
          )}
          {filterButtons && filterButtons.map(button=>button)}
          <div
            className={css(styles.resetFiltersTextWrapper)}
            onClick={onResetAllFilters}
          >
            <Text
              font={Font.SourceSansProSemibold}
              style={styles.resetAllFiltersText}
            >
              Reset All Filters
            </Text>
          </div>
        </div>
      </div>
      <div className={css(styles.tableWrapper)}>
        <table
          className={css(styles.table)}
          {...restTableProps}
        >
          <thead>
            {headerGroups.map(headerGroup => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map(column => (
                  <th
                    className={css(styles.th)}
                    {...column.getHeaderProps()}
                  >
                    <ColumnHeader column={column} />
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            className={css(styles.tbody)}
          >
            {showNoSearchResultsContent && (
              <NoSearchResults additionalText={noResultsAdditionalText} />
            )}
            <FixedSizeList
              height={700}
              itemCount={page.length}
              itemSize={60}
              width={totalColumnsWidth}
              className={`${css(styles.tableVirtualizedRows)} TableVirtualizedRows`}
            >
              {RenderRow}
            </FixedSizeList>
          </tbody>
        </table>
      </div>
      {showTableControls && (
        <>
          <TableControls
            canPreviousPage={canPreviousPage}
            previousPage={previousPage}
            controlledPageIndex={controlledPageIndex}
            gotoPage={gotoPage}
            pageCount={pageCount}
            canNextPage={canNextPage}
            nextPage={nextPage}
          />
          <Text style={styles.rowsCount}>
            {pageSize} rows per page
          </Text>
        </>
      )}
    </div>
  );
};

export default Table;

const styles = StyleSheet.create({
  Table: {
  },
  //
  outerContainer: {
    flex: 1,
    backgroundColor: white,
    borderRadius: 8,
    padding: 24,
    paddingTop: 16,
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.10)",
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  tableVirtualizedRows: {
    width: '100%',
  },
  tableWrapper: {
    flex: 1,
    overflowX: 'scroll',
    marginBottom: 24,
    display: 'flex',
    minHeight: 0,
    overflowY: 'auto',
  },
  table: {
    flex: 1,
    flexDirection: 'column',
    display: 'flex',
    border: `1px solid ${tableLineGray}`,
    borderSpacing: 0,
  },
  tbody: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minHeight: 0,
    minWidth: 0,
    overflow: 'auto',
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tr: {
    overflow: 'hidden',
    ':last-child > td': {
      borderBottom: 0,
    },
    backgroundColor: white,
  },
  th: {
    padding: 0,
    ':last-child > div': {
      borderRight: 0,
    },
    ':last-child separator': {
      right: -1,
    },
  },
  td: {
    borderRight: `1px solid ${tableLineGray}`,
    borderBottom: `1px solid ${tableLineGray}`,
    ':last-child': {
      borderRight: 0,
    },
  },
  resetAllFiltersText: {
    fontSize: 16,
    color: ctaBlue,
    cursor: 'pointer',
    transition: '0.3s',
    ':hover': {
      color: ctaBlueHover,
    }
  },
  resetFiltersTextWrapper: {
    padding: 2,
  },
  settingsContainer: {
    position: 'relative',
  },
  settingsIconWrapper: {
    cursor: 'pointer',
  },
  rowsCount: {
    display: 'flex',
    alignSelf: 'center',
    paddingLeft: 12,
    fontSize: 13,
    color: gray,
  },
  topRowRight: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12,
  },
  upcomingWaitlistsText: {
    fontSize: 16,
    marginRight: 6,
    color: darkGold,
    cursor: 'pointer',
    transition: '300ms',
  },
  upcomingWaitlistsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 16,
    width: 180,
    borderRadius: 20,
    height: 32,
    backgroundColor: white,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: gold,
    transition: '100ms',
  },
  upcomingWaitlistsContainerActive: {
    backgroundColor: lightGold,
    borderWidth: 2,
  },
  zapIcon: {
    marginRight: 3,
  },
  infoIcon: {
    marginRight: 6,
  },
  inventoryFilterContainer: {
    width: 220,
  },
});
