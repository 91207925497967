import { css, StyleSheet } from 'aphrodite';
import React from 'react';
import { ctaBlue } from 'shared/styles/colors';

const opacityKeyframes = {
  'from': {
    opacity: 1,
  },

  'to': {
    opacity: 0,
  }
};

interface Props {
  size?: number;
}

const AnimatedDot = ({ size = 12 }: Props) => {
  return (
    <div
      style={{
        height: size,
        width: size,
        borderRadius: size / 2,
      }}
      className={css(styles.animatedBlueDot)}
    />
  );
};

export default AnimatedDot;

const styles = StyleSheet.create({
  animatedBlueDot: {
    marginTop: 2,
    backgroundColor: ctaBlue,
    animationName: [opacityKeyframes],
    animationDuration: '1.5s',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate',
    transitionTimingFunction: "cubic-bezier(.5, 0, 1, 1)",
  },
});
