import { ImmerReducer } from "immer-reducer";
import { createActionCreators, createReducerFunction } from "immer-reducer";
import { AtRiskState } from "types/state";

const initialState: AtRiskState = {
  atRiskData: [],
  isFetchingAtRiskData: true,
};

class AtRiskReducer extends ImmerReducer<AtRiskState> {
  setAtRiskData(data: any) {
    this.draftState.atRiskData = data;
  }

  setIsFetchingAtRiskData(isFetching: boolean) {
    this.draftState.isFetchingAtRiskData = isFetching;
  }
}

export const AtRiskActions = createActionCreators(AtRiskReducer);
const reducerFunction = createReducerFunction(AtRiskReducer, initialState);
export default reducerFunction;
