import { AppThunk } from 'redux/store';
import BackendService from 'services/Backend/Backend.service';
import { toast } from 'react-toastify';
import { ProductInventoryActions } from 'reducers/productInventory.reducer';
import { format } from 'date-fns';

export const editWebShipDateCell = ({
  rowIndex,
  webShipDate,
  sku
}): AppThunk => async (dispatch) => {
  dispatch(ProductInventoryActions.modifyWebShipDateCell({
    webShipDate,
    rowIndex,
  }));

  try {
    let finalDate = webShipDate;
    if (webShipDate !== null) {
      finalDate = format(webShipDate, 'y-MM-d');
    }
    const data = { sku: sku, shipDate: finalDate };
    await BackendService.editShipment(data);
  } catch (error) {
    toast.error(`Could not edit web ship date for sku ${sku}. Please try again.`);
  }
};
