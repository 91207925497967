import { AppThunk } from 'redux/store';
import BackendService from 'services/Backend/Backend.service';
import { toast } from 'react-toastify';
import { InventoryUploadActions } from 'reducers/inventoryUploadReducer';

export const getWarehouseSKUs = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(InventoryUploadActions.setIsFetchingInitialData(true));

    const skus = await BackendService.getWarehouseSKUs();

    dispatch(InventoryUploadActions.populateWarehouseSKUs(skus));
  } catch (error) {
    toast.error('Could not get Warehouse SKUs. Please refresh the page.');
    dispatch(InventoryUploadActions.setIsFetchingInitialData(false));
  }
};
