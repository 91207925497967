import React from "react";

interface Props {
  flexGrow: number;
}

const Spacer = ({ flexGrow }: Props) => <div style={{ flexGrow }} />;

Spacer.defaultProps = {
  flexGrow: 1,
} as Partial<Props>;

export default Spacer;
