import * as types from '../types';
import { sortMethodInventoryQuantity, sortByDate, sortNumber } from 'shared/sortMethods';

const _ = require('lodash');


const INITIAL_STATE = {
  tableHeaders: [
    {
      label: 'SKU',
      key: 'sku',
      onTable: true
    },
    {
      label: 'Shopify Title',
      key: 'title',
      onTable: true
    },
    {
      label: 'Shopify Inventory',
      key: 'inventory_quantity',
      onTable: true
    },
    {
      label: 'ShipHero Inventory',
      key: 'available',
      onTable: true,
      sortMethod: sortMethodInventoryQuantity
    },
    {
      label: 'Warehouse Backorder',
      key: 'backorder',
      onTable: true,
      sortMethod: sortNumber,
    },
    {
      label: 'Current Shipment Left to Pre-sell',
      key: 'presell',
      onTable: true,
    },
    {
      label: 'Earliest Cust Ship Date',
      key: 'earliestShipDate',
      onTable: true,
      sortMethod: sortByDate,
    },
    {
      label: 'Web Ship Date',
      key: 'web_ship_date',
      onTable: true,
      sortMethod: sortByDate,
    },
    {
      label: 'Waitlist',
      key: 'waitlist',
      onTable: true
    },
    {
      label: 'Sell Through',
      key: 'sell_thru',
      onTable: true,
      sortMethod: sortNumber,
    },
    {
      label: 'Shopify weeks on hand',
      key: 'shopify_week',
      onTable: true,
      sortMethod: sortNumber,
    },
    {
      label: 'Warehouse weeks on hand',
      key: 'shiphero_week',
      onTable: true,
      sortMethod: sortNumber,
    },
    {
      label: 'Shopify Status',
      key: 'vendor',
      onTable: true
    },
    {
      label: 'WOH + OOO',
      key: 'wohCount',
      onTable: true
    },
    {
      label: 'Last Update',
      key: 'last_update_ship',
      onTable: true
    },
  ],
  shopifyProducts: [],
  mappedShopifyProducts: null,
  shopifyMerge: [],
  shopifyShipDate: [],
  orderWaitlist: []
};

const createDataEntry = (inventory_item_id, inventory_quantity, sku, title, vendor) => ({
  available: '',
  backorder: '',
  inventory_item_id,
  inventory_quantity,
  sku,
  title,
  vendor
});

// const createDataEntryFromList = (mainTitle, vendor, list) => {
//   let returnList = [];
//   list.forEach(item => {
//     let { inventory_item_id, inventory_quantity, sku, title } = item;
//     let formattedTitle = `${mainTitle} - ${title}`;
//     returnList.push(createDataEntry(inventory_item_id, inventory_quantity, sku, formattedTitle, vendor));
//   });
//   return returnList;
// }

const createTableHeader = (label, key) => ({
  label,
  key
});

const extractHeaders = headerArray => {
  let formattedHeaders = [];
  for (let i = 0; i < headerArray.length; i++) {
    if (headerArray[i] === 'SHOPIFY SKU') {
      continue;
    }
    let key = headerArray[i].toLowerCase().split(' ').join('_');
    formattedHeaders.push(createTableHeader(headerArray[i], key));
  }
  return formattedHeaders;
};
//
// const extractTableData = inventoryByProducts => {
//   let extractedTableEntries = [];
//   inventoryByProducts.forEach(product => {
//     let { title, vendor } = product;
//     // if the product as no variants
//     if (product.variants === undefined) {
//       let { inventory_item_id, inventory_quantity, sku } = product;
//       extractedTableEntries.push(createDataEntry(inventory_item_id, inventory_quantity, sku, title, vendor));
//     } else if (product.variants.length > 1) {
//       extractedTableEntries = extractedTableEntries.concat(createDataEntryFromList(title, vendor, product.variants));
//     } else {
//       let { inventory_item_id, inventory_quantity, sku } = product.variants[0];
//       extractedTableEntries.push(createDataEntry(inventory_item_id, inventory_quantity, sku, title, vendor));
//     }
//   });
//   return extractedTableEntries;
// };

const mapCSVBodyDataToShopifyData = (CSVBodyData, shopifyData) => {
  let newEntries = [];
  // for each entry in the CSVBodyData
  for (let i = 0; i < CSVBodyData.length; i++) {
    // select the current entry
    let currentCSVEntry = CSVBodyData[i];
    // create a flag to store if match was found
    let matchFound = false;
    // iterate though the shopify data
    for (let j = 0; j < shopifyData.length; j++) {
      // check if the shopify_sku matches the sku from the current shopify data
      if (currentCSVEntry.shopify_sku === shopifyData[j].sku) {
        matchFound = true;
        // if so, iterate through all of the keys, mapping them onto the shopifyEntry
        Object.keys(currentCSVEntry).forEach(key => {
          // if the key is everything but 'shopify_sku', because we already have a sku field
          if (key !== 'shopify_sku') {
            // set the current shopify entry key to the value from the csv entry at the key
            shopifyData[j][key] = currentCSVEntry[key];
          }
        });
        // stop iterating
        break;
      }
    }
    if (matchFound) {
      continue;
    } else {
      // create a new entry
      let newEntry = createDataEntry('', '', currentCSVEntry.shopify_sku, '', '');
      Object.keys(currentCSVEntry).forEach(key => {
        // if the key is everything but 'shopify_sku', because we already have a sku field
        if (key !== 'shopify_sku') {
          // set the new entry key to the value from the csv entry at the key
          newEntry[key] = currentCSVEntry[key];
        }
      });
      // push entry into list
      newEntries.push(newEntry);
    }
  }
  // once finished iterating, concat any new entries to the end of the list
  shopifyData = shopifyData.concat(newEntries);
  // return the shopify data
  return shopifyData;
};

const parseCSVBodyData = CSVMatrix => {
  let parsedResults = [];
  let titleRow = CSVMatrix[0];
  for (let i = 1; i < CSVMatrix.length; i++) {
    let currentEntry = CSVMatrix[i];
    let parsedEntry = {};
    currentEntry.forEach((value, index) => {
      let key = titleRow[index].toLowerCase().split(' ').join('_'); // Example: Hello World => hello_world
      parsedEntry[key] = value;
    });
    parsedResults.push(parsedEntry);
  }
  return parsedResults;
};

const inventoryReducer = (state = INITIAL_STATE, action) => {
  let newState = {...state};
  switch (action.type) {
    case types.PROCESS_NEW_CSV_HEADERS:
      let newHeaders = extractHeaders(action.payload.headers);
      newState.tableHeaders = newState.tableHeaders.concat(newHeaders);
      return newState;

    case types.PROCESS_SHOPIFY_PRODUCT_DATA:
      // let extractedData = extractTableData(action.payload.productsData);
      // newState.shopifyProducts = extractedData;
      newState.shopifyProducts = action.payload.productsData;
      return newState;

    case types.PROCESS_SHOPIFY_MAPPED_PRODUCT_DATA:
      newState.mappedShopifyProducts = action.payload.productsData;
      return newState;

    case types.PROCESS_SHOPIFY_MERGE_SKU:
      newState.shopifyMerge = action.payload.data;
      return newState;

    case types.PROCESS_WEB_SHIPMENT:
      newState.shopifyShipDate = action.payload.data;
      return newState;

    case types.UPDATE_WEB_SHIPMENT:
      const index = _.findIndex(newState.shopifyShipDate, {sku: action.payload.data.sku});
      if(typeof index !== 'undefined') {
        newState.shopifyShipDate[index].shipDate = action.payload.data.shipDate
      } else {
        (newState.shopifyShipDate).push({
          sku: action.payload.data.sku,
          shipDate: action.payload.data.shipDate,
          shipmentId: null,
          sell_thru: null,
          waitlist: null,
        })
      }

      return newState;

    case types.GET_ORDER_WAITLIST:
      newState.orderWaitlist = action.payload.data;
      return newState;

    case types.PROCESS_UPLOADED_CSV_DATA:
      let parsedBodyData = parseCSVBodyData(action.payload.csvMatrix);
      let mappedData = mapCSVBodyDataToShopifyData(parsedBodyData, [...newState.shopifyProducts]);
      newState.shopifyProducts = mappedData;
      return newState;

    case types.UPDATE_TABLE_HEADER:
      let currentHeader = action.payload.header;
      newState.tableHeaders.map(header => {
        if (header.label === action.payload.header.label) {
          header.onTable = !currentHeader.onTable
        }

        return header;
      })
      newState.tableHeaders = [ ...newState.tableHeaders ]
      return newState;

    case types.SUBMIT_TABLE_HEADER:
      newState.tableHeaders = [...action.payload.headers];
      return newState;

    default:
      return newState;
  }
};

export default inventoryReducer;
