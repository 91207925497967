import { AppThunk } from 'redux/store';
import { KitsActions } from 'reducers/kitsReducer';
import BackendService from 'services/Backend/Backend.service';
import { toast } from 'react-toastify';

export const deleteKit = ({ id, name }: { id: number, name?: string }): AppThunk => async (dispatch, getState) => {
  try {
    await BackendService.deleteKit(id);
    dispatch(KitsActions.deleteKit(id));
    toast.success(`Deleted Kit: ${name ?? id}`);
  } catch (error) {
    toast.error('Could not delete Kit. Please try again.');
    console.log('error', error);
  }
};
