import { css, StyleSheet } from 'aphrodite';
import Text, { Font } from 'components/Text';
import React from 'react';
import { black, blueGray, darkBlue, tableLineGray, white } from 'shared/styles/colors';
import { Kit } from 'types/state';
import { ReactComponent as LayersIcon } from 'assets/svgs/layers-icon.svg';
import { ReactComponent as TrashIcon } from 'assets/svgs/trash-icon.svg';
import { ReactComponent as EditIcon } from 'assets/svgs/edit-icon.svg';
import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { deleteKit } from 'redux/thunks';
import { KitsActions } from 'reducers/kitsReducer';
import ReactPlaceholder from 'react-placeholder';
import { RectShape } from 'react-placeholder/lib/placeholders';
import "react-placeholder/lib/reactPlaceholder.css";


const variants = {
  enter: {
    x: 0,
    opacity: 1,
  },
  exit: {
    x: -50,
    opacity: 0,
  },
};

interface Props {
  kit: Kit;
}

const KitCard = ({
  kit: {
    name,
    sku,
    components,
    id,
  },
}: Props) => {
  const dispatch = useDispatch();

  let titleComponent: React.ReactNode = name;
  if (!name) {
    titleComponent = (
      <>
        {/* @ts-ignore */}
        <ReactPlaceholder
          showLoadingAnimation
          customPlaceholder={<RectShape color={tableLineGray} style={{ width: '80%', height: 24 }} />}
        />
      </>
    );
  }

  return (
    <motion.div variants={variants} className={css(styles.KitCard)}>
      <div className={css(styles.topRow)}>
        <Text style={styles.sku}>
          {sku}
        </Text>
        <div className={css(styles.topRightRow)}>
          <div
            className={css(styles.editIconWrapper)}
            onClick={() => dispatch(KitsActions.openEditKitModal(sku))}
          >
            <EditIcon />
          </div>
          <div
            className={css(styles.trashIconWrapper)}
            onClick={() => dispatch(deleteKit({ id, name }))}
          >
            <TrashIcon />
          </div>
        </div>
      </div>
      <Text font={Font.SourceSansProBold} style={styles.name}>
        {titleComponent}
      </Text>
      <div className={css(styles.componentHeaderRow)}>
        <LayersIcon />
        <Text font={Font.SourceSansProBold} style={styles.componentsHeader}>
          components
        </Text>
      </div>
      <div className={css(styles.componentsContainer)}>
        <div className={css(styles.componentsTopRow)}>
          <Text font={Font.SourceSansProBold} style={[styles.componentHeader, styles.componentsColumn]}>
            SKU
          </Text>
          <Text font={Font.SourceSansProBold} style={[styles.componentHeader, styles.componentsColumnQuantity]}>
            Quantity
          </Text>
          <Text font={Font.SourceSansProBold} style={[styles.componentHeader, styles.componentsColumnName]}>
            Name
          </Text>
        </div>
        {components.map(({ sku, name, quantity}) => {
          let nameComponent: React.ReactNode = name;
          if (!name) {
            nameComponent = (
              <>
                {/* @ts-ignore */ }
                <ReactPlaceholder
                  showLoadingAnimation
                  customPlaceholder={<RectShape color={tableLineGray} style={{ width: '90%', height: 24 }} />}
                />
              </>
            );
          }
          return (
            <div key={sku} className={css(styles.component)}>
              <Text style={[styles.componentText, styles.componentsColumn]}>
                {sku}
              </Text>
              <Text style={[styles.componentText, styles.componentsColumnQuantity]}>
                {quantity}
              </Text>
              <Text style={[styles.componentTextName, styles.componentsColumnName]}>
                {nameComponent}
              </Text>
            </div>
          );
        })}
      </div>
    </motion.div>
  );
};

export default KitCard;

const styles = StyleSheet.create({
  KitCard: {
    boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.1)",
    // height: 312,
    width: 480,
    padding: '16px 24px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: white,
    borderRadius: 4,
  },
  //
  topRow: {
    marginBottom: 8,
    display: 'flex',
    justifyContent: 'space-between',
  },
  sku: {
    fontSize: 16,
    color: blueGray,
    textTransform: 'capitalize',
  },
  name: {
    fontSize: 20,
    color: black,
    marginBottom: 22,
    lineHeight: 1.2,
  },
  componentHeaderRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
  },
  componentsHeader: {
    marginLeft: 4,
    fontSize: 14,
    color: darkBlue,
    textTransform: 'uppercase',
  },
  componentsContainer: {
    padding: '8px 12px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f7f7f8',
    flex: 1,
  },
  componentsTopRow: {
    marginBottom: 8,
    display: 'flex',
  },
  componentsColumn: {
    width: 100,
    marginRight: 8,
  },
  componentsColumnName: {
    width: 200,
  },
  componentsColumnQuantity: {
    width: 60,
    marginRight: 24,
  },
  componentHeader: {
    fontSize: 14,
    color: darkBlue,
  },
  component: {
    display: 'flex',
    marginBottom: 8,
  },
  componentText: {
    textTransform: 'uppercase',
    fontSize: 14,
    color: black,
  },
  componentTextName: {
    fontSize: 14,
    color: black,
    lineHeight: 1.3,
  },
  editIconWrapper: {
    cursor: 'pointer',
    marginRight: 12,
  },
  trashIconWrapper: {
    cursor: 'pointer',
  },
  topRightRow: {
    display: 'flex',
    alignItems: 'center',
  },
});
