import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Modal.scss';

class Modal extends Component {
    render() {
        const { onClose, onSubmit, children, title, subtitle , textOnSubmit, textOnClose } = this.props;

        if (!this.props.show) {
            return null;
        }

        return (
            <div className="backdrop">
                <div className="modal">
                    <div className="modal-head">
                        <div className="modal-head-title">{title}</div>
                        <div className="modal-head-subtitle">{subtitle}</div>
                    </div>
                    <div className="modal-body">
                        {children}
                    </div>
                    <div className="modal-footer">
                        <button className="button button-close" onClick={onClose}><span className="btnText">{ !!textOnClose ? textOnClose : "Cancel" }</span></button>
                        <button className="button button-submit" onClick={onSubmit}><span className="btnText">{!!textOnSubmit ? textOnSubmit : "Apply"}</span></button>
                    </div>
                </div>
            </div>
        );
    }
}

Modal.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool,
    children: PropTypes.node,
    title: PropTypes.string.isRequired
};

Modal.defaultProps = {
    show: false
}

export default Modal;