import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import moment from 'moment';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import { confirmAlert } from 'react-confirm-alert';
import { format, parse } from 'date-fns';

import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import actions from '../../actions';

import ShipmentAddSKU from './ShipmentAddSKU';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { compareCaseInsensitive } from 'shared/utils';

const INITIAL_DATA = {
  dashStatus: 'On-hold',
  shipingVendor: 'None',
  shipingMeans: 'Air Express',
  tracking: '',
  shipDate: null,
  landingDate: null,
  customerShipDate: null
};

class Shipment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orderId: null,
      shipmentId: null,
      loaded: false,
      shipmentLoaded: false,
      shipmentItemsLoaded: false,
      available: null,
      expected: null,
      items: [],
      openAdd: false,
      ordersSelected: [],
      formData: INITIAL_DATA
    };

    this.handleOrderChange = this.handleOrderChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleCloseAdd = this.handleCloseAdd.bind(this);
    this.handleUpdateSelected = this.handleUpdateSelected.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    let { match, shipment } = this.props;

    if (match.params.id) {
      this.setState({ orderId: match.params.id });

      this.props.actions.fetchOrderDetail(match.params.id).then(response => {
        let data = [...response];
        let itemsSelected = [];

        data = data.filter(r => {
          if (match.params.shipmentId) return true;
          if (shipment.selectedSKU.length === 0) return true;

          // NOTE: may be source of capitilization bug if it still exists
          if (
            shipment.selectedSKU.indexOf(r.sku) !==
            -1
          ) {
            return true;
          } else {
            return false;
          }
        });

        for (let i = 0; i < data.length; i++) {
          let currentRecord = data[i];

          let temp = {
            sku: currentRecord.sku,
            available: 0,
            expected: 0,
            waitlist: false,
            orderId: match.params.id,
            shipmentId: 'new',
            id: 'new'
          };

          if (this.state.shipmentId !== null) {
            if (this.state.items.length > 0) {
              let search = _.find(this.state.items, item =>
                compareCaseInsensitive(item.sku, currentRecord.sku)
              );

              if (typeof search !== 'undefined') {
                temp = Object.assign(temp, search);
                itemsSelected.push(temp);
              }
            }
          } else {
            itemsSelected.push(temp);
          }
        }

        this.setState({
          loaded: true,
          ordersSelected: [...response],
          items: itemsSelected
        });
      });
    }

    if (match.params.shipmentId) {
      this.setState({ shipmentId: match.params.shipmentId });

      this.props.actions
        .fetchShipmentDetails(match.params.shipmentId)
        .then(response => {
          let customerShipDate = response[0].customerShipDate;
          if (response[0].customerShipDate !== null) {
            let split = response[0].customerShipDate.split('-');
            customerShipDate = new Date(
              Date.UTC(split[0], split[1] - 1, split[2], 12, 0, 0)
            );
          }

          let landingDate = response[0].landingDate;
          if (response[0].landingDate !== null) {
            let split = response[0].landingDate.split('-');
            landingDate = new Date(
              Date.UTC(split[0], split[1] - 1, split[2], 12, 0, 0)
            );
          }

          let shipDate = response[0].shipDate;
          if (response[0].shipDate !== null) {
            let split = response[0].shipDate.split('-');
            shipDate = new Date(
              Date.UTC(split[0], split[1] - 1, split[2], 12, 0, 0)
            );
          }

          this.setState({
            shipmentLoaded: true,
            formData: {
              ...this.state.formData,
              shipmentId: response[0].shipmentId,
              dashStatus: response[0].dashStatus,
              shipingVendor: response[0].shipingVendor,
              shipingMeans: response[0].shipingMeans,
              tracking: response[0].tracking,
              shipDate,
              landingDate,
              customerShipDate
            }
          });
        });

      this.props.actions
        .fetchShipmentItems(match.params.shipmentId)
        .then(response => {
          this.setState({
            shipmentItemsLoaded: true,
            items: response
          });
        });
    } else {
      this.setState({
        shipmentLoaded: true,
        shipmentItemsLoaded: true
      });
    }
  }

  handleOrderChange(key, value, index) {
    let data = [...this.state.items];
    let temp = Object.assign({}, data[index]);

    temp[key] = value;

    if (key === 'waitlist') {
      temp['available'] = 0;
    }

    data[index] = temp;

    if (key === 'waitlist') {
      if (
        typeof _.find(data, { waitlist: false }) !== 'undefined' ||
        typeof _.find(data, { waitlist: null }) !== 'undefined' ||
        typeof _.find(data, { waitlist: 0 }) !== 'undefined'
      ) {
      } else {
        this.handleChange('customerShipDate', null);
      }
    }

    this.setState({
      items: data
    });
  }

  handleChange(key, value) {
    this.setState({
      formData: {
        ...this.state.formData,
        [key]: value
      }
    });
  }

  handleAdd() {
    this.setState({ openAdd: true });
  }

  handleCloseAdd() {
    this.setState({ openAdd: false });
  }

  handleUpdateSelected(newData) {
    this.setState({ items: newData });
  }

  handleRemove(i) {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let { items } = this.state;
            items.splice(i, 1);

            this.setState({ items: items });
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  handleCancel() {
    const { history } = this.props;
    const { orderId } = this.state;

    this.setState({ formData: INITIAL_DATA });

    history.push('/orders/' + orderId);
  }

  handleSubmit() {
    const { formData, orderId, shipmentId, items } = this.state;
    const { history } = this.props;

    let error = false;

    if (error) {
      alert('Please fill add data');
      return;
    }

    let ordersData = [...items];
    let filledData = { ...formData };
    filledData.orderId = parseInt(orderId);

    if (filledData.shipDate !== null)
      filledData.shipDate = moment(filledData.shipDate).format('YYYY-MM-DD');

    if (filledData.landingDate !== null)
      filledData.landingDate = moment(filledData.landingDate).format(
        'YYYY-MM-DD'
      );

    if (filledData.customerShipDate !== null)
      filledData.customerShipDate = moment(filledData.customerShipDate).format(
        'YYYY-MM-DD'
      );

    const data = {
      orders: ordersData,
      data: filledData
    };

    if (shipmentId !== null) {
      this.props.actions.updateShipment(data, shipmentId).then(res => {
        this.props.actions.fetchShipmentAllItems();
        this.props.actions.fetchShipment(orderId);
        history.push('/orders/' + orderId);
      });
    } else {
      this.props.actions.storeShipment(data).then(res => {
        this.props.actions.fetchShipment(orderId);
        history.push('/orders/' + orderId);
      });
    }
  }

  render() {
    const {
      loaded,
      shipmentLoaded,
      shipmentItemsLoaded,
      orderId,
      ordersSelected,
      formData,
      shipmentId,
      items
    } = this.state;
    const { order } = this.props;
    const { shopifyProductsData, shiphero, shipment } = this.props;

    if (!loaded || !shipmentLoaded || !shipmentItemsLoaded) {
      return (
        <div className={'main-block'}>
          loading...
        </div>
      );
    }

    const { shipmentAllItems, shipmentAll, shipmentBills } = shipment;
    let customerShipDate = true;

    const exist = _.find(items, { waitlist: false });
    const exist2 = _.find(items, { waitlist: null });
    const exist3 = _.find(items, { waitlist: 0 });
    if (
      typeof exist === 'undefined' &&
      typeof exist2 === 'undefined' &&
      typeof exist3 === 'undefined'
    ) {
      customerShipDate = false;
    }

    return (
      <div className={'main-block'}>
        <main className="Main">
          <div className="Main__Content">
            <div className="Title__Content">
              <div className="Title flex-item fixed-title">
                {shipmentId === null
                  ? 'Add shipment - Purchase Order #' +
                    orderId +
                    ' - ' +
                    order.orderDetailsData[0].artisan
                  : 'Edit shipment'}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleAdd}
                  className={'Button Button--blue right-align'}
                >
                  <span className="Label">Add SKU</span>
                </Button>
              </div>
            </div>
            <div className="order-items">
              {items.map((r, i) => {
                let order = _.find(ordersSelected, record =>
                  compareCaseInsensitive(record.sku, r.sku)
                );
                let shipHeroProduct = _.find(shiphero.processedData, record =>
                  compareCaseInsensitive(record.sku, r.sku)
                );

                // Web Ship Date
                // ------------------------------------------------------------
                let shipDate = 'N/A';

                // New Shopify SKU
                const newShopifyProduct = _.find(shopifyProductsData.shopifyShipDate, record =>
                  compareCaseInsensitive(record.sku, r.sku)
                );
                if (newShopifyProduct?.shipDate) {
                  try {
                    if (newShopifyProduct.waitlist) {
                      shipDate = 'Waitlist';
                    } else {
                      const date = parse(newShopifyProduct.shipDate, 'yyyy-MM-dd', new Date());
                      shipDate = format(date, 'MM-dd-y');
                    }
                  } catch (error) {
                    console.log(error);
                  }
                }
                // Old Shopify SKU
                const oldShopifyMergeProduct = _.find(shopifyProductsData.shopifyMerge, record =>
                  compareCaseInsensitive(record.newSKU, r.sku)
                );
                if (oldShopifyMergeProduct) {
                  const oldShopifyProduct = _.find(shopifyProductsData.shopifyShipDate, record =>
                    compareCaseInsensitive(record.sku, oldShopifyMergeProduct.SKU)
                  );
                  if (oldShopifyProduct?.shipDate) {
                    try {
                      if (newShopifyProduct.waitlist) {
                        shipDate = 'Waitlist';
                      } else {
                        const date = parse(oldShopifyProduct.shipDate, 'yyyy-MM-dd', new Date());
                        shipDate = format(date, 'MM-dd-y');
                      }
                    } catch (error) {
                      console.log(error);
                    }
                  }
                }
                // ------------------------------------------------------------
                let totalShiped = 0;

                shipmentAllItems
                  .filter(single => {
                    if (
                      single.sku === r.sku &&
                      single.orderId === parseInt(orderId)
                    ) {
                      return true;
                    }
                    return false;
                  })
                  .filter(single => {
                    if (single.shipmentId === parseInt(shipmentId)) {
                      return false;
                    }

                    let shipmentDataSingle = _.find(shipmentAll, {
                      id: single.shipmentId
                    });
                    if (shipmentDataSingle) {
                      let bill = _.find(shipmentBills, {
                        PurchaseOrderId: shipmentDataSingle.shipmentId
                      });

                      if (typeof bill === 'undefined') {
                        return true;
                      }

                      return false;
                    }

                    return true;
                  })
                  .map(single => {
                    totalShiped = totalShiped + single.expected;
                    return true;
                  });

                let availablePO =
                  order.quantity_ordered -
                  order.quantity_received -
                  totalShiped -
                  r.expected;

                return (
                  <div className="order-items__single">
                    <span
                      className={'close-button'}
                      onClick={e => this.handleRemove(i)}
                    />
                    <div className="order-items__main-data">
                      <div className="order-items__line">
                        <div className="order-items__sku">{r.sku}</div>
                        <div className="order-items__main-column">
                          {order.product_name !== null && (
                            <div className="order-items__title">
                              {order.product_name}
                            </div>
                          )}
                          <div className="order-items__manage">
                            <div className="order-items__manage-item">
                              <label>Avail for Pre-sell Qty</label>
                              {r.waitlist ? (
                                <input
                                  type="number"
                                  disabled="disabled"
                                  className="order-items__input"
                                  value={r.available}
                                  onChange={e => {
                                    this.handleOrderChange(
                                      'available',
                                      e.target.value,
                                      i
                                    );
                                  }}
                                />
                              ) : (
                                <input
                                  type="number"
                                  className="order-items__input"
                                  value={r.available}
                                  onChange={e => {
                                    this.handleOrderChange(
                                      'available',
                                      e.target.value,
                                      i
                                    );
                                  }}
                                />
                              )}
                            </div>
                            <div className="order-items__manage-item">
                              <label>Expected Qty</label>
                              <input
                                type="number"
                                className="order-items__input"
                                value={r.expected}
                                onChange={e => {
                                  this.handleOrderChange(
                                    'expected',
                                    e.target.value,
                                    i
                                  );
                                }}
                              />
                            </div>
                            <div className="order-items__manage-item">
                              <label>Waitlist</label>
                              <input
                                type="checkbox"
                                defaultChecked={false}
                                checked={r.waitlist}
                                onChange={e => {
                                  this.handleOrderChange(
                                    'waitlist',
                                    e.target.checked,
                                    i
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="order-items__secondary-data">
                      <span>
                        Warehouse Backorder:{' '}
                        {typeof shipHeroProduct !== 'undefined'
                          ? shipHeroProduct.backorder
                          : 'N/A'}
                      </span>
                      <span className={availablePO < 0 ? 'minus-record' : ''}>
                        Left on PO: {availablePO}
                      </span>
                      <span>
                        Quantity Ordered: {order.quantity_ordered}
                      </span>
                      <span>Web ship date: {shipDate}</span>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="shipment-block">
              <div className="shipment-block__enter-data">
                <div className="shipment-block__item">
                  <label>Artisan ship date</label>
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    autoComplete="off"
                    selected={formData.shipDate}
                    onChange={date => this.handleChange('shipDate', date)}
                    name="shipDate"
                  />
                </div>
                <div className="shipment-block__item">
                  <label>Status</label>
                  <FormControl className={'customSelect'}>
                    <Select
                      value={formData.dashStatus}
                      onChange={e => {
                        this.handleChange('dashStatus', e.target.value);
                      }}
                      displayEmpty
                      name="dashStatus"
                    >
                      <MenuItem value={'On-hold'}>On-hold</MenuItem>
                      <MenuItem value={'Planning'}>Planning</MenuItem>
                      <MenuItem value={'In Production'}>In Production</MenuItem>
                      <MenuItem value={'Preparing Shipping'}>
                        Preparing Shipping
                      </MenuItem>
                      <MenuItem value={'Shipping'}>Shipping</MenuItem>
                      <MenuItem value={'Delayed'}>Delayed</MenuItem>
                      <MenuItem value={'Partial Landed'}>
                        Partial Landed
                      </MenuItem>
                      <MenuItem value={'Landed'}>Landed</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="shipment-block__item">
                  <label>Estimated landing date</label>
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    autoComplete="off"
                    selected={formData.landingDate}
                    onChange={date => this.handleChange('landingDate', date)}
                    name="landingDate"
                  />
                </div>
                <div className="shipment-block__item">
                  <label>Shipping Vendor</label>
                  <FormControl className={'customSelect'}>
                    <Select
                      value={formData.shipingVendor}
                      onChange={e => {
                        this.handleChange('shipingVendor', e.target.value);
                      }}
                      name="shipingVendor"
                    >
                      <MenuItem value={'None'}>None</MenuItem>
                      <MenuItem value={'DHL'}>DHL</MenuItem>
                      <MenuItem value={'FedEx'}>FedEx</MenuItem>
                      <MenuItem value={'In-Country Provider'}>
                        In-Country Provider
                      </MenuItem>
                      <MenuItem value={'UPS'}>UPS</MenuItem>
                      <MenuItem value={'WWE'}>WWE</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="shipment-block__item">
                  <label>Earliest customer ship date </label>
                  {customerShipDate ? (
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      autoComplete="off"
                      selected={formData.customerShipDate}
                      onChange={date =>
                        this.handleChange('customerShipDate', date)
                      }
                      name="customerShipDate"
                    />
                  ) : (
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      autoComplete="off"
                      disabled="disabled"
                      selected={formData.customerShipDate}
                      onChange={date =>
                        this.handleChange('customerShipDate', date)
                      }
                      name="customerShipDate"
                    />
                  )}
                </div>
                <div className="shipment-block__item">
                  <label>Shipping Means</label>
                  <FormControl className={'customSelect'}>
                    <Select
                      value={formData.shipingMeans}
                      onChange={e => {
                        this.handleChange('shipingMeans', e.target.value);
                      }}
                      name="shipingMeans"
                    >
                      <MenuItem value={'Air Express'}>Air Express</MenuItem>
                      <MenuItem value={'Air Freight'}>Air Freight</MenuItem>
                      <MenuItem value={'Ground'}>Ground</MenuItem>
                      <MenuItem value={'Ocean'}>Ocean</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="shipment-block__item">
                  <label>Tracking</label>
                  <input
                    type="text"
                    name="tracking"
                    value={formData.tracking}
                    onChange={e => {
                      this.handleChange('tracking', e.target.value);
                    }}
                  />
                </div>
                {shipmentId !== null && (
                  <div className="shipment-block__item">
                    <label>Shipment ID</label>
                    <input
                      type="text"
                      name="shipmentId"
                      value={formData.shipmentId}
                      onChange={e => {
                        this.handleChange('shipmentId', e.target.value);
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="shipment-block__submit-area">
                <Button
                  variant="contained"
                  onClick={this.handleCancel}
                  className={'Button'}
                >
                  <span className="Label">Cancel</span>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleSubmit}
                  className={'Button Button--blue'}
                >
                  <span className="Label">
                    {shipmentId === null ? 'APPLY' : 'UPDATE'}
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </main>
        <ShipmentAddSKU
          allItems={ordersSelected}
          selected={items}
          open={this.state.openAdd}
          handleClose={this.handleCloseAdd}
          handleUpdate={this.handleUpdateSelected}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

export default connect(
  state => state,
  mapDispatchToProps
)(Shipment);
