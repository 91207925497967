import axios from 'axios';
import * as types from '../types';

const processRawShipHeroProductsAction = (products) => {
  return {
    type: types.PROCESS_RAW_SHIPHERO_PRODUCTS,
    payload: {
      products
    }
  };
};

export function fetchShipHeroData() {
  return async (dispatch) => {
    try {
      const results = await axios.get('/shiphero/data');
      dispatch(processRawShipHeroProductsAction(results.data));
      // const results = await axios.get('/shiphero');
      // dispatch(processRawShipHeroProductsAction(results.data));
    } catch (error) {
      console.log('error from server: ', error);
    }
  };
}

export function updateShipHeroDataAction(sku) {
  return async (dispatch) => {
    try {
      const results = await axios.post('/shiphero/update/', {sku});
      console.log(results.data)
      return true
    } catch (error) {
      console.log('error from server: ', error);
    }
  };
}


