import { css, StyleSheet } from "aphrodite";
import { Style } from "types/shared";
import React from "react";
import { black } from "shared/styles/colors";

interface Props {
  key?: number | string;
  children?: React.ReactNode;
  style?: Style | Style[];
  font?: Font;
}

const Text = ({
  children,
  style,
  font = Font.SourceSansProRegular,
}: Props) => {
  return (
    <div className={css(styles.text, font && styles[font], style)}>
      {children}
    </div>
  );
};

export enum Font {
  SourceSansProBold = "SourceSansProBold",
  SourceSansProRegular = "SourceSansProRegular",
  SourceSansProSemibold = "SourceSansProSemibold",
}

// Styles
// ----------------------------------------------------------------------------
export const SourceSansPro = "'Source Sans Pro', sans-serif";
export const styles = StyleSheet.create({
  // fonts
  [Font.SourceSansProBold]: {
    fontFamily: SourceSansPro,
    fontWeight: 700,
  },
  [Font.SourceSansProRegular]: {
    fontFamily: SourceSansPro,
    fontWeight: 400,
  },
  [Font.SourceSansProSemibold]: {
    fontFamily: SourceSansPro,
    fontWeight: 600,
  },
  //
  text: {
    fontSize: 14,
    color: black,
  },
});

export const textStyles = styles;
export default Text;
