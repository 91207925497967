import { isValidDate } from "./utils";

export const sortMethodInventoryQuantity = (a, b) => {
  const coerceShipheroInventory = (value) => {
    let newValue;
    if (typeof value === 'string' && value === 'N/A') {
      newValue = null;
    } else if (typeof value === 'object') {
      // assume it is a react component with a date-value attribute set
      newValue = parseInt(value.props['data-value']);
    }
    return newValue;
  }

  a = coerceShipheroInventory(a);
  b = coerceShipheroInventory(b);

  // force null and undefined to the bottom
  a = a === null || a === undefined ? -Infinity : a
  b = b === null || b === undefined ? -Infinity : b

  // Return either 1 or -1 to indicate a sort priority
  if (a > b) {
    return 1
  }
  if (a < b) {
    return -1
  }

  // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
  return 0
};

export const sortByDate = (a, b) => {
  const coerceDate = (value) => {
    if (isValidDate(value)) {
      value = value.getTime();
    } else if ([null, undefined].includes(value) || typeof value === 'object') {
      value = -1100;
    } else if (value === 'Waitlist') {
      value = -1000;
    }

    return value;
  }

  a = coerceDate(a);
  b = coerceDate(b);

  if (a === b) {
    return 0;
  }

  return a > b ? 1 : -1;
}

export const sortNumber = (a, b) => {
  const coerceString = (value) => {
    let newValue = value;
    if (typeof value === 'string') {
      if (value === 'N/A') {
        newValue = null;
      } else {
        newValue = parseInt(value);
      }
    }
    return newValue;
  }

  a = coerceString(a);
  b = coerceString(b);

  // force null and undefined to the bottom
  a = a === null || a === undefined ? -Infinity : a
  b = b === null || b === undefined ? -Infinity : b

  // Return either 1 or -1 to indicate a sort priority
  if (a > b) {
    return 1
  }
  if (a < b) {
    return -1
  }

  // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
  return 0
};
