import React from "react";
import {Route, Switch} from "react-router-dom";

import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import actions from '../actions';

import PurchaseOrders from "../layouts/PurchaseOrders";
import OrderDetails from "../views/Orders/OrderDetails";
import Shipment from "../views/Orders/Shipment";
import ShipmentView from "../views/Orders/ShipmentView";

class OrdersRoute extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.actions.fetchShipHeroData();
    this.props.actions.fetchShopifyMergeSKU();
    this.props.actions.getWebShipmentDate();
    this.props.actions.fetchShipmentAllItems();
    this.props.actions.fetchShipmentAll();
    this.props.actions.fetchShipmentBills();
  }

  render() {
    let {match} = this.props;

    return (
      <Switch>
        <Route exact path={`${match.url}`} component={PurchaseOrders}/>
        <Route path={`${match.url}/:id/shipment/:shipmentId/view`} component={ShipmentView}/>
        <Route path={`${match.url}/:id/shipment/:shipmentId`} component={Shipment}/>
        <Route path={`${match.url}/:id/shipment`} component={Shipment}/>
        <Route path={`${match.url}/:id`} component={OrderDetails}/>
      </Switch>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
});

export default connect(
  state => state,
  mapDispatchToProps
)(OrdersRoute);
