import firebase from 'firebase';
import * as types from '../types';

const signInSuccess = (email, password) => ({
  payload: {email, password},
  type: types.SIGN_IN_SUCCESS
});

const signInError = (error) => ({
  payload: {error},
  type: types.SIGN_IN_ERROR
});

/*
  Attempts Email and Password signin through Google Firebase,
  and dispatches appropriate action for un/successful attempts
 */
export const signInWithEmailAndPassword = (email, password) => {
  return (dispatch) => {
    firebase.auth().signInWithEmailAndPassword(email, password)
      .then(response => {
        dispatch(signInSuccess(email, password));
        localStorage.setItem('isAutenticated', true);
        localStorage.setItem('userId', firebase.auth().currentUser.uid);
      })
      .catch(error => {
        dispatch(signInError(error));
        localStorage.removeItem('isAutenticated');
        localStorage.removeItem('userId');
      })
  }
}