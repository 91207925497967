import { captureException } from '@sentry/react';

export function adjustShipheroBackorderAndAvailable(shipheroProducts) {
  return shipheroProducts.map(product => {
    const {
      available,
      backorder,
      sku,
    } = product;

    let newAvailable = available;
    let newBackorder = backorder;

    /*
      IF AVAILABLE - BACKORDER IS POSITIVE OR ZERO, THEN:
      Dashboard ShipHero Inventory = ShipHero Available - ShipHero Backorder
      Dashboard ShipHero Backorder = ShipHero Backorder - ShipHero Backorder (zeroes out inventory)
      IF AVAILABLE - BACKORDER IS NEGATIVE, THEN:
      Dashboard ShipHero Inventory = ShipHero Available - ShipHero Available (zeroes out available inventory)
      Dashboard ShipHero Backorder = -(ShipHero Available - ShipHero Backorder)
    */
    if (available - backorder >= 0) {
      newAvailable = available - backorder;
      newBackorder = 0;
    } else if (available - backorder < 0) {
      newAvailable = 0;
      newBackorder = -(available - backorder);
    } else {
      captureException(`Unexpected available and backorder for product sku: ${sku}`);
    }

    return {
      ...product,
      backorder: newBackorder,
      available: newAvailable,
    };
  });
}
