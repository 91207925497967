import axios from 'axios';

export const fetchInboundShipmentsForOrderId = (orderId) => {
  return () => {
    console.log('orderId', orderId);
    return axios.get(`/inbound-shipment/${orderId}`)
      .then(response => {
        console.log('response.data', response.data);
        return response.data;
      })
      .catch(error => {
        console.log('error from server: ', error);
      });
  }
};