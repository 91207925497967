import { css, StyleSheet } from 'aphrodite';
import React, { useEffect, useRef, useState } from 'react';
import Text, { Font } from 'components/Text';
import { black, brightGreen, ctaBlue, darkBlue, green, red, white } from 'shared/styles/colors';
import Button, { ButtonType } from 'components/Button';
import { useDispatch } from 'react-redux';
import { getWarehouseSKUs, uploadWarehouseInventory } from 'redux/thunks';
import { motion, AnimatePresence } from 'framer-motion';
import { Helmet } from "react-helmet";
import { HTML_TITLE_SUFFIX } from 'shared/constants';
import { useTypedSelector } from 'shared/utils';
import { ReactComponent as LargePackageIcon } from "assets/svgs/large-package-icon.svg";
import { ReactComponent as RocketWhiteIcon } from "assets/svgs/rocket-white.svg";
import { ReactComponent as PackageCheckIcon } from "assets/svgs/package-check.svg";
import PapaParse from 'papaparse';
import { WarehouseInventoryChange } from 'types/shared';
import Spinner from 'react-spinkit';


const InventoryUpload = () => {
  const dispatch = useDispatch();
  const [file, setFile] = useState<File | null>(null);
  const [csvWarehouseSKUsError, setCSVWarehouseSKUsError] = useState<string | null>(null);
  const [csvHasValidSKUs, setCSVHasValidSKUs] = useState<boolean>(false);
  const [parsedSKUs, setParsedSKUs] = useState<WarehouseInventoryChange[] | null>();
  const [parsedSKUsError, setParsedSKUsError] = useState<string | null>();
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const {
    warehouseSKUs,
    isFetchingInitialData,
    isUploading,
    uploadSuccess,
    uploadError,
  } = useTypedSelector((
    {
      inventoryUpload: {
        isFetchingInitialData,
        warehouseSKUs,
        isUploading,
        uploadError,
        uploadSuccess,
      }
    }
  ) => ({
    isFetchingInitialData,
    warehouseSKUs,
    isUploading,
    uploadError,
    uploadSuccess,
  }));
  
  useEffect(() => {
    dispatch(getWarehouseSKUs());
  }, []);

  useEffect(() => {
    if (uploadSuccess) {
      setFile(null);
      setCSVHasValidSKUs(false);

    }
  }, [uploadSuccess])

  useEffect(() => {
    if (parsedSKUs && warehouseSKUs) {
      const nonWarehouseSKUs: string[] = [];
      let hasEmptyRow = false;
      let missingValues: string[] = [];
      for (const { sku, quantity } of parsedSKUs) {
        if (sku === '') {
          hasEmptyRow = true;
        } else if (warehouseSKUs.indexOf(sku.toUpperCase()) === -1) {
          nonWarehouseSKUs.push(sku);
        }
        
        if (isNaN(quantity)) {
          missingValues.push(sku);
        }
      }

      const formatSKUs = (SKUs: string[]) => {
        return SKUs.reduce((prev, current, index) => {
          return prev.concat(index === 0 ? current : `,${index > 0 && index === nonWarehouseSKUs.length -1 ? ' and' : ''} ${current.toUpperCase()}`);
        }, '')
      }

      if (nonWarehouseSKUs.length > 0) {
        const formattedSKUs = formatSKUs(nonWarehouseSKUs);
        setCSVWarehouseSKUsError(`Unable to find SKU${nonWarehouseSKUs.length > 1 ? 's' : ''} ${formattedSKUs} in warehouse inventory`)
        setCSVHasValidSKUs(false);
      } else if (missingValues.length > 0) {
        const formattedSKUs = formatSKUs(missingValues);
        setCSVWarehouseSKUsError(`Missing decrement for SKU${missingValues.length > 1 ? 's' : ''} ${formattedSKUs}`)
      } else if (hasEmptyRow) {
        setCSVWarehouseSKUsError('CSV has empty rows')
      } else {
        setCSVHasValidSKUs(true);
        setCSVWarehouseSKUsError(null);
      }
    }
  }, [parsedSKUs, warehouseSKUs])

  useEffect(() => {
    if (file) {
      // @ts-ignore
      PapaParse.parse(file, {
          header: true,
          complete: (response) => {
            if (response.data) {
              const formattedData: WarehouseInventoryChange[] = [];
              for (const record of response.data) {
                const typedRecord = record as Record<string, string>
                formattedData.push({
                  sku: typedRecord.SKU,
                  quantity: parseInt(typedRecord['Decrement Dash Warehouse Inventory By'], 10)
                });
              }
              setParsedSKUs(formattedData);
            } else if (response.errors.length > 1) {
              setParsedSKUsError('Unable to parse CSV');
            }
          }
        }
      );
    }
  }, [file])

  
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSelectFileClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleInventoryUpload = () => {
    if (parsedSKUs) {
      dispatch(uploadWarehouseInventory(parsedSKUs));
    }
  }

  let bottomContent;
  if (!file) {
    if (uploadSuccess) {
      bottomContent = (
        <>
          <PackageCheckIcon />
          <Text
            style={styles.successText}
            font={Font.SourceSansProBold}
          >
            Inventory Modified
          </Text>
        </>
      );
    } else {
      bottomContent = (
        <LargePackageIcon />
      );
    }
  } else {
    const fileMetadata = (
      <Text style={[styles.emphasisText, styles.fileSelectedContainer]} font={Font.SourceSansProRegular}>
        <Text style={styles.fileSelectedText} font={Font.SourceSansProSemibold}>
          File selected: &nbsp;
        </Text>
        {file.name}
      </Text>
    );
    if (csvWarehouseSKUsError || parsedSKUsError) {
      bottomContent = (
        <>
          {fileMetadata}
          <Text style={styles.errorText} font={Font.SourceSansProSemibold}>
            {csvWarehouseSKUsError || parsedSKUsError}
          </Text>
        </>
      );
    } else if (csvHasValidSKUs) {
      bottomContent = (
        <>
          {fileMetadata}
          {isUploading && (
            <Spinner className={css(styles.spinner)} name="line-scale" color={ctaBlue} fadeIn="none" />
          )}
          <Button
            icon={<RocketWhiteIcon />}
            disabled={isUploading}
            onClick={handleInventoryUpload}
            buttonStyle={styles.selectCSVButton}
            buttonType={ButtonType.Primary}
            iconPosition="left"
          >
            Modify Inventory
          </Button>
        </>
      );
    }
  }

  return (
    <section className={css(styles.InventoryUpload)}>
      <Helmet>
        <title>Inventory Removal {HTML_TITLE_SUFFIX}</title>
      </Helmet>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, y: -18 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 0.5, duration: 0.6 }}
          className={css(styles.headerRow)}
        >
          <Text style={styles.header} font={Font.SourceSansProBold}>
            Inventory Removal
          </Text>
        </motion.div>
        <div className={css(styles.fullWidthContainer)}>
          <div className={css(styles.actionContainer)}>
            <div className={css(styles.actionContainerTop)}>
              <Button
                disabled={isFetchingInitialData}
                onClick={handleSelectFileClick}
                buttonStyle={styles.selectCSVButton}
                buttonType={ButtonType.Secondary}
              >
                Select CSV File
              </Button>
              <input
                ref={hiddenFileInput}
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                style={{display: 'none'}}
              />
              <Text style={styles.emphasisText} font={Font.SourceSansProSemibold}>
                Note: Selecting file will not automatically upload inventory
              </Text>
            </div>
            <div className={css(styles.actionContainerBottom)}>
              {bottomContent}
            </div>
          </div>
        </div>
      </AnimatePresence>
    </section>
  );
};

export default InventoryUpload;

const styles = StyleSheet.create({
  InventoryUpload: {
    padding: '40px 40px',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  loadingScreen: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  //
  header: {
    fontSize: 36,
    color: black,
    marginRight: 16,
    lineHeight: 1,
  },
  headerRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 32,
  },
  fullWidthContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    paddingBottom: 120,
  },
  actionContainer: {
    width: 600,
    height: 400,
    borderRadius: 8,
    backgroundColor: white,
    display: 'flex',
    flexDirection: 'column'
  },
  actionContainerTop: {
    padding: '40px 0 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  actionContainerBottom: {
    height: 250,
    flex: 1,
    backgroundColor: '#f6f7f9',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  selectCSVButton: {
    marginBottom: 12,
  },
  emphasisText: {
    fontSize: 14,
    color: darkBlue,
  },
  fileSelectedText: {
    color: darkBlue,
    display: 'inline-block'
  },
  fileSelectedContainer: {
    marginBottom: 12,
  },
  errorText: {
    color: red,
    maxWidth: 340,
    textAlign: 'center'
  },
  successText: {
    fontSize: 16,
    color: brightGreen,
    marginTop: 12,
  },
  spinner: {
    marginBottom: 12,
  }
});
