import { ImmerReducer } from "immer-reducer";
import { createActionCreators, createReducerFunction } from "immer-reducer";
import { TriggersState } from "types/state";


const initialState: TriggersState = {
  isTriggersDataLoading: true,
  isShipheroSyncCallLoading: false,
  hasActiveShipheroSync: undefined,
  lastShipheroSyncStartTime: undefined,
  hasLastSyncFailed: undefined,
};

class TriggersReducer extends ImmerReducer<TriggersState> {
  setIsShipheroSyncCallLoading(isLoading: boolean) {
    this.draftState.isShipheroSyncCallLoading = isLoading;
  }

  setIsTriggersDataLoading(isLoading: boolean) {
    this.draftState.isTriggersDataLoading = isLoading;
  }

  setTriggersData(data) {
    const {
      lastSync,
      lastSyncFailed,
      metadata,
      syncInProgress,
    } = data[data.findIndex(element => element.name === "shipheroTitles")];
    this.draftState.isTriggersDataLoading = false;
    this.draftState.hasActiveShipheroSync = !!syncInProgress;
    this.draftState.lastShipheroSyncStartTime = lastSync;
    this.draftState.hasLastSyncFailed = !!lastSyncFailed;

    let shipheroSyncFailedSKUs;
    if (metadata) {
      shipheroSyncFailedSKUs = JSON.parse(metadata).skusWithoutData;
    }
    this.draftState.shipheroSyncFailedSKUs = shipheroSyncFailedSKUs;
  }
}

export const TriggersActions = createActionCreators(TriggersReducer);
const reducerFunction = createReducerFunction(TriggersReducer, initialState);
export default reducerFunction;
