import * as types from '../types';

const INITIAL_STATE = {
  tableHeaders: [
    {label: 'Purchase Order ID', key: 'purchase_order_id', onTable: true},
    {label: 'Artisan', key: 'artisan', onTable: true},
    {label: 'Order Date', key: 'order_date', onTable: true},
    {label: 'PO Due Date', key: 'DueDate', onTable: true},
    {label: 'Final Landing Date', key: 'final_landing_date', onTable: true},
    {label: 'Status', key: 'status', onTable: true},
  ],
  ordersData: [],
  orderDetailsHeaders: [
    {label: 'SKU', key: 'sku', onTable: true},
    {label: 'QB Product Name', key: 'product_name', onTable: true},
    {label: 'Qty Ordered', key: 'quantity_ordered', onTable: true},
    {label: 'Qty Received', key: 'quantity_received', onTable: true},
    {label: 'Qty Outstanding', key: 'outstanding', onTable: true},
    {label: 'Acq. Cost', key: 'acquisition_cost', onTable: true},
    {label: 'Total Value', key: 'amount', onTable: true},
    {label: 'Warehouse Backorder', key: 'backorder', onTable: true},
    // {label: 'Waitlist', key: 'waitlist', onTable: true},
    // {label: 'Received  Status', key: 'status', onTable: true},
  ],
  orderDetailsData: [],
  orderAllDetailsData: [],
};

const ordersReducer = (state = INITIAL_STATE, action) => {
  let newState = {...state};
  switch (action.type) {
    case types.PROCESS_ORDERS:
      newState.ordersData = action.payload.data;
      return newState;

    case types.PROCESS_ORDER_DETAILS:
      newState.orderDetailsData = action.payload.data;
      return newState;

    case types.PROCESS_ORDER_ALL_DETAILS:
      newState.orderAllDetailsData = action.payload.data;
      return newState;

    default:
      return newState;
  }
};

export default ordersReducer;
