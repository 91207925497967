import * as types from '../types';
import { adjustShipheroBackorderAndAvailable } from './utils';

const INITIAL_STATE = {
  // rawData: [],
  processedData: []
};

// const processRawData = (products) => {
//   return products.map(product => {
//     let processedProduct = {
//       sku: product.sku,
//       available: product.warehouses[0].available,
//       backorder: product.warehouses[0].backorder,
//       last_update: product.last_update
//     };
//     return processedProduct;
//   });
// };

const shipheroReducer = (state = INITIAL_STATE, action) => {
  let newState = {...state};
  switch (action.type) {
    case types.PROCESS_RAW_SHIPHERO_PRODUCTS:
      // newState.rawData = action.payload.products;
      // newState.processedData = processRawData(action.payload.products);
      const products = adjustShipheroBackorderAndAvailable(action.payload.products);
      newState.processedData = products;

      return newState;
    default:
      return newState;
  }
};

export default shipheroReducer;
