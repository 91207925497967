import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import actions from '../../actions';
import InventoryTable from '../../views/Dashboard/InventoryTable.jsx';
import './Main.scss';
import { css, StyleSheet } from 'aphrodite';
import Text, { Font } from 'components/Text';
import { black } from 'shared/styles/colors';

class Main extends Component {
  constructor(props) {
    super(props);

    this.fetchAllData = this.fetchAllData.bind(this);
  }

  fetchAllData() {
    this.props.fetchAllData();
  }

  render() {
    return (
      <main className="Main">
        <div className="Main__Content">
          <Text style={styles.header} font={Font.SourceSansProBold}>
            products
          </Text>
          <div className="Table__Content">
            <InventoryTable />
          </div>
        </div>
      </main>
    );
  }
}

const styles = StyleSheet.create({
  header: {
    fontSize: 32,
    color: black,
    textTransform: 'uppercase',
    marginBottom: 32,
    lineHeight: 1,
  },
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

export default connect(
  state => state,
  mapDispatchToProps
)(Main);
