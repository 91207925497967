import axios from 'axios';
import * as types from '../types';

const processOrdersAction = (data) => {
  return {
    type: types.PROCESS_ORDERS,
    payload: {
      data
    }
  };
};

const processOrderDetailsAction = (data) => {
  return {
    type: types.PROCESS_ORDER_DETAILS,
    payload: {
      data
    }
  };
};

const processOrderAllDetailsAction = (data) => {
  return {
    type: types.PROCESS_ORDER_ALL_DETAILS,
    payload: {
      data
    }
  };
};

export const fetchOrders = () => {
  return dispatch => {
    axios.get('/purchaseOrders')
      .then(response => {
        dispatch(processOrdersAction(response.data));
      })
      .catch(error => {
        console.log('error from server: ', error);
      });
  };
};

export const fetchOrderDetail = (id) => {
  return dispatch => {
    return axios.get('/purchaseOrders/'+ id)
      .then(response => {
        dispatch(processOrderDetailsAction(response.data));
        return response.data;
      })
      .catch(error => {
        console.log('error from server: ', error);
      });
  }
}

export const fetchAllOrderDetail = () => {
  return dispatch => {
    return axios.get('/purchaseOrders/all')
      .then(response => {
        dispatch(processOrderAllDetailsAction(response.data));
        return response.data;
      })
      .catch(error => {
        console.log('error from server: ', error);
      });
  }
}
export const fetchAllOrderDetailForIncomingView = () => {
  return dispatch => {
    return axios.get('/purchaseOrders/incomingViewAll')
      .then(response => {
        dispatch(processOrderAllDetailsAction(response.data));
        return response.data;
      })
      .catch(error => {
        console.log('error from server: ', error);
      });
  }
}
