import { css, StyleSheet } from 'aphrodite';
import Modal from 'react-awesome-modal';
import React, { useEffect, useState } from 'react';
import { black, blueGray, ctaBlue, darkBlue, red, white } from 'shared/styles/colors';
import { useForm, useFieldArray } from "react-hook-form";
import Text, { Font, textStyles } from 'components/Text';
import Button from 'components/Button';
import { ErrorMessage } from '@hookform/error-message';
import { ReactComponent as TrashIcon } from 'assets/svgs/trash-icon.svg';
import { ReactComponent as PlusIcon } from 'assets/svgs/plus-icon-blue.svg';
import Spacer from 'components/Spacer';
import { useDispatch } from 'react-redux';
import { createKit, updateKit } from 'redux/thunks';
import { BaseKit } from 'types/shared';
import { useTypedSelector, findShipHeroDataWithShopifyMerge } from 'shared/utils';
import { KitsActions } from 'reducers/kitsReducer';
import { Kit } from 'types/state';
import { xorBy } from 'lodash';


type FormData = BaseKit;


const CreateKitModal = () => {
  const dispatch = useDispatch();
  const {
    isLoading,
    isCreateKitModalOpen,
    isEditKitModalOpen,
    kitToEdit,
    kitsbySku,
    isVisible,
    shipheroData,
    shopifyMerge,
  } = useTypedSelector(({
    kits: {
      isLoading,
      isCreateKitModalOpen,
      isEditKitModalOpen,
      kitSkuToEdit,
      kitsbySku,
    },
    shiphero: {
      processedData: shipheroData,
    },
    shopifyProductsData: {
      shopifyMerge,
    },
  }) => {
    let kitToEdit: Kit | null = null;
    if (kitSkuToEdit) {
      kitToEdit = kitsbySku[kitSkuToEdit];
    }

    return {
      isVisible: isCreateKitModalOpen || isEditKitModalOpen,
      isLoading,
      isCreateKitModalOpen,
      isEditKitModalOpen,
      kitToEdit,
      kitsbySku,
      shipheroData,
      shopifyMerge,
    }
  });

  const [kitName, setKitName] = useState<string>();
  const [fieldShopifyTitles, setFieldShopifyTitles] = useState<string[]>([]);

  const isEditing = isEditKitModalOpen && kitToEdit !== null;

  const { register, control, handleSubmit, errors,
    setError, formState, clearErrors, reset, getValues } = useForm<FormData>({
      mode: "onChange",
    });
  const { fields, append, remove } = useFieldArray<{sku: string, quantity: number}>({
    control,
    name: "components",
  });

  const resetForm = (defaultValues?: Record<string, any>) => {
    reset(
      defaultValues ?? {},
      {
        errors: false,
        dirtyFields: false,
        isDirty: false,
        touched: false,
        isValid: false,
        submitCount: false,
      }
    );
  }

  // needs to come after useFieldArray
  useEffect(() => {
    let defaultValues;
    if (isEditKitModalOpen && kitToEdit) {
      const { sku, components } = kitToEdit;

      const product = findShipHeroDataWithShopifyMerge(sku, shipheroData, shopifyMerge)
      const name = product ? product.name : 'No corresponding ShipHero name';
      setKitName(name);

      defaultValues = {
        sku,
        components,
      };
    }
    if (isCreateKitModalOpen) {
      setKitName(undefined);
    }
    resetForm(defaultValues);
  }, [isEditKitModalOpen, isCreateKitModalOpen]);

  const onComponentSkuChange = () => {
    let shopifyTitles: string[] = [];
    const { components } = getValues();
    for (const component of components) {
      const componentSku = component.sku?.trim();
      let title = '';
      if (componentSku) {
        const product = findShipHeroDataWithShopifyMerge(componentSku, shipheroData, shopifyMerge)
        title = product ? product.name : 'No corresponding Shopify title';
      }
      shopifyTitles.push(title);
    }
    setFieldShopifyTitles(shopifyTitles);
  }

  useEffect(() => {
    if (fields?.length > 0) {
      onComponentSkuChange();
    }
  }, [fields]);

  const { isSubmitted } = formState;

  useEffect(() => {
    if (isSubmitted) {
      if (!fields.length) {
        setError('components', { message: 'Please add at least one component'});
      } else {
        clearErrors('components');
      }
    }
  }, [isSubmitted, fields])

  const onSubmit = handleSubmit((formKit) => {

    const kit = {
      sku: formKit.sku.trim(),
      components: formKit.components.map(({ sku, quantity }) => ({
        sku: sku.trim(),
        // @ts-ignore
        quantity: quantity.trim(),
      })),
    }

    if (isCreateKitModalOpen) {
      dispatch(createKit(kit, resetForm))
    } else {
      if (kitToEdit) {
        const componentIdsToRemove = xorBy(kit.components, kitToEdit.components, 'sku')
          // @ts-ignore
          .map(component => component?.id)
          // filter out new components, since they do not have ids
          .filter(id => typeof(id) === 'number') as number[];

          dispatch(updateKit(
            {...kit, id: kitToEdit.id as number},
            componentIdsToRemove,
            resetForm,
          ));
      }
    }
  });

  const onSkuChange = (event: React.FocusEvent<HTMLInputElement>) => {
    if (shipheroData && shopifyMerge) {
      const product = findShipHeroDataWithShopifyMerge(event.target.value.trim(), shipheroData, shopifyMerge)
      setKitName(product ? product.name : 'No corresponding ShipHero name');
    }
  }

  const validateKitSku = (kitSku: string) => {
    if (kitSku !== kitToEdit?.sku && kitsbySku[kitSku.trim()]) {
      return 'Kit with sku already exists';
    }
    return true;
  }

  const validateKitComponentSku = (kitSku: string) => {
    if (kitsbySku[kitSku.trim()]) {
      return 'Kit components cannot be kits';
    }
    return true;
  }

  const onCloseModal = () => dispatch(KitsActions.closeCreateOrEditKitModal());

  return (
    <Modal
      visible={isVisible}
      width="712"
      height="632"
      effect="fadeInDown"
      onClickAway={onCloseModal}
    >
      <section className={css(styles.CreateKit)}>
        <Text font={Font.SourceSansProBold} style={styles.header}>
          {isEditing ? 'Edit' : 'Create'} Kit
        </Text>
        <div className={css(styles.headerLine)} />
        <form className={css(styles.form)} onSubmit={onSubmit}>
          <label className={css(styles.label, textStyles.SourceSansProBold)}>
            sku
          </label>
          <ErrorMessage
            errors={errors}
            name="sku"
            render={({ message }) => <div className={css(styles.errorMessage, textStyles.SourceSansProSemibold)}>{message}</div>}
          />
          <input
            className={css(styles.input, textStyles.SourceSansProRegular)}
            name="sku"
            onChange={onSkuChange}
            ref={register({
              required: 'Please enter a sku',
              validate: validateKitSku,
            })}
          />
          <label
            className={css(styles.label, textStyles.SourceSansProBold)}
          >
            name
          </label>
          <Text
            style={[styles.input, textStyles.SourceSansProRegular, styles.nameText]}
          >
            {kitName ?? ''}
          </Text>
          <label
            className={css(styles.label, textStyles.SourceSansProBold)}
          >
            components
          </label>
          <ErrorMessage
            errors={errors}
            name="components"
            render={({ message }) => (
              <div className={css(styles.errorMessage, textStyles.SourceSansProSemibold)}>
                {message ? message : 'Please verify all components have a SKU and Quantity'}
              </div>
            )}
          />
          <div className={css(styles.componentHeaderRow)}>
            <Text style={styles.componentHeader} font={Font.SourceSansProBold}>
              SKU
            </Text>
            <Text style={styles.componentHeader} font={Font.SourceSansProBold}>
              Quantity
            </Text>
            <Text style={styles.componentHeader} font={Font.SourceSansProBold}>
              Name
            </Text>
          </div>
          {fields.map((item, index) => (
            <>
              <div key={item.id} className={css(styles.componentRow)}>
                <input
                  className={css(styles.input, textStyles.SourceSansProRegular, styles.componentInput)}
                  name={`components[${index}].sku`}
                  ref={register({ required: true, validate: validateKitComponentSku })}
                  onChange={onComponentSkuChange}
                  defaultValue={item.sku}
                />
                <input
                  className={css(styles.input, textStyles.SourceSansProRegular, styles.componentInput)}
                  name={`components[${index}].quantity`}
                  ref={register({ required: true })}
                  defaultValue={item.quantity}
                />
                <Text
                  style={[
                    styles.input,
                    textStyles.SourceSansProRegular,
                    styles.componentInput,
                    styles.componentNameText,
                  ]}
                >
                  {fieldShopifyTitles[index]}
                </Text>
                <div
                  className={css(styles.trashIconWrapper)}
                  onClick={() => remove(index)}
                >
                  <TrashIcon />
                </div>
              </div>
              <ErrorMessage
                errors={errors}
                name={`components[${index}].sku`}
                render={({ message }) => (
                  <div className={css(styles.errorMessage, textStyles.SourceSansProSemibold)}>
                    {message}
                  </div>
                )}
              />
            </>
          ))}
          <div
            className={css(styles.addComponentContainer)}
            onClick={() => {
              append({ sku: "", quantity: 1 });
            }}
          >
            <div className={css(styles.plusIconWrapper)}>
              <PlusIcon />
            </div>
            <Text font={Font.SourceSansProSemibold} style={styles.addComponentText}>
              Add Component
            </Text>
          </div>

          <Spacer />
          <div className={css(styles.buttonRow)}>
            <Button
              buttonStyle={[styles.submitButton, styles.discardButton]}
              onClick={onCloseModal}
              disabled={isLoading}
            >
              Discard
            </Button>
            <Button
              buttonStyle={styles.submitButton}
              type="submit"
              disabled={isLoading}
            >
              Save
            </Button>
          </div>
        </form>
      </section>
    </Modal>
  );
};

export default CreateKitModal;

const styles = StyleSheet.create({
  CreateKit: {
    backgroundColor: white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '32px 40px',
    borderRadius: 8,
    height: '100%',
  },
  //
  header: {
    fontSize: 28,
    marginBottom: 12,
    color: darkBlue,
  },
  headerLine: {
    height: 1,
    width: 160,
    backgroundColor: darkBlue,
    marginBottom: 32,
  },
  label: {
    fontSize: 16,
    marginBottom: 12,
    color: black,
    textTransform: 'uppercase',
  },
  input: {
    padding: '4px 0',
    marginBottom: 24,
    border: 0,
    borderBottom: `1px solid #E5E5E5`,
    fontSize: 18,
    color: black,
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  buttonRow: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
  },
  submitButton: {
    width: 116,
    margin: '0 12px'
  },
  discardButton: {
    backgroundColor: red,
  },
  errorMessage: {
    fontSize: 14,
    color: red,
  },
  componentHeader: {
    fontSize: 16,
    width: 146,
    marginRight: 32,
    color: blueGray,
  },
  componentHeaderRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12,
  },
  trashIconWrapper: {
    padding: 8,
    position: 'absolute',
    top: -4,
    left: -36,
    cursor: 'pointer',
  },
  componentRow: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
  },
  componentInput: {
    width: 146,
    marginBottom: 8,
    marginRight: 32,
  },
  addComponentContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 0',
    cursor: 'pointer',
    marginTop: -6,
  },
  plusIconWrapper: {
    marginRight: 8,
  },
  addComponentText: {
    fontSize: 14,
    color: ctaBlue,
  },
  nameText: {
    cursor: 'not-allowed',
    minHeight: 30,
  },
  componentNameText: {
    width: 320,
    marginRight: 0,
  },
});
