import { ImmerReducer } from "immer-reducer";
import { createActionCreators, createReducerFunction } from "immer-reducer";
import { ProductLogEntry, ProductLogState } from "types/state";


const initialState: ProductLogState = {
  productLogEntries: [],
  isFetchingProductLog: true,
};

class ProductLogReducer extends ImmerReducer<ProductLogState> {
  setProductLogEntries(productLogEntries: ProductLogEntry[]) {
    this.draftState.productLogEntries = productLogEntries;
  }

  setIsFetchingProductLog(isFetchingProductLog: boolean) {
    this.draftState.isFetchingProductLog = isFetchingProductLog;
  }
}

export const ProductLogActions = createActionCreators(ProductLogReducer);
const reducerFunction = createReducerFunction(ProductLogReducer, initialState);
export default reducerFunction;
