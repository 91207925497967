import React, { useEffect, useMemo, useState } from 'react';
import { useTypedSelector } from 'shared/utils';
import { newTableGetExportFileBlob } from 'components/NewReactTable/tableUtils';
import Table, { createLoadingRows } from 'components/NewReactTable/Table';
import localForage from "localforage";
import { columnsData } from './ProductInventory.columns';
import { filterInventoryDiscrepancies } from './filters/ProductInventory.filters';

const loadingRows = createLoadingRows(columnsData);

const LOCAL_FORAGE_COLUMN_ACCESSOR = 'productInventoryHiddenColumns';
interface Props {
  exportDataRef: any;
}

const DashboardTable = ({
  exportDataRef,
}: Props) => {
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const [hasFetchedHiddenColumns, setHasFetchedHiddenColumns] = useState<boolean>(false);
  const [isInventoryFilterActive, setIsInventoryFilterActive] = useState<boolean>(false);

  useEffect(() => {
    (async function () {
      const columns = await localForage.getItem<string[]>(LOCAL_FORAGE_COLUMN_ACCESSOR);
      setHiddenColumns(columns ?? []);
      setHasFetchedHiddenColumns(true);
    })();
  }, []);

  const { isFetchingProductInventory, productInventory } = useTypedSelector((
    { productInventory: { isFetchingProductInventory, productInventory } }
  ) => ({ isFetchingProductInventory, productInventory }));

  const columns = useMemo(
    () => columnsData,
    []
  );

  const tableData = useMemo(
    () => {
      if (isFetchingProductInventory) {
        return loadingRows as any;
      } else {
        return isInventoryFilterActive ? filterInventoryDiscrepancies(productInventory) : productInventory;
      }
    },
    [isFetchingProductInventory, productInventory, isInventoryFilterActive]
  );

  const onHiddenColumnsUpdate = (hiddenColumns: string[]) => {
    setHiddenColumns(hiddenColumns);
    localForage.setItem(LOCAL_FORAGE_COLUMN_ACCESSOR, hiddenColumns);
  }

  if (!hasFetchedHiddenColumns) {
    return null;
  }

  return (
    <Table
      tableData={tableData}
      csvExportPrefix="Product Inventory"
      exportDataRef={exportDataRef}
      isFetchingData={isFetchingProductInventory}
      columns={columns}
      noResultsAdditionalText="Please note that spelling must be exact"
      hiddenColumns={hiddenColumns as string[]}
      onHiddenColumnsUpdate={onHiddenColumnsUpdate}
      getExportFileBlob={args => newTableGetExportFileBlob({...args, isProductInventoryView: true})}
      setIsInventoryFilterActive={setIsInventoryFilterActive}
      isInventoryFilterActive={isInventoryFilterActive}
      showVisibilitySettings
    />
  );
};

export default DashboardTable;
