import { css, StyleSheet } from "aphrodite";
import React, { useEffect, useRef } from "react";
import Text, { Font } from "components/Text";
import { black } from "shared/styles/colors";
import { useDispatch } from "react-redux";
import { fetchAtRiskData } from "redux/thunks";
import { motion, AnimatePresence } from "framer-motion";
import { ReactComponent as RefreshIcon } from "assets/svgs/refresh.svg";
import Button from "components/Button";
import LightTooltip from "components/LightTooltip";
import { useTypedSelector } from "shared/utils";
import { ReactComponent as DownloadIcon } from "assets/svgs/download.svg";
import { mainShadow } from "shared/styles/shadows";
import AtRiskTable from "./AtRisk.table";
import { Helmet } from "react-helmet";
import { HTML_TITLE_SUFFIX } from "shared/constants";

const AtRiskLayout = () => {
  const dispatch = useDispatch();
  const exportDataRef = useRef<() => void>(null);

  const onExportData = () => {
    if (exportDataRef.current) {
      exportDataRef.current();
    }
  };

  const { isFetchingAtRiskData } = useTypedSelector(
    ({ atRisk: { isFetchingAtRiskData } }) => ({
      isFetchingAtRiskData,
    })
  );

  useEffect(() => {
    dispatch(fetchAtRiskData());
  }, []);

  return (
    <section className={css(styles.DashboardLayout)}>
      <Helmet>
        <title>At Risk {HTML_TITLE_SUFFIX}</title>
      </Helmet>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, y: -18 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 0, duration: 0.6 }}
          className={css(styles.headerRow)}
        >
          <div className={css(styles.headerRowLeft)}>
            <Text style={styles.header} font={Font.SourceSansProBold}>
              At Risk
            </Text>
            <div
              className={css(styles.refreshIconWrapper)}
              onClick={() => dispatch(fetchAtRiskData())}
            >
              <RefreshIcon />
            </div>
          </div>
          <div className={css(styles.headerRowRight)}>
            <LightTooltip title="Includes active filters and sorts">
              <Button
                icon={<DownloadIcon />}
                disabled={isFetchingAtRiskData}
                onClick={onExportData}
                buttonStyle={styles.exportButton}
              >
                Export Data
              </Button>
            </LightTooltip>
          </div>
        </motion.div>
        <AtRiskTable exportDataRef={exportDataRef} />
      </AnimatePresence>
    </section>
  );
};

export default AtRiskLayout;

const styles = StyleSheet.create({
  DashboardLayout: {
    padding: "24px 24px",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  header: {
    fontSize: 36,
    color: black,
    marginRight: 16,
    lineHeight: 1,
  },
  headerRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 24,
  },
  refreshIconWrapper: {
    padding: "4px 2px 4px",
    marginTop: 6,
    cursor: "pointer",
  },
  headerRowLeft: {
    display: "flex",
    alignItems: "center",
  },
  headerRowRight: {
    display: "flex",
    alignItems: "center",
  },
  exportButton: {
    boxShadow: mainShadow,
  },
});
