import * as types from '../types';

const INITIAL_STATE = {
  toggleMenu: false,
  toggleProfile: false
};

const menuReducer = (state = INITIAL_STATE, action) => {
  let newState = {...state};
  switch(action.type) {
    case types.MENU_TOGGLE:
      newState.toggleMenu = action.payload.flag;
      return newState;
    case types.MENU_TOGGLE_PROFILE: // If we error, change the flag, and save the code and message
      newState.toggleProfile = action.payload.flag;
      return newState;
    default:
      return newState;
  }
};

export default menuReducer;