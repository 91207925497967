import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";

import moment from 'moment';
import _ from 'lodash';

import actions from '../../actions';

import Button from '@material-ui/core/Button';

import PurchaseOrderDetailTable from 'components/ReactTable/PurchaseOrderDetailTable';
import ShipmentTable from 'components/ReactTable/ShipmentTable';

import { receiveStatus, productOrderStatus } from '../../helpers/product';
import { HTML_TITLE_SUFFIX } from 'shared/constants';
import { compareCaseInsensitive, normalizeByKey } from 'shared/utils';

class OrderDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orderId: null,
      orderDetailLoaded: false,
      shipmentLoaded: false,
      orderWaitlistLoaded: false,
      billsItems: [],
      billsItemsLoaded: false
    };

    this.isLoaded = this.isLoaded.bind(this);
    this.forwardToShipment = this.forwardToShipment.bind(this);
    this.mapShipmentData = this.mapShipmentData.bind(this);
    this.mapValidHeaders = this.mapValidHeaders.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleView = this.handleView.bind(this);
    this.mapShipHeroDataToShopifyData = this.mapShipHeroDataToShopifyData.bind(
      this
    );
  }

  componentDidMount() {
    let { match } = this.props;

    if (match.params.id) {
      this.props.actions
        .fetchOrderDetail(match.params.id)
        .then(response => this.setState({ orderDetailLoaded: true }));

      this.props.actions.fetchShipment(match.params.id).then(response =>
        this.setState({
          shipments: response,
          shipmentLoaded: true
        })
      );

      this.props.actions
        .fetchBillItemsByOrderId(match.params.id)
        .then(response =>
          this.setState({
            billsItems: response,
            billsItemsLoaded: true
          })
        );

      this.props.actions
        .fetchInboundShipmentsForOrderId(match.params.id)
        .then(response => {
          this.setState({
            inboundShipments: normalizeByKey(response, 'shipmentId'),
          })
      });

      this.setState({ orderId: match.params.id });
    }

    this.props.actions.clearSelectedSku();
  }

  mapValidHeaders(headers) {
    if (headers && headers.length > 0) {
      return headers.filter(item => item.onTable);
    }
    return headers;
  }

  mapShipHeroDataToShopifyData(shopifyProducts, shipheroProducts) {
    const { shopifyProductsData } = this.props;
    const { shopifyMerge } = shopifyProductsData;
    for (let i = 0; i < shopifyProducts.length; i++) {
      let currentShopifyProduct = shopifyProducts[i];
      let skuCurrent = currentShopifyProduct.sku.toUpperCase();

      let mergedData = _.find(shopifyMerge, (record) =>
        compareCaseInsensitive(record.SKU, skuCurrent)
      );
      if (typeof mergedData != 'undefined') {
        skuCurrent = mergedData.newSKU;
      }

      let shipHeroProduct = _.find(shipheroProducts, product =>
        compareCaseInsensitive(product.sku, skuCurrent)
      );
      if (typeof shipHeroProduct != 'undefined') {
        currentShopifyProduct.backorder = shipHeroProduct.backorder;
      } else {
        currentShopifyProduct.backorder = 'N/A';
      }
    }
    return shopifyProducts;
  }

  mapShipmentData(combinedData) {
    const { billsItems, inboundShipments } = this.state;
    const { shipment, order } = this.props;
    const { shipmentAllItems } = this.props.shipment;

    const resultData = [...shipment.shipmentsData];
    resultData.map(record => {
      // let shipDate = response[0].shipDate;
      //     if (response[0].shipDate !== null) {
      //       let split = response[0].shipDate.split('-');
      //       shipDate = new Date(
      //         Date.UTC(split[0], split[1] - 1, split[2], 12, 0, 0)
      //       );
      //     }
      record.receiveStatus = receiveStatus(
        order.orderDetailsData[0],
        record,
        shipmentAllItems,
        combinedData,
        shipment.shipmentBills,
        billsItems,
        inboundShipments[record.shipmentId],
      );

      let notWaitlist = false;
      shipmentAllItems
        .filter(r => r.shipmentId === record.id)
        .map(r => {
          if (r.waitlist === 0) {
            notWaitlist = true;
          }
        });

      record.actions = !notWaitlist;

      return record;
    });

    return resultData;
  }

  handleEdit(id) {
    const { orderId } = this.state;
    const { history } = this.props;

    history.push(orderId + '/shipment/' + id);
  }

  handleView(id) {
    const { orderId } = this.state;
    const { history } = this.props;

    history.push(orderId + '/shipment/' + id + '/view');
  }

  handleDelete(id) {
    const { orderId } = this.state;

    this.props.actions
      .removeShipment(id)
      .then(resp => this.props.actions.fetchShipment(orderId));
  }

  handleCheckbox(sku) {
    this.props.actions.updateSelectedSku(sku);
  }

  forwardToShipment() {
    const { orderId } = this.state;
    const { history, shipment } = this.props;

    if (shipment.selectedSKU.length === 0) {
      alert('Please select at least one item');
    } else {
      history.push(orderId + '/shipment');
    }
  }

  isLoaded() {
    const { orderDetailLoaded, shipmentLoaded, billsItemsLoaded } = this.state;
    return orderDetailLoaded && shipmentLoaded && billsItemsLoaded;
  }

  render() {
    const { orderId } = this.state;
    const { order } = this.props;
    const { shiphero, shipment } = this.props;

    if (!this.isLoaded()) {
      return (
        <div className={'main-block'}>
          loading...
        </div>
      );
    }
    // remove any order details without a Shopify SKU
    const filteredOrderDetails = order.orderDetailsData.filter(orderDetails => orderDetails.sku !== null);
    let combinedData = this.mapShipHeroDataToShopifyData(
      [...filteredOrderDetails],
      [...shiphero.processedData]
    );
    let headers = this.mapValidHeaders(order.orderDetailsHeaders);

    const shipmentData = this.mapShipmentData(combinedData).sort((a, b) => {
      let aValue = a.shipmentId.split('-');
      let bValue = b.shipmentId.split('-');

      return parseInt(aValue[1]) - parseInt(bValue[1]);
    });

    const shipmentHeaders = this.mapValidHeaders(shipment.shipmentHeaders);
    const status = productOrderStatus(combinedData[0]);

    return (
      <div className={'main-block'}>
        <Helmet>
          <title>Purchase Order #{orderId} {HTML_TITLE_SUFFIX}</title>
        </Helmet>
        <main className="Main">
          <div className="Main__Content">
            <div className="Title__Content">
              <div className="Title flex-item">
                <span>
                  PURCHASE ORDER #{orderId}{' '}
                  {status !== '' && (
                    <span className="status-block">{status}</span>
                  )}
                </span>
              </div>
            </div>
            <div className="Table__Content">
              <div className="Info__Content">
                <div className="Description flex-block">
                  <span>{combinedData[0]?.artisan ?? ''}</span>
                  <span className="date-order">
                    Order date:{' '}
                    {moment(combinedData[0]?.order_date).format('MM/DD/YYYY')}
                  </span>
                </div>
              </div>
              <div className="Table">
                <div className="inventory-table-wrapper">
                  <PurchaseOrderDetailTable
                    headers={headers}
                    data={combinedData}
                    handleCheckbox={this.handleCheckbox}
                    handleOrderWaitlistClick={this.handleWaitlistChange}
                  />
                </div>
              </div>
              <div className="Info__Content">
                <div className="Description">Shipments for this PO</div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.forwardToShipment}
                  className={'Button right-align Button--blue mr15'}
                >
                  <span className="Label">Add shipment</span>
                </Button>
              </div>
              <div className="Table">
                <div className="inventory-table-wrapper">
                  <ShipmentTable
                    headers={shipmentHeaders}
                    data={shipmentData}
                    handleDelete={this.handleDelete}
                    handleEdit={this.handleEdit}
                    handleView={this.handleView}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

export default connect(
  state => state,
  mapDispatchToProps
)(OrderDetails);
