import { CalendarColumnFilter, MinMaxColumnFilter, SelectColumnFilter } from "components/NewReactTable/Filters";
import { filterCalendarGreaterThan, filterCalendarLessThan, filterMinMax, filterWebShipDate } from "components/NewReactTable/tableUtils";
import { sortByDate } from "shared/sortMethods";
import React from 'react';

export const columnsData = [
  {
    Header: 'SKU',
    accessor: 'sku',
    width: 164,
  },
  {
    Header: 'Shiphero Title',
    accessor: 'shipheroTitle',
    width: 365,
  },
  {
    Header: 'Web Ship Date',
    accessor: 'webShipDate',
    Filter: (props) => (
      <SelectColumnFilter
        selectOptions={[
          'All',
          'Waitlist',
          'Pre-sell',
        ]}
        defaultOption="All"
        {...props} />
    ),
    filter: filterWebShipDate,
    width: 180,
  },
  {
    Header: 'Date Started',
    accessor: 'startDate',
    Filter: CalendarColumnFilter,
    filter: filterCalendarGreaterThan,
    sortType: sortByDate,
    width: 180,
  },
  {
    Header: 'Date Ended',
    accessor: 'endDate',
    Filter: (props) => <CalendarColumnFilter hasNullOption {...props} />,
    filter: filterCalendarLessThan,
    sortType: sortByDate,
    width: 180,
  },
  {
    Header: 'Duration',
    accessor: 'duration',
    width: 150,
    filter: filterMinMax,
    Filter: MinMaxColumnFilter,
  },
];
