import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import ReactTags from 'react-tag-autocomplete'

import actions from '../../actions';

import './Settings.scss';

class SettingsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      tags: [],
      suggestions: []
    }

    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
  }

  componentDidMount() {
    this.props.actions.fetchSettingsData()
      .then(res => {
        this.setState({
          loaded: true,
          suggestions: res.data.suggestions,
          tags: res.data.tags
        });
      });
  }

  handleDelete(i) {
    const tags = this.state.tags.slice(0)
    tags.splice(i, 1)
    this.setState({tags})
    this.props.actions.setSettingsData({excludeSKU: tags})
  }

  handleAddition(tag) {
    const tags = [].concat(this.state.tags, tag)
    this.setState({tags})
    this.props.actions.setSettingsData({excludeSKU: tags})
  }

  render() {
    const {loaded, tags, suggestions} = this.state

    if (!loaded) {
      return (
        <React.Fragment>
          loading...
        </React.Fragment>
      );
    }

    return (
      <ReactTags
        tags={tags}
        suggestions={suggestions}
        handleDelete={this.handleDelete}
        handleAddition={this.handleAddition}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
});

export default connect(
  state => state,
  mapDispatchToProps
)(SettingsForm);
