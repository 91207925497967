import React from 'react';
import { css, StyleSheet } from 'aphrodite';

import "./SideNav.scss";
import NavItem from './NavItem.SideNav';
import { white } from 'shared/styles/colors';
import { ReactComponent as TagIcon } from "assets/svgs/tag-icon.svg";
import { ReactComponent as TruckIcon } from "assets/svgs/truck-icon.svg";
import { ReactComponent as PackageIcon } from "assets/svgs/package-icon.svg";
import { ReactComponent as POIcon } from "assets/svgs/p-o.svg";
import { ReactComponent as HistoryIcon } from "assets/svgs/history-icon.svg";
import { ReactComponent as LogoIcon } from "assets/svgs/logo-icon.svg";
import { ReactComponent as AtRiskIcon } from "assets/svgs/at-risk-icon.svg";
import { ReactComponent as FileMinusIcon } from "assets/svgs/file-minus.svg";

const SideNav = () => {
  return (
    <div className={css(styles.menu)}>
      <div className={css(styles.menuSection, styles.anchorSection)}>
        <a href="/dashboard">
          <LogoIcon />
        </a>
      </div>
      <div className={css(styles.menuSection)}>
        <NavItem
          image={<TagIcon />}
          href="/dashboard"
          text="Products"
        />
        <NavItem
          image={<POIcon />}
          href="/orders"
          text="Purchase Orders"
          activeFill
        />
        <NavItem
          image={<TruckIcon />}
          href="/incoming"
          text="Incoming"
          activeFill
        />
        <NavItem
          image={<PackageIcon />}
          href="/kits"
          text="Kits"
        />
        <NavItem
          image={<HistoryIcon />}
          href="/log"
          text="log"
          activeFill
        />
        <NavItem
          image={<FileMinusIcon />}
          href="/inventory-removal"
          text="inventory removal"
        />
        {/* <NavItem
          image={<AtRiskIcon />}
          href="/atrisk"
          text="At Risk"
        /> */}
      </div>
      <div className={css(styles.menuSection, styles.anchorSection)}>
      </div>
    </div>
  );
}

export default SideNav;

const styles = StyleSheet.create({
  menu: {
    width: 80,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    backgroundColor: white,
    flex: '0 0 auto',
    height: '100vh',
  },
  menuSection: {
    width: 80,
  },
  anchorSection: {
    height: 98,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
});
