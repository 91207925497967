import { AtRiskRow } from "types/tablesTypes";

export const presellFilter = (atRiskData: AtRiskRow[]) => {
  const filteredData = atRiskData.filter((atRiskRow) => {
    return (
      atRiskRow.WOH45 !== null &&
      atRiskRow.WOH45 !== undefined &&
      atRiskRow.WOH45 < 2 &&
      atRiskRow.shipmentId &&
      !atRiskRow.webShipDateWaitlisted &&
      atRiskRow.ecsd &&
      new Date(atRiskRow.ecsd).getTime() - Date.now() < 864000000 * 14 &&
      atRiskRow.availableForPresellQuantity &&
      atRiskRow.availableForPresellQuantity > 0
    );
  });
  const sortedData = filteredData.sort(
    (rowA, rowB) => rowA.WOH45! - rowB.WOH45!
  );
  return sortedData;
};

export const missingPOsFilter = (atRiskData) => {
  const filteredData = atRiskData.filter((atRiskRow) => {
    return (
      atRiskRow.WOH45 !== null &&
      atRiskRow.WOH45 !== undefined &&
      atRiskRow.WOH45 < 6 &&
      !atRiskRow.purchaseOrderId
    );
  });
  const sortedData = filteredData.sort(
    (rowA, rowB) => rowA.WOH45! - rowB.WOH45!
  );
  return sortedData;
};

export const missingShipmentsFilter = (atRiskData: AtRiskRow[]) => {
  const filteredData = atRiskData.filter((atRiskRow) => {
    return (
      atRiskRow.WOH45 !== null &&
      atRiskRow.WOH45 !== undefined &&
      atRiskRow.WOH45 < 6 &&
      atRiskRow.purchaseOrderId &&
      atRiskRow.purchaseOrderOutstanding &&
      atRiskRow.purchaseOrderOutstanding > 0 &&
      !atRiskRow.shipmentId
    );
  });
  const sortedData = filteredData.sort(
    (rowA, rowB) => rowA.WOH45! - rowB.WOH45!
  );
  return sortedData;
};

export const missingShipmentDetailsFilter = (atRiskData: AtRiskRow[]) => {
  const filteredData = atRiskData.filter((atRiskRow) => {
    return (
      atRiskRow.WOH45 !== null &&
      atRiskRow.WOH45 !== undefined &&
      atRiskRow.WOH45 < 6 &&
      atRiskRow.shipmentId &&
      !atRiskRow.webShipDateWaitlisted &&
      atRiskRow.ecsd !== "WAITLIST" &&
      (!atRiskRow.ecsd || !atRiskRow.availableForPresellQuantity)
    );
  });
  const sortedData = filteredData.sort(
    (rowA, rowB) => rowA.WOH45! - rowB.WOH45!
  );
  return sortedData;
};
