import { css, StyleSheet } from 'aphrodite';
import Text from 'components/Text';
import { DatePicker } from 'carbon-components-react';
import { DatePickerInput } from 'carbon-components-react';
import React from 'react';
import { black, tableLineGray } from 'shared/styles/colors';
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import { RectShape } from 'react-placeholder/lib/placeholders';
import { LOADING_IDENTIFIER } from 'shared/constants';
import { Cell as ReactTableCell, Row } from 'react-table';
import '../styles/carbon-calendar-styles.scss';
import './carbon-calendar-cell-styles.scss';
import { ReactComponent as XCircleIcon } from 'assets/svgs/x-circle.svg';
import { useDispatch } from 'react-redux';
import { editWebShipDateCell } from 'redux/thunks';

interface Props {
  cell: ReactTableCell;
  row: Row;
}

export const WebShipDateCell = ({
  cell: { value },
  row: { index, original },
}: Props) => {
  const dispatch = useDispatch();

  const onEditWebShipDateClick = (webShipDate) => dispatch(editWebShipDateCell({
    rowIndex: index,
    // @ts-ignore
    sku: original.sku,
    webShipDate,
  }))

  let content;
  if (value === LOADING_IDENTIFIER) {
    content = (
      <div className={css(styles.loadingContainer)}>
        {/* @ts-ignore */}
        <ReactPlaceholder
          showLoadingAnimation
          customPlaceholder={<RectShape color={tableLineGray} style={{ width: '80%', height: 24 }} />}
        />
      </div>
    );
  } else if (value === 'Waitlist') {
    content = (
      <Text style={styles.text}>
        {value}
      </Text>
    );
  } else {
    content = (
      <div className={css(styles.contentContainer)}>
        {!!value && (
          <div
            className={css(styles.xIconWrapper)}
            onClick={() => onEditWebShipDateClick(null)}
          >
            <XCircleIcon />
          </div>
        )}
        <div className="CalendarColumnFilter">
          <DatePicker
            datePickerType="single"
            onChange={datetimeArray => onEditWebShipDateClick(datetimeArray[0])}
            value={value}
          >
            <DatePickerInput
              placeholder=""
              labelText=""
              id="date-picker-single"
              autoComplete="off"
            />
          </DatePicker>
        </div>
      </div>
    );
  }
  return (
    <div className={css(styles.Cell)}>
      {content}
    </div>
  );
};

const styles = StyleSheet.create({
  Cell: {
    minHeight: 54,
    paddingLeft: 12,
    paddingRight: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  //
  loadingContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  text: {
    fontSize: 16,
    color: black,
  },
  sortIconWrapper: {
    padding: 2,
    marginTop: 4,
    cursor: 'pointer',
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  xIconWrapper: {
    cursor: 'pointer',
    paddingTop: 8,
    marginRight: 8,
  },
});
