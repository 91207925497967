import { AppThunk } from 'redux/store';
import BackendService from 'services/Backend/Backend.service';
import { toast } from 'react-toastify';
import { TriggersActions } from 'reducers/triggers';

export const fetchTriggersData = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(TriggersActions.setIsTriggersDataLoading(true));
    const response = await BackendService.fetchTriggersData();
    dispatch(TriggersActions.setTriggersData(response));
  } catch (error) {
    console.log('error', error);
    dispatch(TriggersActions.setIsTriggersDataLoading(false));
  }
};
