import { AppThunk } from 'redux/store';
import { KitsActions } from 'reducers/kitsReducer';
import BackendService from 'services/Backend/Backend.service';
import { toast } from 'react-toastify';

export const fetchKits = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(KitsActions.setIsFetchingKits(true));

    const response = await BackendService.fetchKits();

    dispatch(KitsActions.setKits(response));
    dispatch(KitsActions.setIsFetchingKits(false));
  } catch (error) {
    toast.error('Could not fetch Kits. Please try again.');
    dispatch(KitsActions.setIsFetchingKits(false));
  }
};
