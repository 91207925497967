import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {CSVLink} from "react-csv";
import moment from 'moment';

import ArchiveIcon from '@material-ui/icons/Archive';

import actions from '../../actions';

import PurchaseOrders from '../../views/Orders/PurchaseOrders';
import { productOrderStatus } from '../../helpers/product';

class Orders extends Component {
  constructor(props) {
    super(props);

    this.prepareCSV = this.prepareCSV.bind(this);
  }

  prepareCSV(data) {
    let csvData = [[
      'Purchase Order ID',
      'Artisan',
      'Order Date',
      'PO Due Date',
      'Final Landing Date',
      'Status'
    ]];

    data.map(r => {
      csvData.push([
        r.purchase_order_id,
        r.artisan,
        moment(r.order_date).format('MM/DD/YYYY'),
        moment(r.DueDate).format('MM/DD/YYYY'),
        moment(r.final_landing_date).format('MM/DD/YYYY'),
        productOrderStatus(r)
      ])

      return r;
    });

    return csvData;
  }

  render() {
    const {order} = this.props;

    return (
      <main className='Main'>
        <div className='Main__Content'>
          <div className="Title__Content">
            <div className="Title">PURCHASE ORDERS</div>
          </div>
          <div className="Table__Content">
            <div className="Info__Content">
              <div className='Description'>
                Purchase Order Data
              </div>
              <div className="Buttons_Content">
                {(order.ordersData).length > 0 && (
                  <CSVLink data={this.prepareCSV(order.ordersData)} filename={"data.csv"} className={"Button export-link"}
                           target="_blank">
                    <ArchiveIcon className="Icon"/>
                    <span className="Label">Export CSV</span>
                  </CSVLink>
                )}
              </div>
            </div>
            <div className="Table">
              <PurchaseOrders/>
            </div>
          </div>
        </div>
      </main>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
});

export default connect(
  state => state,
  mapDispatchToProps
)(Orders);
