import { AppThunk } from 'redux/store';
import BackendService from 'services/Backend/Backend.service';
import { toast } from 'react-toastify';
import { TriggersActions } from 'reducers/triggers';

export const triggerShipheroSync = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(TriggersActions.setIsShipheroSyncCallLoading(true));
    await BackendService.triggerShipheroSync();
  } catch (error) {
    toast.error('Could not start shiphero sync. Please try again');
    console.log('error', error);
  }
  dispatch(TriggersActions.setIsShipheroSyncCallLoading(false));
};
