import React from 'react';

interface Props {
  checked: boolean;
  size: number;
}

const Checkbox = ({ checked, size }: Props) => {
  if (checked) {
    return (
      <svg width={size + 1} height={size} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.10522 6.33317L7.15783 8.33317L13.9999 1.6665" stroke="#4591CD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.3156 7V11.6667C13.3156 12.0203 13.1715 12.3594 12.9148 12.6095C12.6582 12.8595 12.3102 13 11.9472 13H2.3684C2.00548 13 1.65742 12.8595 1.4008 12.6095C1.14417 12.3594 1 12.0203 1 11.6667V2.33333C1 1.97971 1.14417 1.64057 1.4008 1.39052C1.65742 1.14048 2.00548 1 2.3684 1H9.89463" stroke="#4591CD" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round" />
      </svg>
    )
  }

  return (
    <svg width={size + 1} height={size} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.3156 7V11.6667C13.3156 12.0203 13.1715 12.3594 12.9148 12.6095C12.6582 12.8595 12.3102 13 11.9472 13H2.3684C2.00548 13 1.65742 12.8595 1.4008 12.6095C1.14417 12.3594 1 12.0203 1 11.6667V2.33333C1 1.97971 1.14417 1.64057 1.4008 1.39052C1.65742 1.14048 2.00548 1 2.3684 1H9.89463" stroke="#97A2AB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
export default Checkbox;
