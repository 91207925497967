import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import moment from 'moment';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import { format, parse } from 'date-fns';

import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import actions from '../../actions';

import 'react-datepicker/dist/react-datepicker.css';
import { compareCaseInsensitive } from 'shared/utils';

const INITIAL_DATA = {
  dashStatus: 'On-hold',
  shipingVendor: 'None',
  shipingMeans: 'Air Express',
  tracking: '',
  shipDate: null,
  landingDate: null,
  customerShipDate: null
};

class ShipmentView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      orderId: null,
      shipmentId: null,
      loaded: false,
      shipmentLoaded: false,
      shipmentItemsLoaded: false,
      available: null,
      expected: null,
      items: [],
      ordersSelected: [],
      formData: INITIAL_DATA
    };

    this.handleOrderChange = this.handleOrderChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    let { match, shipment } = this.props;

    if (match.params.id) {
      this.setState({ orderId: match.params.id });
      this.props.actions.fetchOrderDetail(match.params.id).then(response => {
        let data = [...response];

        data = data.filter(r => {
          if (match.params.shipmentId) return true;
          if (shipment.selectedSKU.length === 0) return true;

          // potentially could cause capitilization issue
          if (
            shipment.selectedSKU.indexOf(r.sku) !==
            -1
          ) {
            return true;
          } else {
            return false;
          }
        });

        for (let i = 0; i < data.length; i++) {
          let currentRecord = data[i];

          if (this.state.shipmentId === null) {
            currentRecord.available = 0;
            currentRecord.expected = 0;
          } else {
            if (this.state.items.length > 0) {
              let search = _.find(this.state.items, (item) =>
                compareCaseInsensitive(item.sku, currentRecord.sku)
              );

              if (typeof search !== 'undefined') {
                currentRecord.itemId = search.id;
                currentRecord.available = search.available;
                currentRecord.expected = search.expected;
                currentRecord.waitlist = search.waitlist;
              }
            }
          }
        }

        this.setState({
          loaded: true,
          ordersSelected: data
        });
      });
    }

    if (match.params.shipmentId) {
      this.setState({ shipmentId: match.params.shipmentId });
      this.props.actions
        .fetchShipmentDetails(match.params.shipmentId)
        .then(response => {
          let customerShipDate = response[0].customerShipDate;
          if (response[0].customerShipDate !== null) {
            let split = response[0].customerShipDate.split('-');
            customerShipDate = new Date(
              Date.UTC(split[0], split[1] - 1, split[2], 12, 0, 0)
            );
          }

          let landingDate = response[0].landingDate;
          if (response[0].landingDate !== null) {
            let split = response[0].landingDate.split('-');
            landingDate = new Date(
              Date.UTC(split[0], split[1] - 1, split[2], 12, 0, 0)
            );
          }

          let shipDate = response[0].shipDate;
          if (response[0].shipDate !== null) {
            let split = response[0].shipDate.split('-');
            shipDate = new Date(
              Date.UTC(split[0], split[1] - 1, split[2], 12, 0, 0)
            );
          }

          this.setState({
            shipmentLoaded: true,
            formData: {
              ...this.state.formData,
              dashStatus: response[0].dashStatus,
              shipingVendor: response[0].shipingVendor,
              shipingMeans: response[0].shipingMeans,
              tracking: response[0].tracking,
              shipDate,
              landingDate,
              customerShipDate
            }
          });
        });

      this.props.actions
        .fetchShipmentItems(match.params.shipmentId)
        .then(response => {
          this.setState({
            shipmentItemsLoaded: true,
            items: response
          });

          if (this.state.ordersSelected.length > 0) {
            let data = [...this.state.ordersSelected];

            for (let i = 0; i < data.length; i++) {
              let currentRecord = data[i];

              let search = _.find(response, record =>
                compareCaseInsensitive(record.sku, currentRecord.sku)
              );

              if (typeof search !== 'undefined') {
                currentRecord.itemId = search.id;
                currentRecord.available = search.available;
                currentRecord.expected = search.expected;
                currentRecord.waitlist = search.waitlist;
              }
            }

            this.setState({ ordersSelected: data });
          }
        });
    } else {
      this.setState({
        shipmentLoaded: true,
        shipmentItemsLoaded: true
      });
    }
  }

  handleOrderChange(key, value, index) {
    let data = [...this.state.ordersSelected];
    let temp = Object.assign({}, data[index]);

    temp[key] = value;
    data[index] = temp;

    this.setState({
      ordersSelected: data
    });
  }

  handleChange(key, value) {
    this.setState({
      formData: {
        ...this.state.formData,
        [key]: value
      }
    });
  }

  handleRemove(i) {
    let { ordersSelected } = this.state;
    ordersSelected.splice(i, 1);

    this.setState({ ordersSelected: ordersSelected });
  }

  handleCancel() {
    const { history } = this.props;
    const { orderId } = this.state;

    this.setState({ formData: INITIAL_DATA });

    history.push('/orders/' + orderId);
  }

  handleSubmit() {
    const { formData, orderId, shipmentId, ordersSelected } = this.state;
    const { history } = this.props;

    let error = false;

    if (error) {
      alert('Please fill add data');
      return;
    }

    let ordersData = [...ordersSelected];
    let filledData = { ...formData };
    filledData.orderId = parseInt(orderId);

    const data = {
      orders: ordersData,
      data: filledData
    };

    if (shipmentId !== null) {
      this.props.actions.updateShipment(data, shipmentId).then(res => {
        this.props.actions.fetchShipment(orderId);
        history.push('/orders/' + orderId);
      });
    } else {
      this.props.actions.storeShipment(data).then(res => {
        this.props.actions.fetchShipment(orderId);
        history.push('/orders/' + orderId);
      });
    }
  }

  render() {
    const {
      loaded,
      shipmentLoaded,
      shipmentItemsLoaded,
      orderId,
      ordersSelected,
      formData,
      shipmentId,
      items
    } = this.state;
    const { order } = this.props;
    const { shopifyProductsData, shiphero, shipment } = this.props;

    if (!loaded || !shipmentLoaded || !shipmentItemsLoaded) {
      return (
        <div className={'main-block'}>
          loading...
        </div>
      );
    }

    const { shipmentAllItems, shipmentAll, shipmentBills } = shipment;

    return (
      <div className={'main-block'}>
        <main className="Main">
          <div className="Main__Content">
            <div className="Title__Content">
              <div className="Title flex-item fixed-title">
                {shipmentId === null
                  ? 'Add shipment - Purchase Order #' +
                    orderId +
                    ' - ' +
                    order.orderDetailsData[0].artisan
                  : 'Edit shipment'}
              </div>
            </div>
            <div className="order-items">
              {ordersSelected
                .filter(r => {
                  if (shipmentId === null) return true;
                  if (items.length === 0) return true;

                  let search = _.find(items, item =>
                    compareCaseInsensitive(item.sku, r.sku),
                  );

                  if (typeof search === 'undefined') {
                    return false;
                  } else {
                    return true;
                  }
                })
                .map((r, i) => {
                  let shipHeroProduct = _.find(shiphero.processedData, record =>
                    compareCaseInsensitive(record.sku, r.sku),
                  );
                  let totalShiped = 0;

                  shipmentAllItems
                    .filter(single => {
                      if (
                        compareCaseInsensitive(single.sku, r.sku) &&
                          single.orderId === parseInt(orderId)
                      ) {
                        return true;
                      }
                      return false;
                    })
                    .filter(single => {
                      let shipmentDataSingle = _.find(shipmentAll, {
                        id: single.shipmentId
                      });
                      if (shipmentDataSingle) {
                        let bill = _.find(shipmentBills, {
                          PurchaseOrderId: shipmentDataSingle.shipmentId
                        });

                        if (typeof bill === 'undefined') {
                          return true;
                        }

                        return false;
                      }
                      return true;
                    })
                    .map(single => {
                      totalShiped = totalShiped + single.expected;
                      return true;
                    });

                  let availablePO =
                    r.quantity_ordered -
                    r.quantity_received -
                    totalShiped;

                  // Web Ship Date
                  // ------------------------------------------------------------
                  let shipDate = 'N/A';

                  // New Shopify SKU
                  const newShopifyProduct = _.find(shopifyProductsData.shopifyShipDate, record =>
                    compareCaseInsensitive(record.sku, r.sku)
                  );
                  if (newShopifyProduct?.shipDate) {
                    try {
                      if (newShopifyProduct.waitlist) {
                        shipDate = 'Waitlist';
                      } else {
                        const date = parse(newShopifyProduct.shipDate, 'y-MM-dd', new Date());
                        shipDate = format(date, 'MM-dd-y');
                      }
                    } catch (error) {
                      console.log(error);
                    }
                  }
                  // Old Shopify SKU
                  const oldShopifyMergeProduct = _.find(shopifyProductsData.shopifyMerge, record =>
                    compareCaseInsensitive(record.newSKU, r.sku)
                  );
                  if (oldShopifyMergeProduct) {
                    const oldShopifyProduct = _.find(shopifyProductsData.shopifyShipDate, (record) =>
                      compareCaseInsensitive(record.sku, oldShopifyMergeProduct.SKU)
                    );
                    if (oldShopifyProduct?.shipDate) {
                      try {
                        if (newShopifyProduct.waitlist) {
                          shipDate = 'Waitlist';
                        } else {
                          const date = parse(oldShopifyProduct.shipDate, 'yyyy-MM-dd', new Date());
                          shipDate = format(date, 'MM-dd-y');
                        }
                      } catch (error) {
                        console.log(error);
                      }
                    }
                  }

                  return (
                    <div className="order-items__single">
                      {shipmentId === null && (
                        <span
                          className={'close-button'}
                          onClick={e => this.handleRemove(i)}
                        />
                      )}
                      <div className="order-items__main-data">
                        <div className="order-items__line">
                          <div className="order-items__sku">
                            {r.sku}
                          </div>
                          <div className="order-items__main-column">
                            {r.product_name !== null && (
                              <div className="order-items__title">
                                {r.product_name}
                              </div>
                            )}
                            <div className="order-items__manage">
                              <div className="order-items__manage-item">
                                <label>Avail for Pre-sell Qty</label>
                                <input
                                  type="number"
                                  className="order-items__input"
                                  value={r.available}
                                  disabled="disabled"
                                />
                              </div>
                              <div className="order-items__manage-item">
                                <label>Expected Qty</label>
                                <input
                                  type="number"
                                  className="order-items__input"
                                  value={r.expected}
                                  disabled="disabled"
                                />
                              </div>
                              <div className="order-items__manage-item">
                                <label>Waitlist</label>
                                <input
                                  type="checkbox"
                                  defaultChecked={false}
                                  checked={r.waitlist}
                                  disabled="disabled"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="order-items__secondary-data">
                        <span>
                          Warehouse Backorder:{' '}
                          {typeof shipHeroProduct !== 'undefined'
                            ? shipHeroProduct.backorder
                            : 'N/A'}
                        </span>
                        <span className={availablePO < 0 ? 'minus-record' : ''}>
                          Left on PO: {availablePO}
                        </span>
                        <span>
                          Quantity Ordered: {r.quantity_ordered}
                        </span>
                        <span>Web ship date: {shipDate}</span>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="shipment-block">
              <div className="shipment-block__enter-data">
                <div className="shipment-block__item">
                  <label>Artisan ship date</label>
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    autoComplete="off"
                    disabled={true}
                    selected={formData.shipDate}
                    onChange={date => this.handleChange('shipDate', date)}
                    name="shipDate"
                  />
                </div>
                <div className="shipment-block__item">
                  <label>Status</label>
                  <FormControl className={'customSelect'}>
                    <Select
                      disabled="disabled"
                      value={formData.dashStatus}
                      name="dashStatus"
                    >
                      <MenuItem value={'On-hold'}>On-hold</MenuItem>
                      <MenuItem value={'Planning'}>Planning</MenuItem>
                      <MenuItem value={'In Production'}>In Production</MenuItem>
                      <MenuItem value={'Preparing Shipping'}>
                        Preparing Shipping
                      </MenuItem>
                      <MenuItem value={'Shipping'}>Shipping</MenuItem>
                      <MenuItem value={'Delayed'}>Delayed</MenuItem>
                      <MenuItem value={'Partial Landed'}>
                        Partial Landed
                      </MenuItem>
                      <MenuItem value={'Landed'}>Landed</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="shipment-block__item">
                  <label>Estimated landing date</label>
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    autoComplete="off"
                    disabled={true}
                    selected={formData.landingDate}
                    onChange={date => this.handleChange('landingDate', date)}
                    name="landingDate"
                  />
                </div>
                <div className="shipment-block__item">
                  <label>Shipping Vendor</label>
                  <FormControl className={'customSelect'}>
                    <Select
                      disabled="disabled"
                      value={formData.shipingVendor}
                      name="shipingVendor"
                    >
                      <MenuItem value={'None'}>None</MenuItem>
                      <MenuItem value={'DHL'}>DHL</MenuItem>
                      <MenuItem value={'FedEx'}>FedEx</MenuItem>
                      <MenuItem value={'In-Country Provider'}>
                        In-Country Provider
                      </MenuItem>
                      <MenuItem value={'UPS'}>UPS</MenuItem>
                      <MenuItem value={'WWE'}>WWE</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="shipment-block__item">
                  <label>Earliest customer ship date </label>
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    autoComplete="off"
                    disabled={true}
                    selected={formData.customerShipDate}
                    onChange={date =>
                      this.handleChange('customerShipDate', date)
                    }
                    name="customerShipDate"
                  />
                </div>
                <div className="shipment-block__item">
                  <label>Shipping Means</label>
                  <FormControl className={'customSelect'}>
                    <Select
                      disabled="disabled"
                      value={formData.shipingMeans}
                      name="shipingMeans"
                    >
                      <MenuItem value={'Air Express'}>Air Express</MenuItem>
                      <MenuItem value={'Air Freight'}>Air Freight</MenuItem>
                      <MenuItem value={'Ground'}>Ground</MenuItem>
                      <MenuItem value={'Ocean'}>Ocean</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="shipment-block__item">
                  <label>Tracking</label>
                  <input
                    type="text"
                    name="tracking"
                    value={formData.tracking}
                    disabled="disabled"
                  />
                </div>
              </div>
              <div className="shipment-block__submit-area">
                <Button
                  variant="contained"
                  onClick={this.handleCancel}
                  className={'Button'}
                >
                  <span className="Label">Back</span>
                </Button>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

export default connect(
  state => state,
  mapDispatchToProps
)(ShipmentView);
