import { AppThunk } from 'redux/store';
import { KitsActions } from 'reducers/kitsReducer';
import BackendService from 'services/Backend/Backend.service';
import { toast } from 'react-toastify';
import { Kit } from 'types/state';

export const updateKit = (kit: Kit, componentIdsToRemove: number[], resetForm: (defaultValues?: Record<string, any>) => void): AppThunk => async (dispatch, getState) => {
  try {
    await BackendService.updateKit(kit, componentIdsToRemove);
    resetForm(undefined);
    dispatch(KitsActions.updateKit(kit));
    dispatch(KitsActions.closeCreateOrEditKitModal());
    toast.success('Updated Kit');
  } catch (error) {
    toast.error('Could not update Kit. Please try again.');
    console.log('error', error);
  }
};
