import { AppThunk } from 'redux/store';
import BackendService from 'services/Backend/Backend.service';
import { toast } from 'react-toastify';
import { IncomingActions } from 'reducers/newIncoming.reducer';
import { runForMinDuration } from 'shared/utils';

export const fetchIncomingData = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(IncomingActions.setIsFetchingIncomingData(true));

    const incomingData = await runForMinDuration(() => BackendService.fetchIncomingData());

    dispatch(IncomingActions.setIncomingData(incomingData));
  } catch (error) {
    toast.error('Could not fetch Incoming Data. Please try again.');
  }
  dispatch(IncomingActions.setIsFetchingIncomingData(false));
};
