import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { Helmet } from "react-helmet";

import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import actions from '../actions';

import SettingsForm from '../components/Forms/Settings';
import { HTML_TITLE_SUFFIX } from 'shared/constants';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    zIndex: 1,
    position: 'relative',
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
  },
});

class Settings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  render() {
    const {classes} = this.props;

    return (
      <div className={classes.root}>
        <Helmet>
          <title>Settings {HTML_TITLE_SUFFIX}</title>
        </Helmet>
        <main className='Main'>
          <div className='Main__Content'>
            <div className="Title__Content">
              <div className="Title">SETTINGS</div>
            </div>
            <div className="Table__Content">
              <div className="Info__Content">
                <div className='Description'>
                  Settings Data
                </div>
              </div>
              <div className="form__content">
                <SettingsForm/>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

Settings.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
});

const ConnectedSettings = connect(
  state => state,
  mapDispatchToProps
)(Settings);

export default withStyles(styles, {withTheme: true})(ConnectedSettings);
