import React from 'react';
interface Props {
  color?: string;
}

const LeftArrowIcon = ({
  color = "#2B4153",
}: Props) => {
  return (
    <svg width="11" height="11" fill="none" viewBox="0 0 11 11">
      <path stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 5.5H1M5.5 10L1 5.5 5.5 1" />
    </svg>

  );
};

export default LeftArrowIcon;
