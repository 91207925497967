import _, { differenceBy, minBy, orderBy } from 'lodash';
import moment from 'moment';
import { compareCaseInsensitive } from 'shared/utils';

const CONSTANTS = {
  WAITLIST: 'WAITLIST',
};

export const calculateWOH = (
  sku,
  sell_thru,
  available,
  backorder,
  orderAllDetailsData
) => {
  if (
    sell_thru === null ||
    typeof backorder === 'undefined' ||
    typeof available === 'undefined' ||
    available === null ||
    backorder === null
  ) {
    return '';
  }

  const selThrow = sell_thru !== 0 ? sell_thru : 1;
  let outstandingAll = 0;

  orderAllDetailsData
    .filter(r => compareCaseInsensitive(r.sku, sku))
    .filter(r => r.closed !== 1)
    .map(r => {
      outstandingAll =
        outstandingAll +
        r.quantity_ordered -
        r.quantity_received;

      return true;
    });

  return ((available - backorder + outstandingAll) / selThrow).toFixed(2);
};

const getECSDAndPresellForComponent = ({ backorder, shipments, sku }) => {
  let presellQuantity = parseInt(backorder);
  let ECSD = '';

  if (backorder > 0) {
    let closestShipment: any = null;

    const shipmentsUsed: any[] = [];
    for (const shipment of shipments) {
      if (presellQuantity >= 0) {
        const availableFromShipment = parseInt(shipment.available)
        presellQuantity = presellQuantity - availableFromShipment;

        shipmentsUsed.push(shipment);

        closestShipment = shipment;
      }
    }

    if (closestShipment !== null) {
      if (closestShipment.waitlist === 1) {
        ECSD = CONSTANTS.WAITLIST;
      } else {
        const closestCustomerShipDate = closestShipment.shipmentData.customerShipDate;
        if (closestCustomerShipDate !== null) {
          // are there other shipments with available and the same ship date that we haven't already accounted for?
          const shipmentsNotUsed: any = differenceBy(shipments, shipmentsUsed, 'id');
          const shipmentsWithSameShipDate = shipmentsNotUsed
            .filter(({ id, shipmentData: { customerShipDate }, available }) => {
              return id !== closestCustomerShipDate.id
                && customerShipDate === closestCustomerShipDate
                && available > 0;
            });

          presellQuantity = shipmentsWithSameShipDate
            .reduce((quantity, shipment) => quantity - shipment.available, presellQuantity)

          ECSD = moment(closestCustomerShipDate).format('MM/DD/YYYY');
        }
      }
    }

    presellQuantity = presellQuantity * -1;
  } else {
    if (shipments.length > 0) {
      /*
        Always surface the shipment with the earliest customerShipDate
        and an available quantity for Current Shipment Left to Pre-sell.
        Since the sort above does not account for waitlist (which is
        expected behavior) we must do it here.
      */
      /*
          TODO: create unit tests with scenarios below

          Scenario 1:
          no shipment with available
          [
            { available: 0,  waitlist: 0, ECSD: 10/22 }
          ] => use no shipment

          Scenario 2:
          no shipment with available and shipment that has waitlist with none available
          [
            { available: 0,  waitlist: 0, ECSD: 10/20 },
            { available: 0,  waitlist: 1, ECSD: null },
          ] => use no shipment

          Scenario 3:
          two shipments with available and the same ECSD
          [
            { available: 1,  waitlist: 0, ECSD: 10/20 },
            { available: 1,  waitlist: 0, ECSD: 10/20 },
            { available: 3,  waitlist: 0, ECSD: 10/24 },
            { available: 0,  waitlist: 1, ECSD: null },
          ] => use both shipments 1 and 2 / combine available

          Scenario 4:
          one shipment with available
          [
            { available: 1,  waitlist: 0, ECSD: 10/18 },
            { available: 0,  waitlist: 1, ECSD: null },
          ] => use shipment 1

          Scenario 5:
          one shipment with available
          [
            { available: 0,  waitlist: 0, ECSD: 10/18 },
            { available: 0,  waitlist: 1, ECSD: null },
          ] => use shipment 1
      */
      const shipmentsWithWaitlistLast = orderBy(
        shipments,
        [
          // waitlist
          (shipment: any) => shipment.waitlist === 1,
          // ECSD
          (shipment: any) => new Date(shipment.shipmentData.customerShipDate).getTime(),
        ],
        [
          "asc",
          "asc",
        ]);

      const shipmentsWithAvailable = shipmentsWithWaitlistLast.filter(shipment => shipment.available);

      if (shipmentsWithAvailable.length > 0) {
        const shipmentToUse = shipmentsWithAvailable[0];
        const firstCustomerShipDate = shipmentToUse.shipmentData.customerShipDate;

        // ECSD
        //---------------------------------------------------------------------
        if (firstCustomerShipDate !== null) {
          ECSD = moment(firstCustomerShipDate).format('MM/DD/YYYY');
        }

        // Presell Quantity
        //---------------------------------------------------------------------
        // are there other shipments with available and the same ECSD?
        const shipmentsWithAvailableOnSameDate = shipmentsWithAvailable.filter(
          shipment => shipment.shipmentData.customerShipDate === firstCustomerShipDate,
        );
        if (shipmentsWithAvailableOnSameDate.length > 0) {
          presellQuantity = shipmentsWithAvailableOnSameDate.reduce((available, shipment) => available + shipment.available , 0);
        } else {
          presellQuantity = shipmentToUse.available;
        }
      } else {
        /*
          we still might have a waitlist shipment whose ECSD we'll want
          to surface as waitlist
        */
        const waitlistShipments = shipments.filter(shipment => shipment.waitlist);
        if (waitlistShipments.length > 0) {
          ECSD = CONSTANTS.WAITLIST;
          presellQuantity = waitlistShipments[0].available;
        }
      }
    }
  }

  return {
    earliestShipDate: ECSD,
    presellQuantity: ECSD === '' ? '' : presellQuantity,
  };
}

const getShipments = ({
  sku,
  ordersAll,
  shipmentAll,
  shipmentAllItems,
  shipmentBills,
}) => {
  const shipmentswithSKU = shipmentAllItems.filter(
    shipment => shipment.sku === sku
  );

  const shipments = shipmentswithSKU
    .filter(r => {
      const productOrder = _.find(ordersAll, {
        PurchaseOrderId: r.orderId.toString()
      });

      if (
        typeof productOrder !== 'undefined' &&
        productOrder.closed === 1
      ) {
        return false;
      }

      return true;
    })
    .map(r => {
      r.shipmentData = _.find(shipmentAll, { id: r.shipmentId });
      return r;
    })
    .filter(r => {
      let bill = 'undefined';
      if (r.shipmentData) {
        bill = _.find(shipmentBills, { PurchaseOrderId: r.shipmentData.shipmentId });
      }

      return typeof bill === 'undefined';
    })
    .filter(r => {
      return (
        typeof r.shipmentData !== 'undefined' &&
        Object.keys(r.shipmentData).length > 0
      );
    })
    .filter(r => {
      return (
        r.waitlist === 1 ||
        (r.shipmentData.customerShipDate !== null &&
          r.shipmentData.customerShipDate !== '')
      );
    })
    .sort((a, b) => {
      return (
        moment(a.shipmentData.customerShipDate).valueOf() -
        moment(b.shipmentData.customerShipDate).valueOf()
      );
    });

  return shipments;
}

const getFurthestShipDate = (kitComponents: any[]) => {
  const componentsOrderedByEarliestShipDate = _.orderBy(
    kitComponents,
    (component: any) => {
      return new Date(component.earliestShipDate);
    },
    ['desc']
  );
  const furthestComponent = componentsOrderedByEarliestShipDate[0];
  return furthestComponent.earliestShipDate;
}


export const getECSDAndPresell = ({
  backorder,
  sku: productSku,
  shipment: {
    shipmentAll,
    shipmentAllItems,
    shipmentBills,
  },
  ordersAll,
  kitComponents
}) => {
  /* ------------------------------ Shipment Data ----------------------------- */
  const getShipmentsWithSKU = (sku) => getShipments({
    sku,
    shipmentAll,
    shipmentAllItems,
    ordersAll,
    shipmentBills,
  });

  /* -------------------------------------------------------------------------- */
  /*                               ECSD / Presell                               */
  /* -------------------------------------------------------------------------- */
  let earliestShipDate = '';
  let presellQuantity: string | number = '';

  /* -------------------------- Kitted ECSD / Presell ------------------------- */
  if (kitComponents && kitComponents.length > 0) {
    let backorderIsZeroForAllComponents = true;
    let hasWaitlistECSD = false;
    let hasNullECSDOrQuantity = false;
    let kitComponentsWithECSDAndPreSell: any[] = [];
    for (const component of kitComponents) {
      const backorderQuantity = component.shipheroInfo.backorder;

      if (backorderQuantity !== 0) {
        backorderIsZeroForAllComponents = false;
      }

      const {
        earliestShipDate: componentEarliestShipDate,
        presellQuantity: componentPresellQuantity,
      } = getECSDAndPresellForComponent({
        backorder: backorderQuantity,
        shipments: getShipmentsWithSKU(component.sku),
        sku: productSku,
      });

      if (componentEarliestShipDate === CONSTANTS.WAITLIST) {
        hasWaitlistECSD = true;
      } else if (componentEarliestShipDate === '' || componentPresellQuantity === '') {
        hasNullECSDOrQuantity = true;
      }

      kitComponentsWithECSDAndPreSell.push({
        ...component,
        earliestShipDate: componentEarliestShipDate,
        presellQuantity: componentPresellQuantity,
      });
    }

    /*
      - If one or more component SKU is designated as WAITLIST, the kitted SKU
        should show WAITLIST for Earliest Cust Ship Date.
      - If one or more component SKU has a null value for Earliest Cust. Ship
        date (and no components are designated as WAITLIST), the kitted SKU should
        show a null value for Earliest Cust. Ship Date.
    */
    if (hasWaitlistECSD) {
      return {
        earliestShipDate: CONSTANTS.WAITLIST,
        presell: 0,
      };
    } else if (hasNullECSDOrQuantity) {
      // ECSD and Quantity are empty strings for rendering purposes
      return {
        earliestShipDate: '',
        presell: 0,
      };
    }

    if (backorderIsZeroForAllComponents) {
      const kitComponentsWithFullData = kitComponentsWithECSDAndPreSell.map(component => {
        const {
          componentInfo: {
            quantity,
          },
          presellQuantity,
        } = component;

        return {
          ...component,
          kitQuantity: Math.floor(presellQuantity / quantity),
        };
      });
      /*
        Current Shipment Left to Pre-Sell
        ---------------------------------
        Pre-Sell is the total of First Open Shipment shown, calculated as the
        lowest quantity available of any component based on the quantity in
        the kit
      */
      const componentWithLowestKitQuantity: any = minBy(kitComponentsWithFullData, 'kitQuantity');
      presellQuantity = componentWithLowestKitQuantity.kitQuantity;

      /*
        Earliest Customer Ship Date
        ---------------------------------
        Show Earliest Customer Ship Date for First Shipment available for furthest
        out component
      */
      earliestShipDate = getFurthestShipDate(kitComponentsWithFullData);
    } else {
      const kitComponentsWithFullData = kitComponentsWithECSDAndPreSell.map(component => {
        const {
          componentInfo: {
            quantity,
          },
          shipheroInfo: {
            available,
            backorder,
          },
          presellQuantity,
        } = component;

        const availableToUse = backorder > 0 ? presellQuantity : available;
        const kitQuantity = Math.floor(availableToUse / quantity);

        return {
          ...component,
          kitQuantity,
        };
      });
      /*
        Current Shipment Left to Pre-Sell
        ---------------------------------
        Pre-sell is the lowest quantity available of any component, based on
        the quantity in the kit
      */
      const componentWithLowestKitQuantity: any = minBy(kitComponentsWithFullData, 'kitQuantity');
      presellQuantity = componentWithLowestKitQuantity.kitQuantity;

      /*
        Earliest Customer Ship Date
        ---------------------------------
          Show Earliest Customer Ship Date for furthest out component shipment we are
          currently pre-selling against
      */
      const presellingKitComponents = kitComponentsWithFullData.filter(component => component.shipheroInfo.backorder > 0);
      earliestShipDate = getFurthestShipDate(presellingKitComponents);
    }
  } else {
  /* ------------------------ Component ECSD / Presell ------------------------ */
    const shipments = getShipmentsWithSKU(productSku);
    const {
      presellQuantity: componentPresellQuantity,
      earliestShipDate: componentEarliestShipDate
    } = getECSDAndPresellForComponent({ backorder, shipments, sku: productSku });

    earliestShipDate = componentEarliestShipDate;
    presellQuantity = componentPresellQuantity;
  }

  return {
    earliestShipDate,
    presell: presellQuantity,
  };
};
