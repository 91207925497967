import React, { useMemo } from 'react';
import { useTypedSelector } from 'shared/utils';
import Table, { createLoadingRows } from 'components/NewReactTable/Table';
import { columnsData } from './ProductLog.columns';


const loadingRows = createLoadingRows(columnsData);

interface Props {
  exportDataRef: any;
}

const ProductLogTable = ({
  exportDataRef,
}: Props) => {
  const { isFetchingProductLog, productLogEntries } = useTypedSelector((
    { productLog: { isFetchingProductLog, productLogEntries } }
  ) => ({ isFetchingProductLog, productLogEntries }));

  const columns = useMemo(
    () => columnsData,
    []
  );

  const tableData = useMemo(
    () => isFetchingProductLog ? loadingRows as any : productLogEntries,
    [isFetchingProductLog]
  );

  return (
    <Table
      tableData={tableData}
      csvExportPrefix="Product Log"
      exportDataRef={exportDataRef}
      isFetchingData={isFetchingProductLog}
      columns={columns}
      noResultsAdditionalText="Please note that spelling must be exact and date search is between Date Started and Date Ended"
    />
  );
};

export default ProductLogTable;
