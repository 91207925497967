import * as types from '../types';
import axios from 'axios';

export const fetchIncomingData = () => {
  return dispatch => {
    return axios.get('/incoming/all')
      .then(response => {
        dispatch(processIncoming(response.data));
        return response;
      })
      .catch(error => {
        console.log('error from server: ', error);
      });
  };
}

const processIncoming = (data) => {
  return {
    type: types.PROCESS_INCOMING_ALL,
    payload: {data}
  };
}
