import { ImmerReducer } from "immer-reducer";
import { createActionCreators, createReducerFunction } from "immer-reducer";
import { IncomingState } from "types/state";


const initialState: IncomingState = {
  incomingData: [],
  isFetchingIncomingData: true,
};

class IncomingReducer extends ImmerReducer<IncomingState> {
  setIncomingData(data: any) {
    this.draftState.incomingData = data;
  }

  setIsFetchingIncomingData(isFetching: boolean) {
    this.draftState.isFetchingIncomingData = isFetching;
  }
}

export const IncomingActions = createActionCreators(IncomingReducer);
const reducerFunction = createReducerFunction(IncomingReducer, initialState);
export default reducerFunction;
