import React, { useEffect, useMemo, useState } from 'react';
import { useTypedSelector } from 'shared/utils';
import { newTableGetExportFileBlob } from 'components/NewReactTable/tableUtils';
import Table, { createLoadingRows } from 'components/NewReactTable/Table';
import localForage from "localforage";
import { columnsData } from './NewIncoming.columns';
import { filterUpcomingWaitlists } from './filters/Newincoming.filters';


const loadingRows = createLoadingRows(columnsData);

const LOCAL_FORAGE_COLUMN_ACCESSOR = 'incomingHiddenColumns';
interface Props {
  exportDataRef: any;
}

const IncomingTable = ({
  exportDataRef,
}: Props) => {
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const [hasFetchedHiddenColumns, setHasFetchedHiddenColumns] = useState<boolean>(false);
  const [isUpcomingWaitlistsFilterActive, setIsUpcomingWaitlistsFilterActive] = useState<boolean>(false);

  useEffect(() => {
    (async function () {
      const columns = await localForage.getItem<string[]>(LOCAL_FORAGE_COLUMN_ACCESSOR);
      setHiddenColumns(columns ?? []);
      setHasFetchedHiddenColumns(true);
    })();
  }, []);

  const { isFetchingIncomingData, incomingData } = useTypedSelector((
    { newIncoming: { isFetchingIncomingData, incomingData } }
  ) => ({ isFetchingIncomingData, incomingData }));

  const columns = useMemo(
    () => columnsData,
    []
  );

  const tableData = useMemo(
    () => {
      if (isFetchingIncomingData) {
        return loadingRows as any
      } else {
        return isUpcomingWaitlistsFilterActive ? filterUpcomingWaitlists(incomingData) : incomingData;
      }
    },
    [isFetchingIncomingData, incomingData, isUpcomingWaitlistsFilterActive]
  );

  const onHiddenColumnsUpdate = (hiddenColumns: string[]) => {
    setHiddenColumns(hiddenColumns);
    localForage.setItem(LOCAL_FORAGE_COLUMN_ACCESSOR, hiddenColumns);
  }

  if (!hasFetchedHiddenColumns) {
    return null;
  }
  
  return (
    <Table
      tableData={tableData}
      csvExportPrefix="Incoming"
      exportDataRef={exportDataRef}
      isFetchingData={isFetchingIncomingData}
      columns={columns}
      noResultsAdditionalText="Please note that spelling must be exact"
      hiddenColumns={hiddenColumns as string[]}
      onHiddenColumnsUpdate={onHiddenColumnsUpdate}
      getExportFileBlob={newTableGetExportFileBlob}
      isUpcomingWaitlistsFilterActive={isUpcomingWaitlistsFilterActive}
      setIsUpcomingWaitlistsFilterActive={setIsUpcomingWaitlistsFilterActive}
      showVisibilitySettings
    />
  );
};

export default IncomingTable;
