interface ProductInventoryRowStub {
  currentShipmentLeftToPreSell: number | null;
  webShipDate: string | null;
  shipheroInventory: number | string;
  shopifyInventory: number;
  shopifyTitle: string;
}

export const filterInventoryDiscrepancies = (productInventory: ProductInventoryRowStub[]) => {
  /**
   * The two scenarios we would want to include in the "Inventory Adjustment" filter: 
   * If Dash Warehouse Inventory > 0, then Dash Shopify Inventory and Dash Warehouse
   * Inventory should be the same number. 
   * If Dash Warehouse Inventory = 0, then Dash Shopify Inventory and Current Shipment
   * Left to Pre-sell should be the same number except if item has Web Ship Date = Waitlist. 
   */
  const returnData: any[] = [];
  for (const row of productInventory) {
    const {
      currentShipmentLeftToPreSell,
      webShipDate,
      shipheroInventory,
      shopifyInventory,
      shopifyTitle,
    } = row;

    /** 
     * Initial Removal
     * - Warehouse inventory = n/a (item doesn't exist in WSI)
     * - Product title contains Build Your Own Bundle or Skeleton
     *   (no inventory should actually be associated with either of
     *    these records in Shopify or WSI)
    */
    if (shipheroInventory === 'N/A' ||
      shopifyTitle.includes('Build Your Own Bundle') ||
      shopifyTitle.includes('Skeleton')
    ) {
      continue;
    }

    
    let hasDiscrepancy = false;
    if (webShipDate === null) {
      hasDiscrepancy = shopifyInventory !== shipheroInventory;
    } else {
      if (shipheroInventory > 0) {
        hasDiscrepancy = shopifyInventory !== shipheroInventory;  
      } else {
        if (webShipDate === 'Waitlist') {
          hasDiscrepancy = false;
        } else {
          hasDiscrepancy = shopifyInventory !== (currentShipmentLeftToPreSell ?? 0);
        }
      }
    }
    
    if (hasDiscrepancy) {
      returnData.push(row);
    }
  }
  return returnData;
}
