import axios from 'axios';

export const fetchBillItems = (shipmentId) => {
  return dispatch => {
    return axios.get('/shipment/bills/items')
      .then(response => {
        // dispatch(processShipmentItemsAction(response.data));
        return response.data;
      })
      .catch(error => {
        console.log('error from server: ', error);
      });
  }
};

export const fetchBillItemsByOrderId = (orderId) => {
  return dispatch => {
    return axios.get('/shipment/bills/items/' + orderId)
      .then(response => {
        console.log(response)
        // dispatch(processShipmentItemsAction(response.data));
        return response.data;
      })
      .catch(error => {
        console.log('error from server: ', error);
      });
  }
};
