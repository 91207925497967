import * as types from '../types';

const INITIAL_STATE = {
  userLoggedIn: false,
  invalidLoginAttempt: false,
  invalidLoginCode: '',
  invalidLoginMessage: ''
};

const loginReducer = (state = INITIAL_STATE, action) => {
  let newState = {...state};
  switch(action.type) {
    case types.SIGN_IN_SUCCESS:
      newState.userLoggedIn = true;
      newState.invalidLoginAttempt = false;
      newState.invalidLoginMessage = '';
      return newState;
    case types.SIGN_IN_ERROR: // If we error, change the flag, and save the code and message
      newState.invalidLoginAttempt = true;
      newState.invalidLoginCode = action.payload.error.code;
      newState.invalidLoginMessage = action.payload.error.message;
      return newState;
    default:
      return newState;
  }
};

export default loginReducer;