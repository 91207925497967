import { AppThunk } from 'redux/store';
import BackendService from 'services/Backend/Backend.service';
import { toast } from 'react-toastify';
import { ProductInventoryActions } from 'reducers/productInventory.reducer';

export const editWaitlistCell = ({
  rowIndex,
  currentWaitlistValue,
  sku
}): AppThunk => async (dispatch) => {
  const newWaitlistValue = !currentWaitlistValue;

  dispatch(ProductInventoryActions.modifyWaitlistCell({
    isWaitlist: newWaitlistValue,
    rowIndex,
  }));

  try {
    const data = { sku: sku, waitlist: newWaitlistValue, shipDate: null };
    await BackendService.editShipment(data);
  } catch (error) {
    toast.error(`Could not edit waitlist for sku ${sku}. Please try again.`);
  }
};
