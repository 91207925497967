import { css, StyleSheet } from 'aphrodite';
import { textStyles } from 'components/Text';
import React from 'react';
import { darkBlue, gray, tableLineGray } from 'shared/styles/colors';
import { DebounceInput } from 'react-debounce-input';


interface Props {
  column: {
    filterValue?: { min: string | null, max: string | null };
    setFilter: (value: any) => void;
    preFilteredRows: any[];
    id: number;
  }
}

const MinMaxColumnFilter = ({
  column: {
    filterValue,
    setFilter,
  },
}: Props) => {
  const onMinFilterChange = (text: string | undefined) => {
    const hasMaxFilterValue = filterValue?.max !== null;
    let filter;
    if (!hasMaxFilterValue && !text) {
      filter = undefined;
    } else {
      filter = {
        min: text ?? null,
        max: filterValue?.max ?? null,
      }
    }
    setFilter(filter);
  }
  
  const onMaxFilterChange = (text: string | undefined) => {
    const hasMinFilterValue = filterValue?.min !== null;
    let filter;
    if (!hasMinFilterValue && !text) {
      filter = undefined;
    } else {
      filter = {
        min: filterValue?.min ?? null,
        max: text ?? null,
      }
    }
    setFilter(filter);
  }

  return (
    <div className={css(styles.MinMaxColumnFilter)}>
      <div className={css(styles.inputsContainer)}>
        <DebounceInput
          className={css(styles.searchInput)}
          minLength={1}
          debounceTimeout={300}
          onChange={e => onMinFilterChange(e.target.value)}
          placeholder="min"
        />
        <DebounceInput
          className={css(styles.searchInput)}
          minLength={1}
          debounceTimeout={300}
          onChange={e => onMaxFilterChange(e.target.value)}
          placeholder="max"
        />
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  MinMaxColumnFilter: {
    position: 'relative',
  },
  //
  searchInput: {
    ...textStyles.SourceSansProRegular,
    height: 38,
    width: '100%',
    paddingLeft: 8,
    border: `1px solid ${tableLineGray}`,
    color: darkBlue,
    fontSize: 16,
    margin: '0 4px',
    borderRadius: 4,
    '::placeholder': {
      color: gray,
      fontSize: 14,
    }
  },
  inputsContainer: {
    display: 'flex',
  },
});


export default MinMaxColumnFilter;
