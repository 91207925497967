import { css, StyleSheet } from 'aphrodite';
import React from 'react';
import { ReactComponent as HomeIllustration } from 'assets/svgs/home-illustration.svg';
import Text, { Font } from 'components/Text';
import { blueGray, darkBlue } from 'shared/styles/colors';

interface Props {
  additionalText: string;
}

const NoSearchResults = ({ additionalText }: Props) => {
  return (
    <section className={css(styles.NoSearchResults)}>
      <div className={css(styles.homeIllustrationWrapper)}>
        <HomeIllustration />
      </div>
      <Text font={Font.SourceSansProBold} style={styles.header}>
        No Records Found
      </Text>
      <Text style={styles.subHeader}>
        {additionalText}
      </Text>
    </section>
  );
};

export default NoSearchResults;

const styles = StyleSheet.create({
  NoSearchResults: {
    position: 'fixed',
    top: 'calc(50% - 108px)',
    left: 'calc(50% - 149px)',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 32,
    pointerEvents: 'none',
  },
  //
  homeIllustrationWrapper: {
    marginBottom: 40,
  },
  header: {
    fontSize: 26,
    color: darkBlue,
    marginBottom: 16,
  },
  subHeader: {
    fontSize: 18,
    color: blueGray,
    width: 300,
    textAlign: 'center',
    lineHeight: 1.4
  },
});
