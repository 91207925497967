import * as loginActions from './login_actions';
import * as shipheroActions from './shiphero_actions';
import * as shopifyActions from './shopify_actions';
import * as ordersActions from './orders_actions';
import * as shipmentOrders from './shipment_actions';
import * as sosActions from './sos_actions';
import * as menuActions from './menu_actions';
import * as settingsActions from './settings_actions';
import * as incomingActions from './incoming_actions';
import * as usersActions from './users_action';
import * as billsActions from './bills_action';
import * as inboundShipmentActions from './inbound_shipment_actions';

const actions = Object.assign(
  {},
  loginActions,
  shipheroActions,
  shopifyActions,
  ordersActions,
  shipmentOrders,
  sosActions,
  menuActions,
  settingsActions,
  incomingActions,
  usersActions,
  billsActions,
  inboundShipmentActions
);

export default actions;
