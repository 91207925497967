import { AppThunk } from 'redux/store';
import BackendService from 'services/Backend/Backend.service';
import { toast } from 'react-toastify';
import { ProductInventoryActions } from 'reducers/productInventory.reducer';
import { runForMinDuration } from 'shared/utils';

export const fetchProductInventory = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(ProductInventoryActions.setIsFetchingProductInventory(true));

    const productInventory = await runForMinDuration(() => BackendService.fetchProductInventory());

    dispatch(ProductInventoryActions.setProductInventory(productInventory));
  } catch (error) {
    toast.error('Could not fetch Product Inventory. Please try again.');
  }
  dispatch(ProductInventoryActions.setIsFetchingProductInventory(false));
};
