// LOG IN CONSTANTS
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';

// SHIPHERO CONSTANTS
export const PROCESS_RAW_SHIPHERO_PRODUCTS = 'PROCESS_RAW_SHIPHERO_PRODUCTS';

// SHOPIFY CONSTANTS
export const PROCESS_NEW_CSV_HEADERS = 'PROCESS_NEW_CSV_HEADERS';
export const PROCESS_SHOPIFY_PRODUCT_DATA = 'PROCESS_SHOPIFY_PRODUCT_DATA';
export const PROCESS_SHOPIFY_MAPPED_PRODUCT_DATA = 'PROCESS_SHOPIFY_MAPPED_PRODUCT_DATA';
export const PROCESS_SHOPIFY_MERGE_SKU = 'PROCESS_SHOPIFY_MERGE_SKU';
export const PROCESS_UPLOADED_CSV_DATA = 'PROCESS_UPLOADED_CSV_DATA';
export const UPDATE_SHOPIFY_ENTRY_WITH_SHIPHERO = 'UPDATE_SHOPIFY_ENTRY_WITH_SHIPHERO';
export const PROCESS_WEB_SHIPMENT = 'PROCESS_WEB_SHIPMENT';
export const UPDATE_WEB_SHIPMENT = 'UPDATE_WEB_SHIPMENT';
export const GET_ORDER_WAITLIST = 'GET_ORDER_WAITLIST';

// ORDERS CONSTANTS
export const PROCESS_ORDERS = 'PROCESS_ORDERS';
export const PROCESS_ORDER_DETAILS = 'PROCESS_ORDER_DETAILS';
export const PROCESS_ORDER_ALL_DETAILS = 'PROCESS_ORDER_ALL_DETAILS';

// SHIPMENT CONSTANTS
export const PROCESS_SHIPMENT = 'PROCESS_SHIPMENT';
export const PROCESS_SHIPMENT_ITEMS = 'PROCESS_SHIPMENT_ITEMS';
export const PROCESS_SHIPMENT_ALL_ITEMS = 'PROCESS_SHIPMENT_ALL_ITEMS';
export const PROCESS_SHIPMENT_BILLS = 'PROCESS_SHIPMENT_BILLS';
export const PROCESS_SHIPMENT_ALL = 'PROCESS_SHIPMENT_ALL';
export const PROCESS_SHIPMENT_DETAILS = 'PROCESS_SHIPMENT_DETAILS';
export const SELECT_SHIPMENT_SKU = 'SELECT_SHIPMENT_SKU';
export const CLEAR_SHIPMENT_SKU = 'CLEAR_SHIPMENT_SKU';

// SOS CONSTANTS
export const PROCESS_SOS_DATA = 'PROCESS_SOS_DATA';

// MENU CONSTANTS
export const MENU_TOGGLE = 'MENU_TOGGLE';
export const MENU_TOGGLE_PROFILE = 'MENU_TOGGLE_PROFILE';

// HEADER TABLE
export const UPDATE_TABLE_HEADER = 'UPDATE_TABLE_HEADER';
export const SUBMIT_TABLE_HEADER = 'SUBMIT_TABLE_HEADER';

// SETTINGS
export const SET_SETTINGS = 'SET_SETTINGS';

// INCOMING
export const PROCESS_INCOMING_ALL = 'PROCESS_INCOMING_ALL';
