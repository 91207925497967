import { ImmerReducer } from "immer-reducer";
import { createActionCreators, createReducerFunction } from "immer-reducer";
import { Kit, KitsState } from "types/state";
import { filter } from 'lodash';
import { normalize, schema } from 'normalizr';


const kitEntity = new schema.Entity('kits', undefined, { idAttribute: 'sku' });
const kitsSchema = [kitEntity];

const initialState: KitsState = {
  kits: [],
  kitsbySku: {},
  isLoading: false,
  isFetchingKits: true,
  isCreateKitModalOpen: false,
  isEditKitModalOpen: false,
  kitSkuToEdit: null,
};

class KitsReducer extends ImmerReducer<KitsState> {
  createKit(kit: Kit) {
    this.draftState.isLoading = false;
    this.draftState.kits = [kit, ...this.draftState.kits];

    const normalizedKits = normalize(this.draftState.kits, kitsSchema);
    this.draftState.kitsbySku = normalizedKits.entities.kits as { [key: number]: Kit } ?? {};
  }

  setIsloading(isLoading: boolean) {
    this.draftState.isLoading = isLoading;
  }

  setKits(kits: Kit[]) {
    this.draftState.kits = kits;

    const normalizedKits = normalize(kits, kitsSchema);
    this.draftState.kitsbySku = normalizedKits.entities.kits as {[key: number]: Kit} ?? {};
  }

  setIsFetchingKits(isFetchingKits: boolean) {
    this.draftState.isFetchingKits = isFetchingKits;
  }

  deleteKit(kitId: number) {
    const newKits = filter(this.draftState.kits, kit => kit.id !== kitId);
    this.draftState.kits = newKits;

    const normalizedKits = normalize(this.draftState.kits, kitsSchema);
    this.draftState.kitsbySku = normalizedKits.entities.kits as { [key: number]: Kit } ?? {};
  }

  updateKit(kit: Kit) {
    const kitIndex = this.draftState.kits.findIndex(k => k.id === kit.id);
    this.draftState.kits[kitIndex] = kit;

    const normalizedKits = normalize(this.draftState.kits, kitsSchema);
    this.draftState.kitsbySku = normalizedKits.entities.kits as { [key: number]: Kit } ?? {};
  }

  closeCreateOrEditKitModal() {
    this.draftState.isCreateKitModalOpen = false;
    this.draftState.isEditKitModalOpen = false;
    this.draftState.kitSkuToEdit = null;
  }

  setIsCreateKitModalOpen(isOpen: boolean) {
    this.draftState.isCreateKitModalOpen = isOpen;
  }

  setIsEditKitModalOpen(isOpen: boolean) {
    this.draftState.isEditKitModalOpen = isOpen;
  }

  openEditKitModal(kitSku: string) {
    this.draftState.kitSkuToEdit = kitSku;
    this.draftState.isEditKitModalOpen = true;
  }
}

export const KitsActions = createActionCreators(KitsReducer);
const reducerFunction = createReducerFunction(KitsReducer, initialState);
export default reducerFunction;
