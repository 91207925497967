import * as types from '../types';

const INITIAL_STATE = {
  incomingAll: []
}

const incomingReducer = (state = INITIAL_STATE, action) => {
  let newState = {...state};

  switch (action.type) {
    case types.PROCESS_INCOMING_ALL:
      newState.incomingAll = action.payload.data;
      return newState;

    default:
      return newState;
  }
};

export default incomingReducer;
