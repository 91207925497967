import React, { useEffect, useMemo, useState } from 'react';
import { useTypedSelector } from 'shared/utils';
import { newTableGetExportFileBlob } from 'components/NewReactTable/tableUtils';
import Table, { createLoadingRows } from 'components/NewReactTable/Table';
import localForage from "localforage";
import { columnsData } from './AtRisk.columns';
import FilterButton from 'components/FilterButton'; 
import { Info } from 'react-feather';
import RocketIcon from 'components/icons/RocketIcon';
import { missingPOsFilter, missingShipmentDetailsFilter, missingShipmentsFilter, presellFilter } from './AtRisk.filters';
import { AtRiskRow } from 'types/tablesTypes';


const loadingRows = createLoadingRows(columnsData);

const LOCAL_FORAGE_COLUMN_ACCESSOR = 'atRiskHiddenColumns';
interface Props {
  exportDataRef: any;
}

export type FilterTypes = "presell" | "missingPOs" | "missingShip" | "missingShipDetails" | "reset"

const AtRiskTable = ({
  exportDataRef,
}: Props) => {
  const { isFetchingAtRiskData, atRiskData } = useTypedSelector((
    { atRisk: { isFetchingAtRiskData, atRiskData } }
  ) => ({ isFetchingAtRiskData, atRiskData }));

  const [tableData, setTableData] = useState<AtRiskRow[]>([])
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const [hasFetchedHiddenColumns, setHasFetchedHiddenColumns] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<FilterTypes>('presell')

  useEffect(()=> {
    setTableData(presellFilter(atRiskData))
  }, [atRiskData])

  useEffect(() => {
    (async function () {
      const columns = await localForage.getItem<string[]>(LOCAL_FORAGE_COLUMN_ACCESSOR);
      setHiddenColumns(columns ?? []);
      setHasFetchedHiddenColumns(true);
    })();
  }, []);

  const onHiddenColumnsUpdate = (hiddenColumns: string[]) => {
    setHiddenColumns(hiddenColumns);
    localForage.setItem(LOCAL_FORAGE_COLUMN_ACCESSOR, hiddenColumns);
  }

  if (!hasFetchedHiddenColumns) {
    return null;
  }

  const switchFilter = (selectedFilter: FilterTypes) => {
    switch(selectedFilter){
      case 'presell':
        setSelectedFilter('presell')
        setTableData(presellFilter(atRiskData))
        break;
      case 'missingPOs':
        setSelectedFilter('missingPOs')
        setTableData(missingPOsFilter(atRiskData))
        break;
      case 'missingShip':
        setSelectedFilter('missingShip')
        setTableData(missingShipmentsFilter(atRiskData))
        break;
      case 'missingShipDetails': 
        setSelectedFilter('missingShipDetails')
        setTableData(missingShipmentDetailsFilter(atRiskData))
        break
      case 'reset': 
        setSelectedFilter('reset')
        setTableData(atRiskData)
        break
      default:
        setSelectedFilter('reset')
        setTableData(atRiskData)
    }
  }

  const filterButtons = [
    <FilterButton text={"About to Pre-sell"} isSelected={selectedFilter==='presell'} handleClick={()=> switchFilter('presell')} Icon={RocketIcon}/>,
    <FilterButton text={"Missing PO"} isSelected={selectedFilter==='missingPOs'} handleClick={()=>switchFilter('missingPOs')} Icon={Info} />,
    <FilterButton text={"Missing Next Shipment"} isSelected={selectedFilter==='missingShip'} handleClick={()=>switchFilter('missingShip')} Icon={Info} />,
    <FilterButton text={"Missing Shipment Details"} isSelected={selectedFilter==='missingShipDetails'} handleClick={()=>switchFilter('missingShipDetails')} Icon={Info} />
  ]

  return (
    <Table
      tableData={isFetchingAtRiskData ? loadingRows : tableData}
      csvExportPrefix="At Risk Inventory"
      exportDataRef={exportDataRef}
      isFetchingData={isFetchingAtRiskData}
      columns={columnsData}
      noResultsAdditionalText="Please note that spelling must be exact"
      hiddenColumns={hiddenColumns as string[]}
      onHiddenColumnsUpdate={onHiddenColumnsUpdate}
      getExportFileBlob={newTableGetExportFileBlob}
      showVisibilitySettings
      filterButtons={filterButtons}
      resetCustomFilters={()=> switchFilter('reset')}
    />
  );
};

export default AtRiskTable;
