import { AppThunk } from "redux/store";
import BackendService from "services/Backend/Backend.service";
import { toast } from "react-toastify";
import { AtRiskActions } from "reducers/atRisk.reducer";

export const fetchAtRiskData = (): AppThunk => async (dispatch) => {
  try {
    dispatch(AtRiskActions.setIsFetchingAtRiskData(true));

    const atRiskData = await BackendService.fetchAtRiskData();

    dispatch(AtRiskActions.setAtRiskData(atRiskData));
  } catch (error) {
    toast.error("Could not fetch At Risk Data. Please try again.");
  }
  dispatch(AtRiskActions.setIsFetchingAtRiskData(false));
};
