import * as types from '../types';

const INITIAL_STATE = {
  excludeSKU: []
}

const settingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_SETTINGS:
      return { ...state, ...action.payload }

    default:
      return state
  }
}

export default settingsReducer;
