import { AppThunk } from 'redux/store';
import BackendService from 'services/Backend/Backend.service';
import { toast } from 'react-toastify';
import { InventoryUploadActions } from 'reducers/inventoryUploadReducer';
import { WarehouseInventoryChange } from 'types/shared';

export const uploadWarehouseInventory = (inventory: WarehouseInventoryChange[]): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(InventoryUploadActions.setIsUploadingInventory(true));

    await BackendService.uploadWarehouseInventory(inventory);
    
    dispatch(InventoryUploadActions.uploadSuccess());
  } catch (error) {
    toast.error('Could not upload Inventory. Please try again.');
    dispatch(InventoryUploadActions.setIsUploadingInventory(false));
  }
};
