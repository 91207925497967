import axios from 'axios';
import * as types from '../types';

const processShipmentAction = (data) => {
  return {
    type: types.PROCESS_SHIPMENT,
    payload: {data}
  };
};

const processShipmentDetailsAction = (data) => {
  return {
    type: types.PROCESS_SHIPMENT_DETAILS,
    payload: {data}
  };
};

const processShipmentAllItemsAction = (data) => {
  return {
    type: types.PROCESS_SHIPMENT_ALL_ITEMS,
    payload: {data}
  };
};

const processShipmentBillsAction = (data) => {
  return {
    type: types.PROCESS_SHIPMENT_BILLS,
    payload: {data}
  };
};

const processShipmentAllAction = (data) => {
  return {
    type: types.PROCESS_SHIPMENT_ALL,
    payload: {data}
  };
}

const processShipmentClearSelectedSKU = (data) => {
  return {
    type: types.CLEAR_SHIPMENT_SKU,
    payload: {data}
  };
}

const processShipmentUpdateSelectedSKU = (data) => {
  return {
    type: types.SELECT_SHIPMENT_SKU,
    payload: {data}
  };
};

const processShipmentItemsAction = (data) => {
  return {
    type: types.PROCESS_SHIPMENT_ITEMS,
    payload: {data}
  };
};

export const fetchShipment = (id) => {
  return dispatch => {
    return axios.get('/shipment/' + id)
      .then(response => {
        dispatch(processShipmentAction(response.data));
        return response.data;
      })
      .catch(error => {
        console.log('error from server: ', error);
      });
  }
};

export const fetchShipmentItems = (shipmentId) => {
  return dispatch => {
    return axios.get('/shipment/' + shipmentId + '/items')
      .then(response => {
        dispatch(processShipmentItemsAction(response.data));
        return response.data;
      })
      .catch(error => {
        console.log('error from server: ', error);
      });
  }
};

export const fetchShipmentDetails = (shipmentId) => {
  return dispatch => {
    return axios.get('/shipment/' + shipmentId + '/details')
      .then(response => {
        dispatch(processShipmentDetailsAction(response.data));
        return response.data;
      })
      .catch(error => {
        console.log('error from server: ', error);
      });
  }
};

export const fetchShipmentAllItems = () => {
  return dispatch => {
    return axios.get('/shipment/items')
      .then(response => {
        dispatch(processShipmentAllItemsAction(response.data));
        return response.data;
      })
      .catch(error => {
        console.log('error from server: ', error);
      });
  }
};

export const fetchShipmentBills = () => {
  return dispatch => {
    return axios.get('/shipment/bills')
      .then(response => {
        dispatch(processShipmentBillsAction(response.data));
        return response.data;
      })
      .catch(error => {
        console.log('error from server: ', error);
      });
  }
}

export const fetchShipmentAll = () => {
  return dispatch => {
    return axios.get('/shipment/all')
      .then(response => {
        dispatch(processShipmentAllAction(response.data));
        return response.data;
      })
      .catch(error => {
        console.log('error from server: ', error);
      });
  }
};

export const storeShipment = (data) => {
  return dispatch => {
    return axios.post('/shipment', data);
  }
};

export const updateShipment = (data, id) => {
  return dispatch => {
    return axios.put('/shipment/' + id, data);
  }
}

export const removeShipment = (id) => {
  return dispatch => {
    return axios.delete('/shipment/' + id);
  }
};

export const updateSelectedSku = (sku) => {
  return dispatch => {
    dispatch(processShipmentUpdateSelectedSKU(sku));
  }
}

export const clearSelectedSku = () => {
  return dispatch => {
    dispatch(processShipmentClearSelectedSKU([]));
  }
}
