import React from 'react';


interface Props {
  size?: number;
  color?: string;
}

const SearchIcon = ({
  size = 16,
  color = '#97A2AB',
}: Props) => {
  return (
    <svg width={size} height={size} fill="none" viewBox="0 0 16 16">
      <path stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7.222 13.444c3.437 0 6.222-2.785 6.222-6.222C13.444 3.786 10.66 1 7.222 1 3.786 1 1 3.786 1 7.222c0 3.437 2.786 6.222 6.222 6.222zM15 15l-3.383-3.383" />
    </svg>
  );
};

export default SearchIcon;
