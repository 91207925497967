export const white = '#ffffff';
export const black = '#282c2f';
export const blueGray = '#676c70'
export const darkBlue = '#2b4153';
export const ctaBlue = '#4591cd';
export const ctaBlueHover = '#3482C0';
export const gray = '#7B838A';
export const taupe = '#AF8E7A';
export const darkTaupe = '#8F6C56';
export const lightTaupe = '#C4A089';
export const lightGray = '#EAEFF3';
export const red = '#CD4545';
export const tableLineGray = '#e4e4e7';
export const midBlue = '#2a5474';
export const green = '#A3C39D';
export const darkRed = '#844353';
export const lightBlue = '#ECF2FE';
export const lightGold = '#FCF0E0';
export const gold = '#D39C4A';
export const darkGold = '#BC7C37';
export const brightGreen = "#32ba38";
