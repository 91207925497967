import { css, StyleSheet } from 'aphrodite';
import Text from 'components/Text';
import React from 'react';
import { black, darkRed, green, tableLineGray } from 'shared/styles/colors';
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import { RectShape } from 'react-placeholder/lib/placeholders';
import { LOADING_IDENTIFIER } from 'shared/constants';
import { Cell as ReactTableCell } from 'react-table';
import { ReactComponent as WhiteCircleArrowUp } from 'assets/svgs/white-arrow-up-circle.svg';
import { ReactComponent as WhiteCircleArrowDown } from 'assets/svgs/white-arrow-down-circle.svg';

interface Props {
  cell: ReactTableCell;
}

export const ShopifyInventoryCell = ({ cell: { value, row: { original: product }} }: Props) => {
  if (value === LOADING_IDENTIFIER) {
    return (
      <div className={css(styles.Cell)}>
        {/* @ts-ignore */}
        <ReactPlaceholder
          showLoadingAnimation
          customPlaceholder={<RectShape color={tableLineGray} style={{ width: '80%', height: 24 }} />}
        />
      </div>
    );
  }
  const { shopifyInventoryType } = product as Record<string, any>;

  let borderContent;
  if (shopifyInventoryType) {
    let color = green;
    let icon = <WhiteCircleArrowUp />;
    if (shopifyInventoryType === 'Shortage') {
      color = darkRed;
      icon = <WhiteCircleArrowDown />;
    }
    borderContent = (
      <div
        className={css(styles.borderContent)}
        style={{ borderColor: color }}
      >
        <div
          className={css(styles.borderLeftSection)}
          style={{ backgroundColor: color }}
        >
          {icon}
        </div>
      </div>
    );
  }

  return (
    <div className={css(styles.Cell)}>
      {borderContent}
      <Text style={styles.text}>
        {value}
      </Text>
    </div>
  );
};

const styles = StyleSheet.create({
  Cell: {
    position: 'relative',
    minHeight: 54,
    paddingLeft: 24,
    paddingRight: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  //
  text: {
    fontSize: 16,
    textAlign: 'end',
    color: black,
  },
  sortIconWrapper: {
    padding: 2,
    marginTop: 4,
    cursor: 'pointer',
  },
  textLink: {
    cursor: 'pointer',
  },
  borderContent: {
    position: 'absolute',
    top: -1,
    left: -1,
    width: 'calc(100% + 2px)',
    height: 'calc(100% + 6px)',
    borderWidth: 1,
    borderStyle: 'solid',
    backgroundColor: 'transparent'
  },
  borderLeftSection: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
