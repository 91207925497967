import { Row } from "react-table";
import PapaParse from 'papaparse';
import { differenceInCalendarDays, parseISO, parse, format } from "date-fns";
import { isValidDate } from "shared/utils";
import MiniSearch from 'minisearch';
import { smaller, larger } from 'mathjs';


export const sortByDate = (rowA: Record<string, any>, rowB: Record<string, any>, columnId: number, desc: boolean) => {
  const aVal = rowA.original[columnId];
  const bVal = rowB.original[columnId];

  const aTime = new Date(aVal).getTime();
  const bTime = new Date(bVal).getTime();

  if (aTime === bTime) {
    return 0;
  }

  if (desc) {
    return aTime > bTime ? 1 : -1;
  } else {
    return bTime > aTime ? -1 : 1;
  }
}

export const locationAgnosticSearch = (
  rows: Row[],
  columnIds: string[],
  filterValue: string,
) => {
  const columnIdentifier = columnIds[0];

  const activeField = `values.${columnIdentifier}`;
  const miniSearch = new MiniSearch({
    fields: [activeField],
    searchOptions: {
      prefix: true,
      combineWith: 'and',
      fuzzy: false,
    },
    extractField: (document, fieldName) => {
      if (fieldName === activeField) {
        return fieldName.split('.').reduce((doc, key) => doc && doc[key], document)
      }
      return document[fieldName];
    }
  });

  // Index all documents
  miniSearch.addAll(rows)

  const results = miniSearch.search(filterValue)

  const resultIds = results.map(result => result.id)

  const returnArray: Row[] = [];
  for (const row of rows) {
    if (resultIds.includes(row.id)) {
      returnArray.push(row);
    }
  }

  return returnArray;
}

type webShipDate = string | undefined | 'WAITLIST';
export const filterWebShipDate = (rows: Row[], columnIds: string[], filterValue: string) => {
  const columnIdentifier = columnIds[0];

  const preSellMatcher = (value: webShipDate) => ![undefined, '', 'WAITLIST'].includes(value);
  const waitlistMatcher = (value: webShipDate) => value === 'WAITLIST';

  const matcherToUse = {
    'Waitlist': waitlistMatcher,
    'Pre-sell': preSellMatcher,
  }[filterValue];

  const filteredRows = rows.filter(row => matcherToUse(row.values[columnIdentifier]));
  return filteredRows;
}

export const filterCalendarGreaterThan = (rows: Row[], columnIds: string[], filterValue: { date: Date, isNullChecked?: boolean }) => {
  const columnIdentifier = columnIds[0];
  const selectedTime = filterValue.date.getTime();

  const greaterThanOrEqualToDate = (date: string) => {
    const time = new Date(date).getTime();
    return time >= selectedTime;
  };

  const filteredRows = rows.filter(row => greaterThanOrEqualToDate(row.values[columnIdentifier]));

  return filteredRows;
};

export const filterCalendarLessThan = (
  rows: Row[],
  columnIds: string[],
  filterValue: { date: Date, isNullChecked: boolean },
) => {
  if (!filterValue.date && !filterValue.isNullChecked) {
    return rows;
  }

  const columnIdentifier = columnIds[0];

  const LessThanOrEqualToDate = (date: string | undefined) => {
    if (!date) {
      return false;
    }
    const selectedTime = filterValue.date.getTime();
    const time = new Date(date).getTime();
    return time <= selectedTime;
  };

  const filteredRows = rows.filter(row => {
    const value = row.values[columnIdentifier];
    return filterValue.isNullChecked ? !value : LessThanOrEqualToDate(value)
  });

  return filteredRows;
};

export const filterCalendarEqualTo = (
  rows: Row[],
  columnIds: string[],
  filterValue: { date: Date, isNullChecked: boolean },
) => {
  if (!filterValue.date && !filterValue.isNullChecked) {
    return rows;
  }

  const columnIdentifier = columnIds[0];

  const equalToDate = (date: string | undefined) => {
    if (!date) {
      return false;
    }
    const selectedTime = filterValue.date.getTime();
    const time = new Date(date).getTime();
    return time === selectedTime;
  };

  const filteredRows = rows.filter(row => {
    const value = row.values[columnIdentifier];
    return filterValue.isNullChecked ? !value : equalToDate(value)
  });

  return filteredRows;
};

export const filterWOH = (
  rows: Row[],
  columnIds: string[],
  filterValue: string,
) => {
  if (!filterValue) {
    return rows;
  }

  const columnIdentifier = columnIds[0];

  const nullCheck = (value) => typeof value === 'undefined' ||
    value === null ||
    value === 'N/A';

  const filteredRows = rows.filter(row => {
    const value = row.values[columnIdentifier];
    const isNullValue = nullCheck(value);
    return filterValue === 'Only Null' ? isNullValue : !isNullValue;
  });

  return filteredRows;
}

export const filterShopifyStatus = (
  rows: Row[],
  columnIds: string[],
  filterValue: string,
) => {
  if (!filterValue) {
    return rows;
  }

  const columnIdentifier = columnIds[0];

  const filteredRows = rows.filter(row => {
    const value = row.values[columnIdentifier];
    
    const valueHasShipText = value.includes('Ships');

    if (filterValue === 'Show All' || filterValue === 'Waitlist') {
      return filterValue === value;
    } else if (filterValue === 'Ships [X]') {
      return valueHasShipText;
    }

    const valueEqualsShowAll = value === 'Show All';
    const valueEqualsWaitlist = value === 'Waitlist';
    return !valueEqualsShowAll && !valueEqualsWaitlist && !valueHasShipText;
  });

  return filteredRows;
}

export const filterWaitlist = (
  rows: Row[],
  columnIds: string[],
  filterValue: string,
) => {
  if (!filterValue) {
    return rows;
  }

  const columnIdentifier = columnIds[0];

  const filteredRows = rows.filter(row => {
    const value = row.values[columnIdentifier];
    return filterValue === 'Waitlist' ? value : !value;
  });

  return filteredRows;
}

export const filterInventoryWebShipDate = (
  rows: Row[],
  columnIds: string[],
  filterValue: string | Date,
  ) => {
  if (!filterValue && !isValidDate(filterValue)) {
    return rows;
  }

  const columnIdentifier = columnIds[0];

  const filteredRows = rows.filter(row => {
    const value = row.values[columnIdentifier];

    if (filterValue === 'Not Null') {
      return !!value;
    } else if (filterValue === 'Only Null') {
      return !value;
    } else if (filterValue === 'By Date') {
      return true;
    } else if (isValidDate(filterValue)) {
      if (value != undefined && value !== 'Waitlist') {
        const rowDate = new Date(value);
        return differenceInCalendarDays(filterValue as Date, rowDate) === 0;
      }
      return false;
    }

    return true;
  });

  return filteredRows;
}

export const filterMinMax = (
  rows: Row[],
  columnIds: string[],
  filterValue?: {min: string | null; max: string | null},
  ) => {
  if (!filterValue) {
    return rows;
  }
  const columnIdentifier = columnIds[0];

  const filteredRows = rows.filter(row => {
    const value = row.values[columnIdentifier];
    
    if ((value || value === 0) && value !== 'N/A') {
      try {
        if (filterValue.min && smaller(value, filterValue.min)) {
          return false;
        }
        if (filterValue.max && larger(value, filterValue.max)) {
          return false;
        }
        return true;
      } catch {
        return false;
      }
    }
    return false;
  });

  return filteredRows;
}

export interface GetExportFileBlobInput {
  columns: any[],
  data: any,
  fileType: string
}
export type GetExportFileBlobOutput = Blob | false;

export const defaultGetExportFileBlob = (
  { columns, data, fileType }: GetExportFileBlobInput
): GetExportFileBlobOutput => {
  if (fileType === "csv") {
    const headerNames = columns.map((col) => col.exportValue);
    const csvString = PapaParse.unparse({ fields: headerNames, data });
    return new Blob([csvString], { type: "text/csv" });
  }

  // Other formats goes here
  return false;
}

interface args extends GetExportFileBlobInput {
  isProductInventoryView: boolean;
}
export const newTableGetExportFileBlob = (
  { columns, data, fileType, isProductInventoryView }: args
): GetExportFileBlobOutput => {
  if (fileType === "csv") {
    const waitlistIndex = columns.findIndex(column => column.id === 'waitlist');
    const webShipDateIndex = columns.findIndex(column => column.id === 'webShipDate');

    let modifiedData: any[] = [];

    for (const product of data) {
      const waitlistValue = product[waitlistIndex];
      const webShipDateValue = product[webShipDateIndex];

      let webShipDate = '';
      if (webShipDateValue === 'Waitlist' || waitlistValue) {
        webShipDate = 'WAITLIST';
      } else if (
        typeof webShipDateValue !== 'undefined' &&
        webShipDateValue !== null &&
        webShipDateValue !== ''
      ) {
        try {
          if (isValidDate(webShipDateValue)) {
            webShipDate = format(webShipDateValue, 'MM/dd/y');
          } else {
            let date: Date | undefined = undefined;
            if (isProductInventoryView) {
              date = parseISO(webShipDateValue);
            } else {
              // incoming view date is different format
              date = parse(webShipDateValue, 'MM/dd/yyyy', new Date());
            }
            // date has been parsed successfully
            if (typeof date?.getMonth === 'function') {
              webShipDate = format(date, 'MM/dd/y');
            } else {
              console.error(`date ${webShipDateValue} cannot be parsed`);
            }
          }
        } catch (error) {
          console.log('error', error);
        }
      }

      const modifiedProduct = [...product];
      modifiedProduct.splice(webShipDateIndex, 1, webShipDate);
      if (isProductInventoryView) {
        modifiedProduct.splice(waitlistIndex, 1);
      }

      modifiedData.push(modifiedProduct);
    }
    const headerNames = columns.map((col) => col.exportValue);
    // remove Waitlist Column in ProductInventory
    if (isProductInventoryView) {
      headerNames.splice(waitlistIndex, 1);
    }

    const csvString = PapaParse.unparse({ fields: headerNames, data: modifiedData });
    return new Blob([csvString], { type: "text/csv" });
  }

  // Other formats goes here
  return false;
}
