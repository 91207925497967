import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { mainShadow } from 'shared/styles/shadows';
import './mui-tooltip-overrides.scss';
import { Theme, withStyles } from '@material-ui/core';
import { black, white } from 'shared/styles/colors';
import { SourceSansPro } from './Text';

const TooltipWithStyles = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: white,
    fontFamily: SourceSansPro,
    color: black,
    boxShadow: mainShadow,
    padding: 12,
    fontSize: 16,
    opacity: 1,
  },
  // @ts-ignore
}))(Tooltip);

const Wrapper = React.forwardRef(function Wrapper(props, ref) {
  //  Spread the props to the underlying DOM element.
  // @ts-ignore
  return <div {...props} ref={ref}>{props.children}</div>
});

interface Props {
  title: string;
  children: any;
}

const LightTooltip = ({ title, children }: Props) => {
  return (
    <>
      {/* @ts-ignore */ }
      <TooltipWithStyles
        disableFocusListener
        disableTouchListener
        title={title}
        placement="top"
        /* @ts-ignore */
        arrow
      >
        {/* @ts-ignore */}
        <Wrapper>
          {children}
        </Wrapper>
      </TooltipWithStyles>
    </>
  );
};

export default LightTooltip;
