import React from "react";

import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import "./CustomReactTable.scss";

import ArrowDownwardOutlined from '@material-ui/icons/ArrowDownwardOutlined';
import ArrowUpwardOutlined from '@material-ui/icons/ArrowUpwardOutlined';

const commafy = ( str ) => {
  str = str.split('.');

  if (str[0].length >= 4) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  }

  if (str[1] && str[1].length >= 4) {
    str[1] = str[1].replace(/(\d{3})/g, '$1 ');
  }

  return str.join('.');
}

const cellFormatters = {
  product_name: ({row}) => {
    return (
      <div className="cell title">
        <div className="value">{row.product_name}</div>
      </div>
    );
  },
  quantity_ordered: ({row}) => {
    return (
      <div className="cell cell--centered">
        <div className="value">{row.quantity_ordered}</div>
      </div>
    );
  },
  quantity_received: ({row}) => {
    return (
      <div className="cell cell--centered">
        <div className="value">{row.quantity_received}</div>
      </div>
    );
  },
  acquisition_cost: ({row}) => {
    return (
      <div className="cell cell--centered">
        <div className="value">${commafy((row.acquisition_cost).toFixed(2))}</div>
      </div>
    );
  },
  amount: ({row}) => {
    return (
      <div className="cell cell--centered">
        <div className="value">${commafy((row.amount).toFixed(2))}</div>
      </div>
    );
  },
  backorder: ({row}) => {
    return (
      <div className="cell cell--centered">
        <div className="value">{row.backorder}</div>
      </div>
    );
  },
  outstanding: ({row}) => {
    return (
      <div className="cell cell--centered">
        <div className="value">{row.quantity_ordered - row.quantity_received}</div>
      </div>
    );
  },
};

class PurchaseOrderDetailTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSKU: []
    };

    this.constructHeaderObject = this.constructHeaderObject.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
  }

  handleSelect(sku) {
    let currentSelections = [...this.state.selectedSKU];

    if(currentSelections.indexOf(sku) !== -1) {
      currentSelections.splice(currentSelections.indexOf(sku), 1);
    } else {
      currentSelections.push(sku);
    }

    this.setState({selectedSKU: currentSelections});

    this.props.handleCheckbox(sku);
  }

  handleSelectAll(e) {
    const {data} = this.props;
    let sku = [];

    if(e.target.checked) {
      sku = data.map(r => {return r.sku})
    }

    this.setState({selectedSKU: sku});
    this.props.handleCheckbox(sku);
  }

  constructHeaderObject(header) {
    const {selectedSKU} = this.state;

    let {label, key} = header; // Refer to /src/reducers/shopify_products_reducer.js to see list of Header objects
    let formattedHeader = {
      Header: props =>
        <div className='column'>
          <div className='label'>{label}</div>
          <div className='arrows_orders'>
            <ArrowDownwardOutlined className="Icon"/>
            <ArrowUpwardOutlined className="Icon"/>
          </div>
        </div>,
      accessor: key
    };

    if (key === 'product_name') {
      formattedHeader.width = 300;
    }

    if(key === 'sku') {
      formattedHeader = {
        Header: props =>
          <div className='column'>
            <div className="checkbox">
              <input type="checkbox" defaultChecked={false} onChange={this.handleSelectAll}/>
            </div>
            <div className='label'>{label}</div>
            <div className='arrows_orders'>
              <ArrowDownwardOutlined className="Icon"/>
              <ArrowUpwardOutlined className="Icon"/>
            </div>
          </div>,
        accessor: key
      };

      formattedHeader.Cell = ({row}) => {
        return (
          <div className="cell cell--centered">
            <div className="checkbox" style={{marginRight: "15px"}}>
              <input type="checkbox"
                     defaultChecked={false}
                     checked={selectedSKU.indexOf(row.sku) !== -1 ? true : false}
                     onChange={e => this.handleSelect(row.sku)}/>
            </div>
            <div className="value">{row.sku}</div>
          </div>
        );
      }
    }

    if (cellFormatters.hasOwnProperty(key)) {
      formattedHeader.Cell = cellFormatters[key];
    }

    return formattedHeader;
  }

  render() {
    const {
      headers = [],
      data = []
    } = this.props;

    let height = 140;

    if(data.length < 5) {
      height = height + data.length * 60;
    } else {
      height = 445;
    }

    return (
      <div>
        <ReactTable
          data={data}
          filterable
          defaultFilterMethod={(filter, row) => String(row[filter.id].toLowerCase()).includes(filter.value.toLowerCase())}
          columns={[
            {
              Header: "Purchase Order Data",
              columns: headers.map(this.constructHeaderObject)
            }
          ]}
          style={{height: height + "px"}}
          pageSize={data.length}
          showPageSizeOptions={false}
          showPagination={false}
          className="-striped -highlight "
        />
      </div>
    );
  }
}

export default PurchaseOrderDetailTable;
