import { css, StyleSheet } from 'aphrodite';
import LeftArrowIcon from 'components/icons/LeftArrowIcon';
import RightArrowIcon from 'components/icons/RightArrowIcon';
import Text, { Font, textStyles } from 'components/Text';
import React from 'react';
import { blueGray, darkBlue, tableLineGray } from 'shared/styles/colors';


interface Props {
  canPreviousPage: boolean;
  previousPage: () => void;
  canNextPage: boolean;
  nextPage: () => void;
  controlledPageIndex: number;
  gotoPage: (pageIndex: number) => void;
  pageCount: number;
}

const TableControls = ({
  canPreviousPage,
  previousPage,
  controlledPageIndex,
  gotoPage,
  pageCount,
  canNextPage,
  nextPage,
}: Props) => {
  return (
    <div className={css(styles.TableControls)}>
      <div
        className={css(styles.controlButton, canPreviousPage && styles.controlButtonEnabled)}
        onClick={() => { if (canPreviousPage) previousPage() }}
      >
        <LeftArrowIcon color={canPreviousPage ? darkBlue : tableLineGray} />
        <Text
          font={Font.SourceSansProBold}
          style={[styles.previousText, styles.controlText, canPreviousPage && styles.controlTextEnabled]}
        >
          Previous
          </Text>
      </div>
      <div className={css(styles.controlMiddle)}>
        <input
          type="number"
          value={controlledPageIndex + 1}
          className={css(styles.controlInput, textStyles.SourceSansProBold)}
          onChange={e => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(page);
          }}
        />
        <Text style={styles.pageCountTextMiddle}>
          of
          </Text>
        <Text style={styles.pageCountText}>
          {pageCount}
        </Text>
      </div>
      <div
        className={css(styles.controlButton, canNextPage && styles.controlButtonEnabled)}
        onClick={() => { if (canNextPage) { nextPage(); }}}
      >
        <Text
          font={Font.SourceSansProBold}
          style={[styles.nextText, styles.controlText, canNextPage && styles.controlTextEnabled]}
        >
          Next
        </Text>
        <RightArrowIcon color={canNextPage ? darkBlue : tableLineGray} />
      </div>
    </div>
  );
};

export default TableControls;

const styles = StyleSheet.create({
  TableControls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 12,
  },
  //
  controlText: {
    fontSize: 16,
    color: tableLineGray,
  },
  controlTextEnabled: {
    color: darkBlue,
  },
  controlButton: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'default',
  },
  controlButtonEnabled: {
    cursor: 'pointer',
  },
  previousText: {
    marginLeft: 6,
  },
  nextText: {
    marginRight: 6,
  },
  controlMiddle: {
    margin: '0 24px',
    display: 'flex',
    alignItems: 'center',
  },
  controlInput: {
    fontSize: 16,
    border: 0,
    borderRadius: 4,
    backgroundColor: tableLineGray,
    color: darkBlue,
    width: 28,
    height: 24,
    textAlign: 'center',
  },
  pageCountTextMiddle: {
    margin: '0 8px',
    fontSize: 16,
    color: blueGray,
  },
  pageCountText: {
    fontSize: 16,
    color: blueGray,
  },
});
