const INITIAL_STATE = {
  sosTest: true
};

const sosReducer = (state = INITIAL_STATE, action) => {
  let newState = {...state};
  switch(action.type) {
    default:
      return newState;
  }
};

export default sosReducer;
