import { AppThunk } from 'redux/store';
import { KitsActions } from 'reducers/kitsReducer';
import { BaseKit } from 'types/shared';
import BackendService from 'services/Backend/Backend.service';
import { toast } from 'react-toastify';

export const createKit = (kitData: BaseKit, resetForm: (defaultValues?: Record<string, any>) => void): AppThunk => async (dispatch, getState) => {
  dispatch(KitsActions.setIsloading(true));
  try {
    const response = await BackendService.createKit(kitData);
    resetForm(undefined);

    dispatch(KitsActions.createKit(response))
    dispatch(KitsActions.closeCreateOrEditKitModal());
    toast.success('Created Kit');
  } catch (error) {
    toast.error('Could not create Kit. Please try again.');
    dispatch(KitsActions.setIsloading(false));
  }
};
