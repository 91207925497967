import { css, StyleSheet } from 'aphrodite';
import { textStyles } from 'components/Text';
import React from 'react';
import { black, tableLineGray } from 'shared/styles/colors';
import { DropdownList } from 'react-widgets';
import './select-column-filter-overrides.scss';
import { DatePicker } from 'carbon-components-react';
import { DatePickerInput } from 'carbon-components-react';
import '../styles/carbon-calendar-styles.scss';
import '../Cells/carbon-calendar-cell-styles.scss';
import { isValidDate } from 'shared/utils';

interface Props {
  column: {
    filterValue: string | Date;
    setFilter: (value: any) => void;
    preFilteredRows: any[];
    id: number;
  },
  selectOptions: string[];
  defaultOption: string;
  withCalendarForSelectedOption?: string;
}

const SelectColumnFilter = ({
  column: {
    filterValue,
    setFilter,
  },
  selectOptions,
  defaultOption,
  withCalendarForSelectedOption,
}: Props) => {
  // change undefined value to show 'All' for just the ui
  let value = filterValue ? filterValue : defaultOption;

  if (isValidDate(filterValue)) {
    value = 'By Date';
  }

  const dropdownContent = (
    <DropdownList
      data={selectOptions}
      value={value}
      containerClassName={css(styles.select, textStyles.SourceSansProRegular)}
      onChange={(value) => setFilter(value === defaultOption ? undefined : value)}
    />
  );

  if (value === withCalendarForSelectedOption) {
    return (
      <div className={`${css(styles.contentContainer)} CalendarColumnFilter`}>
        {dropdownContent}
        <DatePicker
          datePickerType="single"
          onChange={datetimeArray => setFilter(datetimeArray[0])}
          value={filterValue}
        >
          <DatePickerInput
            placeholder="mm/dd/yyyy"
            labelText=""
            id="date-picker-single"
            autoComplete="off"
          />
        </DatePicker>
      </div>
    )
  }

  return dropdownContent;
};

const styles = StyleSheet.create({
  select: {
    border: `1px solid ${tableLineGray}`,
    boxShadow: 'none',
    fontSize: 16,
    color: black,
  },
  contentContainer: {

  },
});

export default SelectColumnFilter;
