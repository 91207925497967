import axios from 'axios';

export const fetchSOSData = () => {
  return dispatch => {
    axios.get('/sos')
      .then(response => {
        console.log('SOS Fetch Response from server: ', response);
      })
      .catch(error => {
        console.log('error from server: ', error);
      });
  };
};
