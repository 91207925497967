import { MinMaxColumnFilter } from "components/NewReactTable/Filters";
import { filterMinMax } from "components/NewReactTable/tableUtils";

export const columnsData = [
  {
    Header: "SKU",
    accessor: "sku",
    width: 100,
  },
  {
    Header: "Artisan",
    accessor: "artisan",
    width: 220,
  },
  {
    Header: "QB Product Name",
    accessor: "quickbooksProductName",
    width: 220,
  },
  {
    Header: "Avail for Pre-Sell Quantity",
    accessor: "availableForPresellQuantity",
    width: 100,
    filter: filterMinMax,
    Filter: MinMaxColumnFilter,
  },
  {
    Header: "Earliest Customer Ship Data",
    accessor: "ecsd",
    width: 100,
  },
  {
    Header: "WOH + 45- Day Incoming",
    accessor: "WOH45",
    width: 100,
    filter: filterMinMax,
    Filter: MinMaxColumnFilter,
  },
  {
    Header: "Total Backorder Quantity",
    accessor: "backOrderQuantity",
    width: 100,
    filter: filterMinMax,
    Filter: MinMaxColumnFilter,
  },
  {
    Header: "Purchase Order ID",
    accessor: "purchaseOrderId",
    width: 100,
  },
  {
    Header: "Shipment ID",
    accessor: "shipmentId",
    width: 100,
  },
  {
    Header: "All Open POs",
    accessor: "allOpenPOs",
    width: 200,
  },
];
