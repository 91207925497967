import React from 'react';
import _ from "lodash";

class ShipmentAddSKU extends React.Component {
  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
  }

  handleClose() {
    this.props.handleClose();
  }

  handleUpdate(sku, id) {
    let {selected} = this.props;
    // NOTE: potential sku capitalization issue
    let select = _.find(selected, {sku: sku});

    if (typeof select === 'undefined') {
      selected.push({
        available: 0,
        expected: 0,
        orderId: id,
        id: 'new',
        shipmentId: 'new',
        sku: sku
      })
    } else {
      _.remove(selected, {
        sku: sku
      });
    }

    this.props.handleUpdate(selected);
  }

  render() {
    const {allItems, open, selected} = this.props;

    return (
      <div className={"modal-block" + (open ? ' modal-block--opened' : '')}>
        <span className="modal-block__close" onClick={this.handleClose}></span>
        <div className="modal-block__container">
          {
            allItems
              .map(r => {
                return (
                  <div className="singleSKU">
                    <input type="checkbox" value={r.sku}
                           defaultChecked={false} checked={typeof _.find(selected, {sku: r.sku}) === 'undefined' ? false : true}
                           onChange={() => this.handleUpdate(r.sku, r.purchase_order_id)}/>
                    <span>{r.sku} - {r.product_name}</span>
                  </div>
                )
              })
          }
        </div>
      </div>
    )
  }
}

export default ShipmentAddSKU;
