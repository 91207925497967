import * as types from '../types';
import axios from 'axios';

export const fetchSettingsData = () => {
  return dispatch => {
    return axios.get('/settings/allData')
      .then(response => {
        return response;
      })
      .catch(error => {
        console.log('error from server: ', error);
      });
  };
}

export const fetchSingleSettingsData = (key) => {
  return dispatch => {
    return axios.get('/settings/getSingleSettings/' + key )
      .then(response => {
        dispatch(processettings(key, response.data));
        return response;
      })
      .catch(error => {
        console.log('error from server: ', error);
      });
  };
}

export const setSettingsData = (data) => {
  return dispatch => {
    return axios.post('/settings/allData', data);
  }
}

const processettings = (key, data) => {
  return {
    type: types.SET_SETTINGS,
    payload: {[key]: data}
  };
};


