import { css, StyleSheet } from "aphrodite";
import React from "react";
import Text, { Font } from "./Text";
import { Style } from "types/shared";
import { white, ctaBlue, ctaBlueHover } from "shared/styles/colors";
import { ReactComponent as PlusIcon } from "assets/svgs/plus-icon.svg";
import Spinner from "react-spinkit";

export enum ButtonType {
  Primary = "Primary",
  Secondary = "Secondary",
  Tertiary = "Tertiary",
}

interface Props {
  children: React.ReactNode,
  disabled: boolean;
  onClick: () => void;
  buttonType: ButtonType;
  buttonStyle?: Style | Style[];
  textStyle?: Style | Style[];
  loading: boolean;
  hasPlusIcon: boolean;
  type: "button" | "submit" | "reset" | undefined;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
}

const Button = ({
  disabled,
  buttonType,
  children,
  onClick: passedOnClick,
  buttonStyle,
  textStyle,
  loading,
  type,
  icon,
  iconPosition = 'right',
  hasPlusIcon,
}: Props) => {
  const isSecondaryButton = buttonType === ButtonType.Secondary;
  const loadingColor = isSecondaryButton ? ctaBlue : white;

  const onClick = () => {
    if (!disabled) {
      passedOnClick();
    }
  };

  let iconContent;
  if (icon) {
    iconContent = (
      <div
        className={css(
          iconPosition === 'right' ? styles.iconWrapperRight : styles.iconWrapperLeft,
        )}
      >
        {icon}
      </div>
    );
  }

  return (
    <button
      className={css(
        styles.Button,
        disabled && !loading && styles.disabled,
        styles[buttonType],
        buttonStyle
      )}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {loading && (
        <Spinner name="line-scale" color={loadingColor} fadeIn="none" />
      )}
      {!loading && (
        <>
          <Text font={Font.SourceSansProBold} style={[styles.text, styles[`${buttonType}_text`], textStyle]}>
            {hasPlusIcon && (
              <PlusIcon className={css(styles.plusIcon)} />
            )}
            {children}
          </Text>
          {iconContent}
        </>
      )}
    </button>
  );
};

Button.defaultProps = {
  buttonType: ButtonType.Primary,
  disabled: false,
  onClick: () => {}, // eslint-disable-line
  loading: false,
  type: "button",
  hasPlusIcon: false,
} as Partial<Props>;

const styles = StyleSheet.create({
  Button: {
    height: 40,
    width: 196,
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderWidth: 0,
    transition: '0.3s',
    ':active': {
      transform: 'scale(0.9)',
    },
  },
  [ButtonType.Primary]: {
    backgroundColor: ctaBlue,
    color: white,
    ':hover': {
      backgroundColor: ctaBlueHover,
    },
  },
  [ButtonType.Secondary]: {
    border: `1px solid ${ctaBlue}`,
    backgroundColor: white,
  },
  disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
    ':active': {
      transform: 'none',
    },
  },
  // text
  text: {
    fontSize: 16,
    lineHeight: 1.2,
    letterSpacing: 0.5,
    order: 2,
    "@media (max-width: 360px)": {
      fontSize: 15,
    },
  },
  [`${ButtonType.Primary}_text`]: {
    color: white,
    lineHeight: 1.2,
  },
  [`${ButtonType.Secondary}_text`]: {
    color: ctaBlue,
  },
  plusIcon: {
    marginRight: 8,
  },
  iconWrapperLeft: {
    marginRight: 12,
    order: 1,
  },
  iconWrapperRight: {
    marginLeft: 12,
    order: 3,
  },
});
export default Button;
