import { AppThunk } from 'redux/store';
import BackendService from 'services/Backend/Backend.service';
import { toast } from 'react-toastify';
import { ProductLogActions } from 'reducers/productLog';
import { runForMinDuration } from 'shared/utils';

export const fetchProductLog = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(ProductLogActions.setIsFetchingProductLog(true));

    const productLogEntries = await runForMinDuration(() => BackendService.fetchProductLog());

    dispatch(ProductLogActions.setProductLogEntries(productLogEntries));
    dispatch(ProductLogActions.setIsFetchingProductLog(false));
  } catch (error) {
    toast.error('Could not fetch Product Log. Please try again.');
    dispatch(ProductLogActions.setIsFetchingProductLog(false));
  }
};
