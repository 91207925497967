import { css, StyleSheet } from 'aphrodite';
import Text from 'components/Text';
import React from 'react';
import { black, tableLineGray } from 'shared/styles/colors';
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import { RectShape } from 'react-placeholder/lib/placeholders';
import { LOADING_IDENTIFIER } from 'shared/constants';
import { Cell as ReactTableCell } from 'react-table';

interface Props {
  cell: ReactTableCell;
}

export const ShipheroBackorderCell = ({ cell: { value } }: Props) => {
  if (value === LOADING_IDENTIFIER) {
    return (
      <div className={css(styles.Cell)}>
        {/* @ts-ignore */}
        <ReactPlaceholder
          showLoadingAnimation
          customPlaceholder={<RectShape color={tableLineGray} style={{ width: '80%', height: 24 }} />}
        />
      </div>
    );
  }

  return (
    <div className={css(styles.Cell)}>
      <Text style={styles.text}>
        {value}
      </Text>
    </div>
  );
};

const styles = StyleSheet.create({
  Cell: {
    position: 'relative',
    minHeight: 54,
    paddingLeft: 24,
    paddingRight: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  //
  text: {
    fontSize: 16,
    textAlign: 'end',
    color: black,
  },
  sortIconWrapper: {
    padding: 2,
    marginTop: 4,
    cursor: 'pointer',
  },
  textLink: {
    cursor: 'pointer',
  },
});
