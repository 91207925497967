import { css, StyleSheet } from 'aphrodite';
import { NavLink } from 'react-router-dom';
import React, { ReactElement } from 'react';
import { ctaBlue, gray, lightGray } from 'shared/styles/colors';

interface Props {
  image: ReactElement;
  href: string;
  text: string;
  activeFill: boolean;
}
const NavItem = ({ image, href, text, activeFill, }: Props) => {
  return (
    <NavLink
      to={href}
      className={`${css(styles.NavItem)} NavItem`}
      activeClassName={`NavItem-active active-${activeFill ? 'fill' : 'stroke'}`}
    >
      <div className={`${css(styles.imageWrapper)} NavItem-image-wrapper`}>
        {image}
        <div className={`${css(styles.underline)} NavItem-underline`} />
      </div>
    </NavLink >
  );
};

NavItem.defaultProps = {
  activeFill: false,
};

const styles = StyleSheet.create({
  NavItem: {
    display: 'flex',
    padding: '16px 0',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    transition: '0.3s',
    boxSizing: 'border-box'
  },
  //
  imageWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: '300ms',
    height: 30,
  },
  underline: {
    transition: '300ms',
    opacity: 0,
    position: 'absolute',
    bottom: -4,
    left: -4,
    width: 'calc(100% + 8px)',
    height: 2,
    backgroundColor: ctaBlue,
  }
});

export default NavItem;
