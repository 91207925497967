import { css, StyleSheet } from 'aphrodite';
import React from 'react';
import Text, { Font } from 'components/Text';
import { black } from 'shared/styles/colors';
import { motion, AnimatePresence } from 'framer-motion';
import ShipheroCard from './Triggers.ShipheroCard';
import { useDispatch } from 'react-redux';
import { fetchTriggersData } from 'redux/thunks';
import useInterval from 'use-interval';
import AnimatedDot from 'components/AnimatedDot';
import { Helmet } from "react-helmet";
import { HTML_TITLE_SUFFIX } from 'shared/constants';

const variants = {
  enter: {
    x: 0,
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.04,
      delayChildren: 0.2,
    },
  },
  exit: { x: -300, opacity: 0 },
};

const TriggersLayout = () => {
  const dispatch = useDispatch();

  const fetchPage = () => {
    dispatch(fetchTriggersData());
  }

  useInterval(() => {
    fetchPage();
  }, 5000, true);

  return (
    <section className={css(styles.TriggersLogLayout)}>
      <Helmet>
        <title>Triggers {HTML_TITLE_SUFFIX}</title>
      </Helmet>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, y: -18 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 0, duration: 0.6 }}
          className={css(styles.headerRow)}
        >
          <Text style={styles.header} font={Font.SourceSansProBold}>
            Triggers
          </Text>
          <AnimatedDot size={14} />
        </motion.div>
        <motion.div
          variants={variants}
          initial="exit"
          animate="enter"
          exit="exit"
          className={css(styles.cardsContainer)}
        >
          <ShipheroCard key="shiphero" />
        </motion.div>
      </AnimatePresence>
    </section>
  );
};

export default TriggersLayout;

const styles = StyleSheet.create({
  TriggersLogLayout: {
    padding: 40,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  //
  header: {
    fontSize: 36,
    color: black,
    marginRight: 16,
    lineHeight: 1,
  },
  headerRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 32,
  },
  cardsContainer: {
    display: 'grid',
    gridGap: 24,
    gridTemplateColumns: "repeat(auto-fit, 530px)",
    paddingBottom: 60,
  },
  refreshIconWrapper: {
    padding: '4px 2px 4px',
  },
});
