import { combineReducers } from 'redux';
import login from './login_reducer';
import shopifyProductsData from './shopify_products_reducer';
import shiphero from './shiphero_reducer';
import order from './order_reducers';
import shipment from './shipment_reducer';
import sosData from './sos_reducer';
import menuReducer from './menu_reducer';
import settingsReducer from './settings_reducer';
import incomingReducer from './incoming_reducer';
import newIncomingReducer from './newIncoming.reducer';
import kits from './kitsReducer';
import productLog from './productLog';
import triggers from './triggers';
import productInventory from './productInventory.reducer';
import atRiskReducer from "./atRisk.reducer";
import inventoryUploadReducer from './inventoryUploadReducer';

const reducers = combineReducers({
  login,
  shopifyProductsData,
  shiphero,
  order,
  shipment,
  sosData,
  kits,
  productLog,
  triggers,
  productInventory,
  settings: settingsReducer,
  incoming: incomingReducer,
  newIncoming: newIncomingReducer,
  menu: menuReducer,
  atRisk: atRiskReducer,
  inventoryUpload: inventoryUploadReducer,
});

export default reducers;
