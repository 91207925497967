import * as types from '../types';

export const menuToggle = (flag) => ({
  type: types.MENU_TOGGLE,
  payload: { flag }
});

export const profileToggle = (flag) => ({
  type: types.MENU_TOGGLE_PROFILE,
  payload: { flag }
});
