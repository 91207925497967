import { css, StyleSheet } from 'aphrodite';
import React, { useEffect, useRef } from 'react';
import Text, { Font } from 'components/Text';
import { black, darkBlue, red, white } from 'shared/styles/colors';
import { useDispatch } from 'react-redux';
import { fetchProductInventory } from 'redux/thunks';
import { motion, AnimatePresence } from 'framer-motion';
import { ReactComponent as RefreshIcon } from 'assets/svgs/refresh.svg';
import Button, { ButtonType } from 'components/Button';
import LightTooltip from 'components/LightTooltip';
import { useTypedSelector } from 'shared/utils';
import { ReactComponent as DownloadIcon } from 'assets/svgs/download.svg';
import { mainShadow } from 'shared/styles/shadows';
import ProductInventoryTable from './ProductInventory.table';
import { Helmet } from "react-helmet";
import { HTML_TITLE_SUFFIX } from 'shared/constants';

const ProductInventoryLayout = () => {
  const dispatch = useDispatch();
  const exportDataRef = useRef<() => void>(null);

  const onExportData = () => {
    if (exportDataRef.current) {
      exportDataRef.current();
    }
  }

  const { isFetchingProductInventory } = useTypedSelector((
    { productInventory: { isFetchingProductInventory } }
  ) => ({ isFetchingProductInventory }));

  useEffect(() => {
    dispatch(fetchProductInventory());
  }, []);

  return (
    <section className={css(styles.ProductInventoryLayout)}>
      <Helmet>
        <title>Product Inventory {HTML_TITLE_SUFFIX}</title>
      </Helmet>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, y: -18 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 0, duration: 0.6 }}
          className={css(styles.headerRow)}
        >
          <div className={css(styles.headerRowLeft)}>
            <Text style={styles.header} font={Font.SourceSansProBold}>
              Product Inventory
            </Text>
            <div
              className={css(styles.refreshIconWrapper)}
              onClick={() => dispatch(fetchProductInventory())}
            >
              <RefreshIcon />
            </div>
          </div>
          <div className={css(styles.headerRowRight)}>
            {/* @ts-ignore */}
            <LightTooltip
              title="Includes active filters and sorts"
            >
              {/* @ts-ignore */}
              <Button
                icon={<DownloadIcon />}
                disabled={isFetchingProductInventory}
                onClick={onExportData}
                buttonStyle={styles.exportButton}
              >
                Export Data
              </Button>
            </LightTooltip>
          </div>
        </motion.div>
        <ProductInventoryTable exportDataRef={exportDataRef} />
      </AnimatePresence>
    </section>
  );
};

export default ProductInventoryLayout;

const styles = StyleSheet.create({
  ProductInventoryLayout: {
    padding: '24px 24px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  loadingScreen: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  //
  header: {
    fontSize: 36,
    color: black,
    marginRight: 16,
    lineHeight: 1,
  },
  headerRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 24,
  },
  searchInputContainer: {
    position: 'relative',
  },
  numberOfKitsText: {
    fontSize: 22,
    color: darkBlue,
    marginBottom: 24,
  },
  refreshIconWrapper: {
    padding: '4px 2px 4px',
    marginTop: 6,
    cursor: 'pointer',
  },
  headerRowLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  headerRowRight: {
    display: 'flex',
    alignItems: 'center',
  },
  exportButton: {
    boxShadow: mainShadow,
  },
  trueupButton: {
    marginRight: 10,
    backgroundColor: red,
    border: 0,
  },
  trueupText: {
    color: white,
  }
});
