interface IncomingRowStub {
  availableForPresellQuantity: number;
  webShipDate: string | null;
  quantityPresold: number;
  ecsd: string | null;
}

export const filterUpcomingWaitlists = (incomingData: IncomingRowStub[]) => {
  const returnData: any[] = [];
  for (const row of incomingData) {
    const {
      webShipDate,
      ecsd,
    } = row;
    if (webShipDate === 'Waitlist' &&
      ecsd !== null && ecsd !== 'Waitlist'
    ) {
      returnData.push(row);
    }
  }
  return returnData;
}
