import { css, StyleSheet } from 'aphrodite';
import SearchIcon from 'components/icons/SearchIcon';
import { textStyles } from 'components/Text';
import React from 'react';
import { darkBlue, tableLineGray } from 'shared/styles/colors';
import { DebounceInput } from 'react-debounce-input';


interface Props {
  column: {
    filterValue: string;
    setFilter: (value: any) => void;
    preFilteredRows: any[];
    id: number;
  }
}

const SearchColumnFilter = ({
  column: {
    setFilter,
  },
}: Props) => {
  const onFilterChange = (text: string | undefined) => setFilter(text || undefined) // Set undefined to remove the filter entirely

  return (
    <div className={css(styles.SearchColumnFilter)}>
      <div className={css(styles.searchIconWrapper)}>
        <SearchIcon size={14} color="#C9CFD4" />
      </div>
      <DebounceInput
        className={css(styles.searchInput)}
        minLength={1}
        debounceTimeout={300}
        onChange={e => onFilterChange(e.target.value)}
      />
    </div>
  );
};

const styles = StyleSheet.create({
  SearchColumnFilter: {
    position: 'relative',
  },
  //
  searchInput: {
    ...textStyles.SourceSansProRegular,
    height: 38,
    width: '100%',
    paddingLeft: 28,
    border: 0,
    borderBottom: `1px solid ${tableLineGray}`,
    color: darkBlue,
    fontSize: 16,
  },
  searchIconWrapper: {
    position: 'absolute',
    top: 10,
    left: 4,
  },
});


export default SearchColumnFilter;
