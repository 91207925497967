import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { Provider, connect } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import configureStore, { history } from './redux/store';
import actions from './actions';
import firebaseInit from './firebaseConfig';
import indexRoutes from "routes/index.jsx";
import SideNav from './components/SideNav/SideNav';
import "assets/scss/material-dashboard-pro-react.css?v=1.3.0";
import "assets/scss/main.scss";
import { css, StyleSheet } from "aphrodite";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-widgets/dist/css/react-widgets.css';

import TimeAgo from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en'

TimeAgo.addDefaultLocale(en)


// Initialze firebase
firebaseInit();

// Initialize Sentry
if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: "https://3d994caf51b544d3b6e64f878f679d42@o443403.ingest.sentry.io/5421805",
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  });
}

// Create the redux store
export const store = configureStore();

// Connect dispatch to all actions
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch)
});

// Reusable function to connect components to the actions
const connectComponent = (component) => {
  return connect(
    state => state,
    mapDispatchToProps
  )(component);
};

// Create a wrapper App component to contain our routes, connecting each component to redux
class App extends React.Component {
  render() {
    return (
      <Router history={history}>
        <div className={css(styles.app)}>
          <ToastContainer
            position="top-right"
            autoClose={false}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange
            draggable={false}
            pauseOnHover
          />
          <div className={css(styles.sideNavWrapper)}>
            <SideNav />
          </div>
          <div className={css(styles.page)}>
            <Switch>
              {indexRoutes.map(({ path, component }, key) => {
                // Newer pages use hooks, not HOCs
                const newPageRoutes = ['/kits', '/log', '/triggers', '/dashboard', '/atrisk', '/inventory-removal'];
                const isNewPage = newPageRoutes.includes(path);
                const routeComponent = isNewPage ? component : connectComponent(component);

                return (
                  <Route
                    exact={path === '/'}
                    path={path}
                    component={routeComponent}
                    key={key}
                  />
                );
              })}
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}

const styles = StyleSheet.create({
  app: {
    display: 'flex',
    height: '100vh',
  },
  page: {
    flex: '1 1 auto',
    overflow: 'auto',
  },
  sideNavWrapper: {
  }
});

// Render our connected application to the root
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
