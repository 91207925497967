import React from 'react';
import ReactTable from 'react-table-6';
import moment from 'moment';

import ArrowDownwardOutlined from '@material-ui/icons/ArrowDownwardOutlined';
import ArrowUpwardOutlined from '@material-ui/icons/ArrowUpwardOutlined';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-table-6/react-table.css';
import './CustomReactTable.scss';

const cellFormatters = {
  sku: ({ row }) => {
    return (
      <div className="cell sku">
        <div className="value">{row.sku}</div>
      </div>
    );
  },
  landingDate: ({ row }) => {
    return (
      <div className="cell cell--centered">
        <div className="value">
          {row.landingDate !== null
            ? moment(row.landingDate).format('MM/DD/YYYY')
            : 'N/A'}
        </div>
      </div>
    );
  },
  shipDate: ({ row }) => {
    return (
      <div className="cell cell--centered">
        <div className="value">
          {row.shipDate !== null
            ? moment(row.shipDate).format('MM/DD/YYYY')
            : 'N/A'}
        </div>
      </div>
    );
  },
  customerShipDate: ({ row }) => {
    let result = '';
    if (row.customerShipDate !== null) {
      if (row.customerShipDate === 'Waitlist') {
        result = 'Waitlist';
      } else {
        result = moment(row.customerShipDate).format('MM/DD/YYYY');
      }
    }

    return (
      <div className="cell cell--centered">
        <div className="value">{result}</div>
      </div>
    );
  },
  web_ship_date: ({ row }) => {
    let render = '';
    if (row.web_ship_date === 'Waitlist') {
      render = 'WAITLIST';
    } else if (
      typeof row.web_ship_date !== 'undefined' &&
      row.web_ship_date !== null &&
      row.web_ship_date !== ''
    ) {
      render = moment(row.web_ship_date).format('MM/DD/YYYY');
    }

    return (
      <div className="cell cell--centered">
        <div className="value">{render}</div>
      </div>
    );
  }
};

class IncomingTable extends React.Component {
  constructor(props) {
    super(props);

    this.constructHeaderObject = this.constructHeaderObject.bind(this);
  }

  constructHeaderObject(header) {
    let { label, key } = header; // Refer to /src/reducers/shopify_products_reducer.js to see list of Header objects
    let formattedHeader = {
      Header: props => (
        <div className="column">
          <div className="label">{label}</div>
          <div className="arrows_orders">
            <ArrowDownwardOutlined className="Icon" />
            <ArrowUpwardOutlined className="Icon" />
          </div>
        </div>
      ),
      accessor: key
    };

    // if (key === 'landingDate') {
    //   formattedHeader.sortMethod = (a, b) => {
    //     let aValue = a.split('-');
    //     let bValue = b.split('-');

    //     return parseInt(aValue[1]) - parseInt(bValue[1]);
    //   };
    // }

    if (key === 'sku') {
      formattedHeader.width = 150;
    }

    if (key === 'artisan' || key === 'title') {
      formattedHeader.width = 150;
    }

    if (
      key === 'landingDate' ||
      key === 'shipDate' ||
      key === 'customerShipDate' ||
      key === 'web_ship_date'
    ) {
      formattedHeader.width = 130;
    }

    if (cellFormatters.hasOwnProperty(key)) {
      formattedHeader.Cell = cellFormatters[key];
    } else {
      formattedHeader.Cell = ({ row }) => {
        return (
          <div className="cell cell--centered title">
            <div className="value">{row[key]}</div>
          </div>
        );
      };
    }

    return formattedHeader;
  }

  getTrProps(state, rowInfo, instance) {
    if (rowInfo && rowInfo.row.receiveStatus === 'received') {
      return {
        style: {
          background: '#a7a7a7'
        }
      };
    }
    return {};
  }

  render() {
    const { headers = [], data = [] } = this.props;

    return (
      <ReactTable
        data={data}
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id])
            .toLowerCase()
            .includes(filter.value.toLowerCase())
        }
        columns={[
          {
            Header: 'Incoming View',
            columns: headers.map(this.constructHeaderObject)
          }
        ]}
        style={{
          height: '710px'
        }}
        defaultPageSize={25}
        className="-striped -highlight no-margins"
        onPageChange={() => {
          document.getElementsByClassName('rt-tbody')[0].scrollTop = 0;
        }}
        getTrProps={this.getTrProps}
      />
    );
  }
}

export default IncomingTable;
