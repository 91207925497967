import * as types from '../types';
import axios from 'axios';

const processNewCSVHeaders = headers => ({
  payload: { headers },
  type: types.PROCESS_NEW_CSV_HEADERS
});

const processShopifyProductsData = productsData => ({
  payload: { productsData },
  type: types.PROCESS_SHOPIFY_PRODUCT_DATA
});

const processShopifyMappedProductsData = productsData => ({
  payload: { productsData },
  type: types.PROCESS_SHOPIFY_MAPPED_PRODUCT_DATA
});

const processShopifyMergeSKU = data => ({
  payload: { data },
  type: types.PROCESS_SHOPIFY_MERGE_SKU
});

const processUploadedCSVData = csvMatrix => ({
  payload: { csvMatrix },
  type: types.PROCESS_UPLOADED_CSV_DATA
});

export const updateTableHeader = header => ({
  type: types.UPDATE_TABLE_HEADER,
  payload: { header }
});

export const submitTableHeader = headers => ({
  type: types.SUBMIT_TABLE_HEADER,
  payload: { headers }
});

const processWebShipmentAction = data => {
  return {
    type: types.PROCESS_WEB_SHIPMENT,
    payload: { data }
  };
};

const getOrderWaitlistAction = data => {
  return {
    type: types.GET_ORDER_WAITLIST,
    payload: { data }
  };
};

export const fetchShopifyProductsData = () => {
  return dispatch => {
    // axios.get('/shopify', {params: {shop: 'the-citizenry.myshopify.com'}})
    //   .then(response => {
    //     dispatch(processShopifyProductsData(response.data));
    //   })
    //   .catch(error => {
    //     console.log('error from server: ', error);
    //   });

    axios
      .get('/shopify/data')
      .then(response => {
        dispatch(processShopifyProductsData(response.data));
      })
      .catch(error => {
        console.log('error from server: ', error);
      });
  };
};

export const fetchShopifyMappedProductsData = () => {
  return dispatch => {
    axios
      .get('/shopify/data/mapped')
      .then(response => {
        dispatch(processShopifyMappedProductsData(response.data));
      })
      .catch(error => {
        console.log('error from server: ', error);
      });
  };
};

export const fetchShopifyMergeSKU = () => {
  return dispatch => {
    axios
      .get('/shopify/merge')
      .then(response => {
        dispatch(processShopifyMergeSKU(response.data));
      })
      .catch(error => {
        console.log('error from server: ', error);
      });
  };
};

export const parseCSVUpload = matrix => {
  // we have to make sure that the first row isn't blank,
  // perhaps a while loop checking for matrix values === ''
  return dispatch => {
    dispatch(processNewCSVHeaders(matrix[0]));
    dispatch(processUploadedCSVData(matrix));
  };
};

// TODO: remove with old product table
export const setWebShipmentDate = data => {
  return dispatch => {
    // dispatch(updateWebShipmentAction(data));
    return axios.post('/shopify/shipment', data);
  };
};

// TODO: remove with old product table
export const setWaitlist = data => {
  return dispatch => {
    return axios.post('/shopify/shipment', data);
  };
};

export const setOrderWaitlist = data => {
  return dispatch => {
    return axios.post('/shipment/waitlist', data);
  };
};

export const getOrderWaitlist = id => {
  return dispatch => {
    return axios
      .get(`/shipment/${id}/waitlist`)
      .then(response => {
        dispatch(getOrderWaitlistAction(response.data));
      })
      .catch(error => {
        console.log('error from server: ', error);
      });
  };
};

export const getWebShipmentDate = () => {
  return dispatch => {
    axios
      .get('/shopify/shipment')
      .then(response => {
        dispatch(processWebShipmentAction(response.data));
      })
      .catch(error => {
        console.log('error from server: ', error);
      });
  };
};
