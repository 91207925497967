import React from "react";

import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import "./CustomReactTable.scss";

import ArrowDownwardOutlined from '@material-ui/icons/ArrowDownwardOutlined';
import ArrowUpwardOutlined from '@material-ui/icons/ArrowUpwardOutlined';

import moment from 'moment';

import calendarImg from 'assets/img/ic-calender@2x.png';
import { format, parseISO } from "date-fns";

const cellFormatters = {
  purchase_order_id: ({row}) => {
    return (
      <div className="cell sku">
        {/* DON'T DELETE */}
        <div className="checkbox" style={{marginRight: "15px"}}><input type="checkbox" defaultChecked={false}/></div>
        <a className="value" href={`orders/${row.purchase_order_id}`}>{row.purchase_order_id}</a>
      </div>
    );
  },
  artisan: ({row}) => {
    return (
      <div className="cell title">
        <div className="value">{row.artisan}</div>
      </div>
    );
  },
  order_date: ({row}) => {
    return (
      <div className="cell title">
        <div className="value">
          <img style={{height: '16px', marginRight: "5px"}} src={calendarImg}
               alt="logo"/> {moment(row.order_date).format('MM/DD/YYYY')}</div>
      </div>
    );
  },
  DueDate: ({row}) => {
    return (
      <div className="cell title">
        <div className="value">{moment(row.DueDate).format('MM/DD/YYYY')}</div>
      </div>
    );
  },
  final_landing_date: ({row}) => {
    return (
      <div className="cell title">
        <div className="value">{moment(row.final_landing_date).format('MM/DD/YYYY')}</div>
      </div>
    );
  },
  status: ({row}) => {
    return (
      <div className="cell title">
        <div className="value">{row.status}</div>
      </div>
    );
  },
};

class PurchaseOrdersTable extends React.Component {
  constructor(props) {
    super(props);

    this.constructHeaderObject = this.constructHeaderObject.bind(this);
  }

  constructHeaderObject(header) {
    let {label, key} = header; // Refer to /src/reducers/shopify_products_reducer.js to see list of Header objects
    let formattedHeader = {
      //Header: label,
      Header: props =>
        <div className='column'>
          <div className='label'>{label}</div>
          <div className='arrows_orders'>
            <ArrowDownwardOutlined className="Icon"/>
            <ArrowUpwardOutlined className="Icon"/>
          </div>
        </div>,
      accessor: key
    };

    if (key === 'artisan') {
      formattedHeader.width = 300;
    }

    if (key === 'purchase_order_id') {
      formattedHeader.sortMethod = (a, b) => {
        if (a.length === b.length) {
          return a > b ? 1 : -1;
        }
        return a.length > b.length ? 1 : -1;
      }
    }

    if (cellFormatters.hasOwnProperty(key)) {
      formattedHeader.Cell = cellFormatters[key];
    }

    return formattedHeader;
  }

  render() {
    const {
      headers = [],
      data = []
    } = this.props;

    return (
      <div>
        <ReactTable
          data={data}
          filterable
          defaultFilterMethod={(filter, row) => {
            let cellValue = row[filter.id] ?? "";
            // columns with a date will have 'date' in their id
            if (filter.id.toLowerCase().includes("date") && cellValue !== "") {
              cellValue = format(parseISO(cellValue), 'MM/dd/y');
            }
            return cellValue.toLowerCase().includes(filter.value.toLowerCase());
          }}
          columns={[
            {
              Header: "Purchase Order Data",
              columns: headers.map(this.constructHeaderObject)
            }
          ]}
          style={{
            height: "910px"
          }}
          defaultPageSize={25}
          className="-striped -highlight "
          onPageChange={() => {
            document.getElementsByClassName('rt-tbody')[0].scrollTop = 0;
          }}
        />
      </div>
    );
  }
}

export default PurchaseOrdersTable;
