import { times } from 'lodash';
import { LOADING_IDENTIFIER } from "shared/constants";

export const createLoadingRows = (columns: Record<string, any>[]) => {
  const loadingRow = {};
  for (const { accessor } of columns) {
    loadingRow[accessor] = LOADING_IDENTIFIER;
  }
  /*
    since we're hiding the pagination while loading,
    we can just generate more rows than the page can render
    to account for large screens
  */
  return times(25, () => loadingRow);
}
