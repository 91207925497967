import { find } from "lodash";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { StoreState } from "types/state";

export const useTypedSelector: TypedUseSelectorHook<StoreState> = useSelector;

export const isEven = (number: number) => number % 2 === 0;


export const timeout = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const runForMinDuration = async (func: () => Promise<any>, minDuration: number = 1200) => {
  const startTime = Date.now();

  const returnValue = await func();

  const endTime = Date.now();

  const timeElapsed = endTime - startTime;

  if (timeElapsed < minDuration) {
    await timeout(minDuration - timeElapsed);
  }

  return returnValue;
}

export const isValidDate = (date) => {
  return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
}

export const findShipHeroDataWithShopifyMerge = (sku: string, shipheroData: any, shopifyMerge: any) => {
  const mappedProduct = find(shopifyMerge, { SKU: sku });
  if (mappedProduct !== undefined) {
    sku = mappedProduct.newSKU;
  }
  return find(shipheroData, { sku });
}

export function normalizeByKey(array, key, shouldUpperCaseKey = false) {
  const normalizedItems = {};
  for (const element of array) {
    let itemKey = element[key];
    if (shouldUpperCaseKey && typeof itemKey === "string") {
      itemKey = itemKey.toUpperCase();
    }

    normalizedItems[itemKey] = element;
  }
  return normalizedItems;
}

export const compareCaseInsensitive = (value1: string | null, value2: string | null) => {
  const val1 = typeof value1 === "string" ? value1.toUpperCase() : value1;
  const val2 = typeof value2 === "string" ? value2.toUpperCase() : value2;
  return val1 === val2;
}
