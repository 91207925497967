import { css, StyleSheet } from 'aphrodite';
import React, { useEffect, useState } from 'react';
import Text, { Font, textStyles } from 'components/Text';
import { black, darkBlue, gray } from 'shared/styles/colors';
import Button from 'components/Button';
import CreateKitModal from './CreateOrEditKitModal';
import Spinner from 'react-spinkit';
import { useDispatch } from 'react-redux';
import { fetchKits } from 'redux/thunks';
import { useTypedSelector } from 'shared/utils';
import KitCard from './KitCard';
import { motion, AnimatePresence } from 'framer-motion';
import Fuse from 'fuse.js';
import { Kit } from 'types/state';
import { ReactComponent as SearchIcon } from 'assets/svgs/search.svg';
import { KitsActions } from 'reducers/kitsReducer';
import { ReactComponent as RefreshIcon } from 'assets/svgs/refresh.svg';
import { fetchShopifyProductsData } from 'actions/shopify_actions';
import { Helmet } from "react-helmet";
import { HTML_TITLE_SUFFIX } from 'shared/constants';
import { find } from 'lodash';

const variants = {
  enter: {
    x: 0,
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.04,
      delayChildren: 0.2,
    },
  },
  exit: { x: -300, opacity: 0 },
};

const KitsLayout = () => {
  const [kitsToShow, setKitsToShow] = useState<Kit[]>();
  const [searchString, setSearchString] = useState<string>();
  const dispatch = useDispatch();
  const {
    kits,
    isFetchingKits,
    shopifyProducts,
  } = useTypedSelector((
    {
      kits: { kits, isFetchingKits },
      shopifyProductsData: {
        shopifyProducts,
      },
    }
  ) => ({
    kits,
    isFetchingKits,
    shopifyProducts
  }));

  const fetchPage = () => {
    dispatch(fetchKits());
    dispatch(fetchShopifyProductsData());
  }

  useEffect(() => {
    fetchPage();
  }, []);

  useEffect(() => {
    if (isFetchingKits) {
      setKitsToShow(undefined);
    } else if (kits) {
      let kitsMappedWithShopifyTitles = kits;
      if (shopifyProducts) {
        kitsMappedWithShopifyTitles = kits.map(kit => {
          const modifiedKit = { ...kit };

          const shopifyProduct = find(shopifyProducts, { sku: kit.sku });
          if (shopifyProduct) {
            modifiedKit.name = shopifyProduct.title;
          }
          modifiedKit.components = modifiedKit.components.map(kitComponent => {
            const modifiedKitComponent = { ...kitComponent };
            const shopifyProduct = find(shopifyProducts, { sku: kitComponent.sku });
            if (shopifyProduct) {
              modifiedKitComponent.name = shopifyProduct.title;
            }
            return modifiedKitComponent;
          });

          return modifiedKit;
        });

        kitsMappedWithShopifyTitles = kitsMappedWithShopifyTitles.sort((a, b) => {
          if (!a.name || !b.name) {
            return -1;
          }
          return a.name.localeCompare(b.name);
        });
      }
      if (searchString) {
        const fuse = new Fuse(kitsMappedWithShopifyTitles, {
          keys: ['sku', 'name', 'components.sku', 'components.name'],
          threshold: 0,
        });
        const results = fuse.search(searchString);
        const kitsFromResults = results.map(({ item }) => ({ ...item }));
        setKitsToShow(kitsFromResults);
      } else {
        setKitsToShow(kitsMappedWithShopifyTitles);
      }
    }
  }, [kits, isFetchingKits, searchString, shopifyProducts]);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchString(value === "" ? undefined : value);
  }

  let content;
  if (isFetchingKits) {
    content = (
      <div className={css(styles.loadingScreen)}>
        <Spinner
          className={css(styles.spinner)}
          name="line-scale-pulse-out"
          color={darkBlue}
          fadeIn="half"
        />
      </div>
    );
  } else if (kitsToShow !== undefined) {
    content = (
      <motion.div
        variants={variants}
        initial="exit"
        animate="enter"
        exit="exit"
        className={css(styles.kitsContainer)}
      >
        {kitsToShow.map(kit => (
          <KitCard key={kit.id} kit={kit} />
        ))}
      </motion.div>
    );
  }

  return (
    <section className={css(styles.KitLayout)}>
      <Helmet>
        <title>Kits {HTML_TITLE_SUFFIX}</title>
      </Helmet>
      <CreateKitModal />
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, y: -18 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 0.5, duration: 0.6 }}
          className={css(styles.headerRow)}
        >
          <Text style={styles.header} font={Font.SourceSansProBold}>
            Kits
          </Text>
          <div
            className={css(styles.refreshIconWrapper)}
            onClick={() => dispatch(fetchPage())}
          >
            <RefreshIcon />
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: -18 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 0.5, duration: 0.6 }}
          className={css(styles.actionRow)}
        >
          <div className={css(styles.searchInputContainer)}>
            <input
              type="text"
              className={css(styles.searchInput, textStyles.SourceSansProRegular)}
              placeholder="search kits..."
              onChange={onInputChange}
            />
            <SearchIcon className={css(styles.searchInputIcon)} />
          </div>
          <Button onClick={() => dispatch(KitsActions.setIsCreateKitModalOpen(true))}>
            Create Kit
          </Button>
        </motion.div>
        {kitsToShow && (
          <motion.div
            initial={{ opacity: 0, y: 18 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 1.1 }}
          >
            <Text style={styles.numberOfKitsText} font={Font.SourceSansProBold}>
              {kitsToShow.length} Kit{kitsToShow.length === 1 ? '' : 's'}
            </Text>
          </motion.div>
        )}
        {content}
      </AnimatePresence>
    </section>
  );
};

export default KitsLayout;

const styles = StyleSheet.create({
  KitLayout: {
    padding: '40px 40px',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  loadingScreen: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  //
  header: {
    fontSize: 36,
    color: black,
    marginRight: 16,
    lineHeight: 1,
  },
  headerRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 32,
  },
  actionRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 40,
  },
  kitsContainer: {
    display: 'grid',
    gridGap: 24,
    gridTemplateColumns: "repeat(auto-fit, 480px)",
    justifyContent: 'center',
  },
  searchInputContainer: {
    position: 'relative',
  },
  searchInputIcon: {
    position: 'absolute',
    top: 12,
    left: 2,
  },
  searchInput: {
    border: 0,
    fontSize: 18,
    borderBottom: `1px solid ${gray}`,
    height: 40,
    width: 312,
    padding: '6px 12px 6px 26px',
    backgroundColor: 'transparent',
    color: black,
    '::placeholder': {
      color: gray,
      fontSize: 16,
    },
  },
  numberOfKitsText: {
    fontSize: 22,
    color: darkBlue,
    marginBottom: 24,
  },
  spinner: {
    transform: 'scale(1.3)',
  },
  refreshIconWrapper: {
    padding: '4px 2px 4px',
    marginTop: 6,
    cursor: 'pointer',
  },
});
