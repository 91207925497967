import { ImmerReducer } from "immer-reducer";
import { createActionCreators, createReducerFunction } from "immer-reducer";
import { InventoryUploadState, Kit, KitsState } from "types/state";
import { filter } from 'lodash';
import { normalize, schema } from 'normalizr';


const kitEntity = new schema.Entity('kits', undefined, { idAttribute: 'sku' });
const kitsSchema = [kitEntity];

const initialState: InventoryUploadState = {
  isFetchingInitialData: false,
  isUploading: false,
  uploadError: null,
  warehouseSKUs: null,
  uploadSuccess: false,
};

class InventoryUploadReducer extends ImmerReducer<InventoryUploadState> {
  setIsFetchingInitialData(isFetching: boolean) {
    this.draftState.isFetchingInitialData = isFetching;
  }
  
  setIsUploadingInventory(isUploading: boolean) {
    this.draftState.isUploading = isUploading;
  }
  
  populateWarehouseSKUs(warehouseSKUs: string[]) {
    this.draftState.warehouseSKUs = warehouseSKUs;
    this.draftState.isFetchingInitialData = false;
  }

  populateWarehouseSKUsError(error: string) {
    this.draftState.uploadError = error;
    this.draftState.isFetchingInitialData = false;
  }
  
  uploadSuccess() {
    this.draftState.isUploading = false;
    this.draftState.uploadSuccess = true;
    this.draftState.uploadError = null;
  }
}

export const InventoryUploadActions = createActionCreators(InventoryUploadReducer);
const reducerFunction = createReducerFunction(InventoryUploadReducer, initialState);
export default reducerFunction;
