import { css, StyleSheet } from 'aphrodite';
import Text from 'components/Text';
import React from 'react';
import { black, tableLineGray } from 'shared/styles/colors';
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";
import { RectShape } from 'react-placeholder/lib/placeholders';
import { LOADING_IDENTIFIER } from 'shared/constants';
import { Cell as ReactTableCell, Row } from 'react-table';

interface Props {
  cell: ReactTableCell;
  justifyContent?: string;
}

export const DefaultCell = ({ cell: { value }, justifyContent }: Props) => {
  let content;
  if (value === LOADING_IDENTIFIER) {
    content = (
      <>
        {/* @ts-ignore */}
        <ReactPlaceholder
          showLoadingAnimation
          customPlaceholder={<RectShape color={tableLineGray} style={{ width: '80%', height: 24 }} />}
        />
      </>
    );
  } else {
    content = (
      <Text style={styles.text}>
        {value}
      </Text>
    );
  }

  return (
    <div
      className={css(styles.Cell)}
      style={{ justifyContent: justifyContent ?? 'flex-end' }}
    >
      {content}
    </div>
  );
};

const styles = StyleSheet.create({
  Cell: {
    minHeight: 54,
    paddingLeft: 12,
    paddingRight: 12,
    display: 'flex',
    alignItems: 'center',
  },
  //
  text: {
    fontSize: 16,
    color: black,
  },
  sortIconWrapper: {
    padding: 2,
    marginTop: 4,
    cursor: 'pointer',
  },
});
