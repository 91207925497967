/* eslint-disable no-unused-expressions */
import React from 'react';

// Import React Table
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import './CustomReactTable.scss';
import UpdateIcon from '@material-ui/icons/Sync';
import ArrowDownwardOutlined from '@material-ui/icons/ArrowDownwardOutlined';
import ArrowUpwardOutlined from '@material-ui/icons/ArrowUpwardOutlined';
import IconButton from '@material-ui/core/IconButton';
import { findIndex } from 'lodash';

import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { ReactComponent as XCircleIcon } from 'assets/svgs/x-circle.svg';

const cellFormatters = {
  available: ({ row }) => {
    return row.available;
  },
  best_customer_ship_date: ({ row }) => {
    let backgroundColor = ''; // initial row color
    let fontWeight = 300;
    // if the best customer ship date is greater than the lock_in_date
    if (
      Date.parse(row.best_customer_ship_date) > Date.parse(row.lock_in_date)
    ) {
      backgroundColor = 'rgba(225, 115, 115, 0.2)';
      fontWeight = 600;
    }
    return (
      <div
        style={{ backgroundColor, fontWeight }}
        className="cell best_customer_ship_date"
      >
        <div className="value">{row.best_customer_ship_date}</div>
      </div>
    );
  },
  inventory_quantity: ({ row }) => {
    let backgroundColor = ''; // initial row color
    if (
      typeof row.available !== 'object' &&
      (row.vendor === 'Ready to ship' || row.vendor === 'Waitlist')
    ) {
      if (row.available < row.inventory_quantity) {
        backgroundColor = 'rgba(225, 115, 115, 0.2)';
      } else if (row.available > row.inventory_quantity) {
        backgroundColor = '#FAD956';
      }
    }

    return (
      <div style={{ backgroundColor }} className="cell inventory_quantity">
        <p>{row.inventory_quantity}</p>
      </div>
    );
  },
  lock_in_date: ({ row }) => {
    let backgroundColor = ''; // initial row color
    let fontWeight = 300;
    // if the best customer ship date is greater than the lock_in_date
    if (
      Date.parse(row.best_customer_ship_date) > Date.parse(row.lock_in_date)
    ) {
      backgroundColor = 'rgba(225, 115, 115, 0.2)';
      fontWeight = 600;
    }

    return (
      <div
        style={{ backgroundColor, fontWeight }}
        className="cell lock_in_date"
      >
        <div className="value">{row.lock_in_date}</div>
      </div>
    );
  },
  weeks_on_hand: ({ row }) => {
    let backgroundColor = ''; // initial row color
    let fontWeight = 300;
    // if available isn't the button element
    if (typeof row.available !== 'object') {
      row.weeks_on_hand < 5 && row.weeks_on_hand > 4
        ? // if we're inside 4 - 5 week delivery window, set light red, slight bold
          ((backgroundColor = 'rgba(225, 155, 155, 0.2)'), (fontWeight = 500))
        : // If we have 5+ weeks remaining, set light green
          (backgroundColor = 'rgba(175, 235, 155, 0.2)');
      // if we're under 4 weeks remaining, set darker red, more bold
      row.weeks_on_hand <= 4
        ? ((backgroundColor = 'rgba(225, 155, 155, 0.4)'), (fontWeight = 600))
        : // if we aren't under 5 or 4 weeks, do nothing
          null;
    }

    return (
      <div
        style={{ backgroundColor, fontWeight }}
        className="cell weeks_on_hand"
      >
        <div className="value">{row.weeks_on_hand}</div>
      </div>
    );
  },
  backorder: ({ row }) => {
    return (
      <div className="cell backorder">
        <div className="value">{row.backorder}</div>
      </div>
    );
  },
  sell_thru: ({ row }) => {
    return (
      <div className="cell backorder">
        <div className="value">{row.sell_thru}</div>
      </div>
    );
  },
  shopify_week: ({ row }) => {
    return (
      <div className="cell backorder">
        <div className="value">{row.shopify_week}</div>
      </div>
    );
  },
  last_update: ({ row }) => {
    return (
      <div className="cell backorder">
        <div className="value">{row.last_update}</div>
      </div>
    );
  },
  shiphero_week: ({ row }) => {
    return (
      <div className="cell backorder">
        <div className="value">{row.shiphero_week}</div>
      </div>
    );
  },

  presell: ({ row }) => {
    return (
      <div className="cell backorder">
        <div className="value">{row.presell}</div>
      </div>
    );
  },
  wohCount: ({ row }) => {
    return (
      <div className="cell backorder">
        <div className="value">{row.wohCount}</div>
      </div>
    );
  },
  earliestShipDate: ({ row }) => {
    return (
      <div className="cell backorder">
        <div className="value">{row.earliestShipDate}</div>
      </div>
    );
  },
  inventory_item_id: ({ row }) => {
    return (
      <div className="cell inventory_item_id">
        <div className="value">{row.inventory_item_id}</div>
      </div>
    );
  },
  title: ({ row }) => {
    return (
      <div className="cell title">
        <div className="value">{row.title}</div>
      </div>
    );
  },
  vendor: ({ row }) => {
    return (
      <div className="cell vendor">
        <div className="value">{row.vendor}</div>
      </div>
    );
  }
};

class CustomReactTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      selectedSKU: []
    };

    this.constructHeaderObject = this.constructHeaderObject.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.localUpdateData = this.localUpdateData.bind(this);
  }

  componentDidMount() {
    this.setState({
      selectedSKU: this.props.selectedSKU,
      data: this.props.data
    });
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.data.length !== this.props.data.length) {
      this.setState({
        data: nextProps.data
      });
    }

    return true;
  }

  handleSelect(sku) {
    let currentSelections = [...this.state.selectedSKU];

    if (currentSelections.indexOf(sku) !== -1) {
      currentSelections.splice(currentSelections.indexOf(sku), 1);
    } else {
      currentSelections.push(sku);
    }

    this.setState({ selectedSKU: currentSelections });

    this.props.handleCheckbox(sku);
  }

  localUpdateData = (sku, value) => {
    const { data } = this.state;
    const key = findIndex(data, { sku: sku });

    let dateNew = null;
    if (value !== null) {
      dateNew = moment(value).format('YYYY-MM-DD');
    }

    data[key]['web_ship_date'] = value;

    this.setState({ data });

    this.props.handleShipmentDate(sku, dateNew);
  };

  localUpdateWaitlistData = (sku, value) => {
    const { data } = this.state;
    const key = findIndex(data, { sku: sku });

    data[key]['waitlist'] = value;
    if (value) {
      data[key]['web_ship_date'] = 'Waitlist';
    } else {
      data[key]['web_ship_date'] = '';
    }

    this.setState({ data });

    this.props.handleWaitlistClick(sku, value);
  };

  handleSelectAll(e) {
    const { data } = this.props;
    let sku = [];

    if (e.target.checked) {
      sku = data.map(r => {
        return r.sku;
      });
    }

    this.setState({ selectedSKU: sku });
    this.props.handleCheckbox(sku);
  }

  updateShiphero(sku) {
    this.props.updateShipHeroData(sku);
  }

  constructHeaderObject(header) {
    const { selectedSKU } = this.state;
    const { filters } = this.props;

    let { label, key } = header; // Refer to /src/reducers/shopify_products_reducer.js to see list of Header objects
    let formattedHeader = {
      //Header: label,
      Header: props => (
        <div className="column">
          <div className="label">{label}</div>
          <div className="arrows_orders">
            <ArrowDownwardOutlined className="Icon" />
            <ArrowUpwardOutlined className="Icon" />
          </div>
        </div>
      ),
      accessor: key
    };

    if (header.sortMethod) {
      formattedHeader.sortMethod = header.sortMethod;
    }

    if (key === 'last_update_ship') {
      formattedHeader.Cell = ({ row }) => {
        return (
          <div className="cell backorder">
            <div className="value">{row.last_update_ship}</div>
            <IconButton onClick={() => this.updateShiphero(row.sku)}>
              <UpdateIcon />
            </IconButton>
          </div>
        );
      };
    }
    if (key === 'sku') {
      formattedHeader = {
        Header: props => (
          <div className="column">
            <div className="checkbox">
              <input
                type="checkbox"
                defaultChecked={false}
                onChange={this.handleSelectAll}
              />
            </div>
            <div className="label">{label}</div>
            <div className="arrows_orders">
              <ArrowDownwardOutlined className="Icon" />
              <ArrowUpwardOutlined className="Icon" />
            </div>
          </div>
        ),
        accessor: key
      };

      formattedHeader.Cell = ({ row }) => {
        return (
          <div className="cell sku">
            <div className="checkbox" style={{ marginRight: '15px' }}>
              <input
                type="checkbox"
                defaultChecked={false}
                checked={selectedSKU.indexOf(row.sku) !== -1 ? true : false}
                onChange={e => this.handleSelect(row.sku)}
              />
            </div>
            <div className="value">{row.sku}</div>
          </div>
        );
      };

      formattedHeader.width = 150;
    }

    if (key === 'title') {
      formattedHeader.width = 250;
    }

    if (key === 'vendor') {
      let selectValues = [
        { name: 'Show All', value: 'all' },
        { name: 'Waitlist', value: 'Waitlist' },
        { name: 'Ready to ship', value: 'Ready to ship' },
        { name: 'Headed home with you', value: 'Headed home with you' },
        {
          name: 'Ships in 4 Weeks - Final Sale',
          value: 'Ships in 4 Weeks - Final Sale'
        }
      ];

      formattedHeader.Filter = () => {
        return (
          <select
            onChange={event =>
              this.props.handleFilters('vendor', event.target.value)
            }
            style={{ width: '100%' }}
            value={
              typeof filters.vendor !== 'undefined' ? filters.vendor : 'all'
            }
          >
            {selectValues.map(function(value) {
              return (
                <option key={value.value} value={value.value}>
                  {value.name}
                </option>
              );
            })}
          </select>
        );
      };
    }

    if (key === 'wohCount') {
      let selectValues = [
        { name: 'Show All', value: 'all' },
        { name: 'Not Null', value: 'not_null' },
        { name: 'Only Null', value: 'null' }
      ];

      formattedHeader.Filter = () => {
        return (
          <select
            onChange={event =>
              this.props.handleFilters('wohCount', event.target.value)
            }
            style={{ width: '100%' }}
            value={
              typeof filters.wohCount !== 'undefined' ? filters.wohCount : 'all'
            }
          >
            {selectValues.map(function(value) {
              return (
                <option key={value.value} value={value.value}>
                  {value.name}
                </option>
              );
            })}
          </select>
        );
      };
    }

    if (key === 'web_ship_date') {
      formattedHeader.Cell = ({ row }) => {
        let date = '';
        if (row.web_ship_date === 'Waitlist' || !!row.waitlist) {
          return <div className="cell webshipdate">WAITLIST</div>;
        } else if (
          typeof row.web_ship_date !== 'undefined' &&
          row.web_ship_date !== null &&
          row.web_ship_date !== ''
        ) {
          date = row.web_ship_date;
        }

        return (
          <div className="cell webshipdate">
            <DatePicker
              dateFormat="MM/dd/yyyy"
              selected={date}
              onChange={date => this.localUpdateData(row.sku, date)}
              name="web_ship_date"
              autoComplete="off"
              onChangeRaw={(e) => e.preventDefault()}
            />
            {date && (
              <div
                style={{
                  marginLeft: -12,
                  cursor: 'pointer',
                  margin: '4px 16% 0 3px',
                }}
                onClick={() => this.localUpdateData(row.sku, null)}
              >
                <XCircleIcon />
              </div>
            )}
          </div>
        );
      };

      let selectValues = [
        { name: 'Show All', value: 'all' },
        { name: 'By Date', value: 'date' },
        { name: 'Not Null', value: 'not_null' },
        { name: 'Only Null', value: 'null' }
      ];

      formattedHeader.Filter = () => {
        return (
          <>
            <select
              onChange={event =>
                this.props.handleFilters('web_ship_date', event.target.value)
              }
              style={{ width: '100%' }}
              value={
                typeof filters.web_ship_date !== 'undefined'
                  ? filters.web_ship_date
                  : 'all'
              }
            >
              {selectValues.map(function(value) {
                return (
                  <option key={value.value} value={value.value}>
                    {value.name}
                  </option>
                );
              })}
            </select>
            {filters.web_ship_date === 'date' && (
              <div className="cell filter-datepicker">
                <DatePicker
                  dateFormat="MM/dd/yyyy"
                  selected={filters.web_ship_date_date}
                  onChange={date => this.props.handleFilters('web_ship_date_date', date)}
                  name="web_ship_date_date"
                />
              </div>
            )}
          </>
        );
      };
    }

    if (key === 'waitlist') {
      formattedHeader.Cell = ({ row }) => {
        return (
          <div className="cell">
            <div className="checkbox" style={{ marginRight: '15px' }}>
              <input
                type="checkbox"
                defaultChecked={false}
                checked={!!row.waitlist}
                onChange={e =>
                  this.localUpdateWaitlistData(row.sku, e.target.checked)
                }
              />
            </div>
          </div>
        );
      };
    }

    if (cellFormatters.hasOwnProperty(key)) {
      formattedHeader.Cell = cellFormatters[key];
    }
    return formattedHeader;
  }

  render() {
    const { headers = [] } = this.props;
    const { data = [] } = this.state;

    return (
      <ReactTable
        data={data}
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id])
            .toLowerCase()
            .includes(filter.value.toLowerCase())
        }
        columns={[
          {
            Header: 'Inventory Data',
            columns: headers.map(this.constructHeaderObject)
          }
        ]}
        style={{
          height: '66vh'
        }}
        defaultPageSize={25}
        className="-striped -highlight "
        onPageChange={() => {
          document.getElementsByClassName('rt-tbody')[0].scrollTop = 0;
        }}
        // onFilteredChange={(filtered) => this.props.handleTextFilters(filtered)}
        // defaultFiltered={textFilters}
      />
    );
  }
}

export default CustomReactTable;
