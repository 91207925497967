import { css, StyleSheet } from 'aphrodite';
import SortIcon from 'components/SortIcon';
import Text, { Font } from 'components/Text';
import React from 'react';
import { black, ctaBlue, tableLineGray, white } from 'shared/styles/colors';

interface Props {
  column: any;
}

const ColumnHeader = ({
  column: {
    toggleSortBy,
    isSorted,
    isSortedDesc,
    canFilter,
    render,
    getResizerProps,
    isResizing
  },
}: Props) => {
  return (
    <div className={css(styles.ColumnHeader)}>
      <div className={css(styles.resizer, isResizing && styles.isResizing)} {...getResizerProps()} />
      <div className={css(styles.topRow)}>
        <Text font={Font.SourceSansProSemibold} style={styles.text}>
          {render('Header')}
        </Text>
        <div
          className={css(styles.sortIconWrapper)}
          onClick={() => toggleSortBy()}
        >
          <SortIcon isSorted={isSorted} isSortedDesc={isSortedDesc} />
        </div>
      </div>
      <div>{canFilter ? render('Filter') : null}</div>
    </div>
  );
};

export default ColumnHeader;

const styles = StyleSheet.create({
  ColumnHeader: {
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 12,
    paddingBottom: 12,
    backgroundColor: white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRight: `1px solid ${tableLineGray}`,
    borderBottom: `1px solid ${tableLineGray}`,
    minHeight: 132,
  },
  resizer: {
    display: 'inline-block',
    backgroundColor: tableLineGray,
    width: 1,
    height: '100%',
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 1,
  },
  isResizing: {
    backgroundColor: ctaBlue,
  },
  //
  text: {
    fontSize: 16,
    color: black,
    userSelect: 'none',
    textAlign: 'left',
  },
  sortIconWrapper: {
    padding: 4,
    cursor: 'pointer',
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 16,
    height: 40,
  },
});
