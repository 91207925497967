import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// import Card from "components/Card/Card.jsx";
// import CardBody from "components/Card/CardBody.jsx";
// import CardHeader from "components/Card/CardHeader.jsx";

import logo from "assets/img/citizenry-logo.png";
import loginBackground from "assets/img/login-background.jpg";

import actions from "../actions";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: ''
    };
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.updatePasswordField = this.updatePasswordField.bind(this);
    this.updateEmailField = this.updateEmailField.bind(this);
  }

  UNSAFE_componentWillMount() {
    const isAutenticated = localStorage.getItem('isAutenticated')
    if (this.props.login.userLoggedIn || isAutenticated) {
      this.props.history.push('/dashboard');
    }
  }

  shouldComponentUpdate(nextProps) {
    // if the state of the user login isn't the same, and the user is currently logged in, redirect to dashboar
    if (nextProps.login.userLoggedIn !== this.props.login.userLoggedIn && nextProps.login.userLoggedIn) {
      this.props.history.push('/dashboard');
      return false;
    } else {
      return true;
    }
  }

  handleButtonClick(e) {
    e.preventDefault();
    // if the form has the invalid-attempt class on it, remove it
    let { email, password } = this.state;
    this.props.actions.signInWithEmailAndPassword(email, password);
  }

  updatePasswordField() {
    let passwordEl = document.getElementById('password-field');
    let currentValue = passwordEl.value;
    this.setState({
      password: currentValue
    });
  }

  updateEmailField() {
    let emailEl = document.getElementById('email-field');
    let currentValue = emailEl.value;
    this.setState({
      email: currentValue
    });
  }

  // REFACTOR TO INCLUDE MATERIAL COMPONENTS
  render() {
    return (
      <div className="login-page-wrapper" style={{backgroundImage: `url(${loginBackground})`}}>
        <form className="login-form">
          {/* Logo Wrapper */}
          <div className="login-content-wrapper">
            <img
              className="login-logo"
              src={logo}
              alt="The Citizenry"
            />
          </div>

          {/* Input Fields Wrapper */}
          <div className="login-content-wrapper">
            <input
              className="login-input" // Conditional error class should go here; wrap with string template literals
              id='email-field'
              onChange={this.updateEmailField}
              placeholder="Email..."
              type="text"
            />
            <input
              className="login-input" // Conditional error class should go here; wrap with string template literals
              id='password-field'
              onChange={this.updatePasswordField}
              placeholder="Password..."
              type="password"
            />
          </div>

          {/* Button and Message Wrapper */}
          <div className="login-content-wrapper">
            <button
              className="login-button"
              type="submit"
              onClick={this.handleButtonClick}
            >
              Log in
            </button>
            <div id="login-error-message" className="login-message-wrapper">
              {
                this.props.login.invalidLoginAttempt ?
                  this.props.login.invalidLoginMessage :
                  ''
              }
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

export default connect(
  state => state,
  mapDispatchToProps
)(Login);
