import { mainShadow } from 'shared/styles/shadows';
import React from 'react';
import Text, { Font } from 'components/Text';
import { ctaBlue, darkBlue, midBlue, white } from 'shared/styles/colors';
import Button from 'components/Button';
import { css, StyleSheet } from 'aphrodite';
import { useTypedSelector } from 'shared/utils';
import { Watch, ThumbsUp, Calendar } from 'react-feather';
import { useDispatch } from 'react-redux';
import { triggerShipheroSync } from 'redux/thunks';
import ReactTimeAgo from 'react-time-ago';
import AnimatedDot from 'components/AnimatedDot';
import LightTooltip from 'components/LightTooltip';
import listify from 'listify';
import { ReactComponent as RedAlertIcon } from 'assets/svgs/red-alert-circle.svg';

const ShipheroCard = () => {
  const dispatch = useDispatch();

  const {
    isTriggersDataLoading,
    isShipheroSyncCallLoading,
    hasActiveShipheroSync,
    lastShipheroSyncStartTime,
    shipheroSyncFailedSKUs,
    hasLastSyncFailed,
  } = useTypedSelector((
    {
      triggers: {
        isTriggersDataLoading,
        isShipheroSyncCallLoading,
        hasActiveShipheroSync,
        lastShipheroSyncStartTime,
        shipheroSyncFailedSKUs,
        hasLastSyncFailed,
      },
    },
  ) => ({
    isTriggersDataLoading,
    isShipheroSyncCallLoading,
    hasActiveShipheroSync,
    lastShipheroSyncStartTime,
    shipheroSyncFailedSKUs,
    hasLastSyncFailed,
  }));

  const onSyncButtonClick = () => {
    dispatch(triggerShipheroSync());
  }

  let lastSyncText: React.ReactNode;
  if (!isTriggersDataLoading || lastShipheroSyncStartTime) {
    const lastSyncTextPrefix = hasActiveShipheroSync && lastShipheroSyncStartTime ? 'Started' : 'Completed';
    lastSyncText = (
      <div className={css(styles.lastSyncText)}>
        {lastSyncTextPrefix} <ReactTimeAgo date={lastShipheroSyncStartTime} locale="en-US" />
      </div>
    );
  }

  let buttonContent;
  if (hasActiveShipheroSync) {
    buttonContent = (
      <div className={css(styles.buttonRow)}>
        <div className={css(styles.animatedDotWrapper)}>
          <AnimatedDot />
        </div>
        <Text font={Font.SourceSansProBold} style={styles.buttonText}>
          Sync in Progress
        </Text>
      </div>
    );
  } else {
    buttonContent = (
      <Button
        disabled={isTriggersDataLoading || isShipheroSyncCallLoading}
        onClick={onSyncButtonClick}
        buttonStyle={styles.syncButton}
      >
        Sync Shiphero Titles
      </Button>
    );
  }

  return (
    <div className={css(styles.ShipheroCard)}>
      <div className={css(styles.headerRow)}>
        <div className={css(styles.headerLeft)}>
          <Text style={styles.headerText} font={Font.SourceSansProBold}>
            Shiphero Titles
          </Text>
        </div>
        {buttonContent}
      </div>
      <div className={css(styles.infoRow)}>
        <div className={css(styles.infoItem)}>
          <div className={css(styles.infoItemTopRow)}>
            <Watch size={16} color={midBlue} />
            <Text font={Font.SourceSansProBold} style={styles.infoItemText}>
              duration
            </Text>
          </div>
          <Text style={styles.infoItemBottomText}>
            1 Hour
          </Text>
        </div>
        <div className={css(styles.infoItem)}>
          <div className={css(styles.infoItemTopRow)}>
            <ThumbsUp size={16} color={midBlue} />
            <Text font={Font.SourceSansProBold} style={styles.infoItemText}>
              when to run
            </Text>
          </div>
          <Text style={styles.infoItemBottomText}>
            Off hours after new Shiphero items are added
          </Text>
        </div>
        <div className={css(styles.infoItem)}>
          <div className={css(styles.infoItemTopRow)}>
            <Calendar size={16} color={midBlue} />
            <Text font={Font.SourceSansProBold} style={styles.infoItemText}>
              last sync
            </Text>
            {hasLastSyncFailed && (
              <LightTooltip
                title={
                  shipheroSyncFailedSKUs ?
                  `No Shiphero info for SKU${shipheroSyncFailedSKUs.length > 0 ? 's' : ''} ${listify(shipheroSyncFailedSKUs)}` :
                  'Last sync failed'
                }
              >
                <div className={css(styles.redInfoIconWrapper)}>
                  <RedAlertIcon />
                </div>
              </LightTooltip>
            )}
          </div>
          <Text style={styles.infoItemBottomText}>
            {lastSyncText}
          </Text>
        </div>
      </div>
      <div className={css(styles.spacer)} />
      <div className={css(styles.bottomSection)}>
        <Text style={styles.bottomSectionText}>
          Shiphero titles are used in Product Log and Kits. Use this in case new or updated Shiphero product titles are not showing in Dash
        </Text>
      </div>
    </div>
  );
}

export default ShipheroCard;

const styles = StyleSheet.create({
  ShipheroCard: {
    width: 530,
    height: 270,
    paddingTop: 18,
    boxShadow: mainShadow,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: white,
    overflow: 'hidden',
  },
  //
  headerRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 38,
    padding: '0 24px',
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  headerText: {
    fontSize: 24,
    color: darkBlue,
  },
  syncButton: {
    boxShadow: mainShadow,
  },
  buttonRow: {
    display: 'flex',
    alignItems: 'center',
    height: 40,
  },
  buttonText: {
    color: ctaBlue,
    fontSize: 16,
  },
  infoRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 48px',
  },
  infoItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  infoItemTopRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
  },
  infoItemText: {
    color: midBlue,
    fontSize: 16,
    marginLeft: 6,
    textTransform: 'uppercase',
  },
  infoItemBottomText: {
    color: darkBlue,
    fontSize: 16,
    lineHeight: 1.4,
    textAlign: 'center',
    maxWidth: 180,
    cursor: "context-menu",
  },
  bottomSection: {
    display: 'flex',
    width: '100%',
    height: 80,
    backgroundColor: '#f6f7f9',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bottomSectionText: {
    width: 432,
    color: darkBlue,
    textAlign: 'center',
  },
  spacer: {
    flexGrow: 1,
  },
  lastSyncText: {
    width: 120,
  },
  animatedDotWrapper: {
    marginRight: 6,
  },
  redInfoIconWrapper: {
    marginLeft: 6,
    display: 'flex',
    alignItems: 'center',
  },
});
