import React from 'react';


interface Props {
  color?: string;
}

const RightArrowIcon = ({
  color = "#2B4153",
}: Props) => {
  return (
    <svg width="11" height="11" fill="none" viewBox="0 0 11 11">
      <path stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.5h9M5.5 1L10 5.5 5.5 10" />
    </svg>
  );
};

export default RightArrowIcon;
