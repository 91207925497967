import { css, StyleSheet } from 'aphrodite';
import React from 'react';
import { ctaBlue } from 'shared/styles/colors';


interface Props {
  isSorted: boolean;
  isSortedDesc: boolean;
}

const SortIcon = ({
  isSorted,
  isSortedDesc,
}: Props) => {
  const defaultColor = '#C9CFD4';
  let upArrowColor = defaultColor;
  let downArrowColor = defaultColor;
  if (isSorted) {
    if (isSortedDesc) {
      downArrowColor = ctaBlue;
    } else {
      upArrowColor = ctaBlue;
    }
  }
  return (
    <section className={css(styles.SortIcon)}>
      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="7" fill="none" viewBox="0 0 10 7">
        <path fill={upArrowColor} d="M4.235 1.11L1.14 5.416C.663 6.078 1.136 7 1.95 7H8.25c.822 0 1.293-.937.802-1.597L5.85 1.097C5.445.554 4.63.561 4.235 1.11z" />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="7" fill="none" viewBox="0 0 10 7">
        <path fill={downArrowColor} d="M5.927 5.896l3.15-4.306C9.56.93 9.088 0 8.27 0h-6.3c-.818 0-1.29.93-.807 1.59l3.15 4.306c.4.547 1.215.547 1.614 0z" />
      </svg>
    </section>
  );
};

export default SortIcon;

const styles = StyleSheet.create({
  SortIcon: {
    display: 'flex',
    flexDirection: 'column',
    height: 16,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});
